import { useRef, useState } from 'react';
import classNames from 'classnames';
import * as styles from './styles.module.scss';
import * as arise from '@/arise/api';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { closeModerationAction, openModerationAction } from '@/state/features/feed';
import { msTimeDifferenceFromNow } from '@/utils/time';

interface ModerationActionsProps {
    messageId: string;
    userId: string;
    userDisplayName: string;
    userBannedUntil?: string;
}

export default function ModerationActions({
    messageId,
    userId,
    userDisplayName,
    userBannedUntil,
}: ModerationActionsProps) {
    const dispatch = useAppDispatch();
    const moderationActionOpenOnMessageId = useAppSelector((state) => state.feed.moderationActionOpenOnMessageId);
    const modActionsRef = useRef<HTMLUListElement>(null);

    const isOpen = moderationActionOpenOnMessageId === messageId;
    const isBanned = userBannedUntil && msTimeDifferenceFromNow(userBannedUntil) > 0;

    // Returns wether mod action options are obscured off the bottom of the feed
    // If so, reposition
    function isOffBottom() {
        const div = modActionsRef.current;
        const parent = document.querySelector('.vw-list-ref');

        if (!div || !parent) {
            return false;
        }

        return div.getBoundingClientRect().bottom > parent.getBoundingClientRect().bottom;
    }

    return (
        <div className={styles.ModerationActions}>
            <button
                onClick={() => {
                    if (isOpen) {
                        dispatch(closeModerationAction());
                    } else {
                        dispatch(openModerationAction(messageId));
                    }
                }}
            >
                ...
            </button>
            {isOpen && (
                <ul
                    ref={modActionsRef}
                    className={classNames(styles.modActionsOptions, {
                        [styles.isOffBottom]: isOffBottom(),
                    })}
                >
                    <li style={{ fontWeight: 'bold' }}>{userDisplayName}</li>
                    {isBanned && (
                        <li style={{ backgroundColor: 'red' }}>Banned until {new Date(userBannedUntil).toString()}</li>
                    )}
                    <li>
                        <ModButton
                            label="Remove Message"
                            onClick={async () => {
                                await arise.deleteMessage(messageId);
                            }}
                        />
                    </li>
                    <li>
                        <ModButton
                            label={'Ban - 10 minutes'}
                            onClick={async () => {
                                try {
                                    await arise.banUser(userId, 60 * 10, 'short_ban');
                                } catch (error) {
                                    console.error('Error freezing user', error);
                                }
                            }}
                        />
                    </li>
                    <li>
                        <ModButton
                            label={'Ban - 1 year'}
                            onClick={async () => {
                                try {
                                    await arise.banUser(userId, 365 * 24 * 60 * 60, 'permanent_ban');
                                } catch (error) {
                                    console.error('Error banning user', error);
                                }
                            }}
                        />
                    </li>
                </ul>
            )}
        </div>
    );
}

interface ModButtonProps {
    label: string;
    onClick: () => Promise<void>;
}

export function ModButton({ label, onClick }: ModButtonProps) {
    const [active, setActive] = useState(false);

    async function handleClick() {
        setActive(true);
        await onClick();
        setActive(false);
    }

    return (
        <button onClick={handleClick} disabled={active}>
            {active ? '...' : label}
        </button>
    );
}
