import { useEffect } from 'react';

import { init } from '@/utils/analytics';
import { useTheme } from '@/theme';

export default function AnalyticsProvider() {
    const theme = useTheme();

    useEffect(() => {
        init(theme.customJSON.googleAnalyticsId, theme.customJSON.amplitudeKey);
    }, [theme]);
    return <></>;
}
