@import '../../../style/globals.scss';

.AuthInput {
    height: 45px;
    color: var(--token-accent-theme-1-text-text-default) !important;
    font-size: $font-size-p;

    &::placeholder {
        color: var(--token-accent-theme-1-text-text-default) !important;
        opacity: 0.7;
    }

    &--invalid {
        animation-name: invalid-input-shake;
        animation-timing-function: linear;
        transition: none;
    }

    @keyframes invalid-input-shake {
        8%,
        41% {
            -webkit-transform: translateX(-10px);
        }
        25%,
        58% {
            -webkit-transform: translateX(10px);
        }
        75% {
            -webkit-transform: translateX(-5px);
        }
        92% {
            -webkit-transform: translateX(5px);
        }
        0%,
        100% {
            -webkit-transform: translateX(0);
        }
    }
}
