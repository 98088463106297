import { useState } from 'react';

import AudioController, { AudioFile } from '@/hooks/AudioController';

let mutedLSKey = 'ARISE_SOUNDS_MUTED';

let audioController: AudioController | null = null;
let emptyAudioController = new AudioController([]);

/*
 * The useAudio hook is a wrapper around the AudioController class.
 * There can only be a single Audiocontroller class at a time.
 * The audio controller is created when the hook is first used (and tracks are passed in) and is then reused in every subsequent call.
 */
export function useAudio(tracks?: AudioFile[]) {
    const [isMuted, setIsMuted] = useState(localStorage.getItem(mutedLSKey) === '1');

    if (!audioController && tracks && tracks.length > 0) {
        audioController = new AudioController(tracks);
        audioController.mute(isMuted);
    }

    const toggleMute = () => {
        const isHowlerMuted = audioController.toggleMute();
        localStorage.setItem(mutedLSKey, isHowlerMuted ? '1' : '0');
        setIsMuted(isHowlerMuted);
    };

    return {
        isMuted,
        toggleMute,
        audio: audioController || emptyAudioController,
    };
}
