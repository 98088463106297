import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import IconButton from '@/components/IconButton';
import { pathToURL } from '@/utils/urls';
import { searchByDisplayName } from '@/arise/api';
import { Profile } from '@/models/profile';

import * as styles from './styles.module.scss';

interface SuggestionsPickerProps {
    query?: string;
    onProfileSelected: (profile: Profile) => void;
    visible?: boolean;
    currentKey?: string;
    onPickerClosed: () => void;
    closePickerOnEmptyResult?: boolean;
}

export default function SuggestionsPicker({
    query,
    currentKey,
    onProfileSelected,
    onPickerClosed,
    visible,
    closePickerOnEmptyResult,
}: SuggestionsPickerProps) {
    const MIN_QUERY_LENGTH = 2;
    const MAX_QUERY_LENGTH = 19;
    const [profiles, setProfiles] = useState<Profile[]>([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
    const latestQuery = useRef('');

    useEffect(() => {
        const trimmedQuery = query?.trim();
        latestQuery.current = trimmedQuery;
        if (trimmedQuery?.length >= MIN_QUERY_LENGTH && trimmedQuery.length <= MAX_QUERY_LENGTH) {
            const fetchResults = async () => {
                setIsLoadingSuggestions(true);
                try {
                    const response = await searchByDisplayName(trimmedQuery);
                    // Only update results if from latest query
                    if (trimmedQuery === latestQuery.current) {
                        setIsLoadingSuggestions(false);
                        setActiveIndex(0);
                        setProfiles(response.length ? response : []);
                        if (closePickerOnEmptyResult && !response.length) {
                            onPickerClosed();
                        }
                    }
                } catch (err) {
                    console.error('Could not find profiles');
                    setActiveIndex(0);
                    setProfiles([]);
                    setIsLoadingSuggestions(false);
                }
            };
            fetchResults();
        } else {
            setActiveIndex(0);
            setProfiles([]);
        }
    }, [query]);

    const onSelect = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, profile: Profile) => {
        e.preventDefault();
        setActiveIndex(0);
        onProfileSelected(profile);
    };

    useEffect(() => {
        if (currentKey === 'ArrowDown') {
            const newIndex = activeIndex + 1;
            if (newIndex < profiles.length) {
                setActiveIndex(newIndex);
                document.getElementById(`suggestion-${newIndex}`)?.scrollIntoView(false);
            }
        }
        if (currentKey === 'ArrowUp') {
            const newIndex = activeIndex - 1;
            if (newIndex >= 0) {
                setActiveIndex(newIndex);
                document.getElementById(`suggestion-${newIndex}`)?.scrollIntoView();
            }
        }
        if (currentKey === 'Enter') {
            if (profiles[activeIndex]) {
                onProfileSelected(profiles[activeIndex]);
            } else {
                onPickerClosed();
            }
        }
    }, [currentKey]);

    const noResultsMessage =
        query?.length < MIN_QUERY_LENGTH ? 'Start typing' : isLoadingSuggestions ? 'Loading...' : 'No results';

    return (
        <div className={classNames(styles.SuggestionsPicker, { [styles.hidden]: !visible })}>
            <IconButton
                iconType="close"
                ariaLabel="Close picker"
                buttonStyle="icon"
                onClick={onPickerClosed}
                className={styles.SuggestionsPicker__closeButton}
                title="Close picker"
            />
            <ul>
                {profiles.map((profile, index) => {
                    return (
                        <li key={index}>
                            <button
                                className={classNames(styles.SuggestionsPicker__user, {
                                    [styles.active]: index === activeIndex,
                                })}
                                id={`suggestion-${index}`}
                                onClick={(e) => onSelect(e, profile)}
                                onMouseDown={(e) => e.preventDefault()}
                                onMouseMove={() => setActiveIndex(index)}
                            >
                                <img src={pathToURL(profile.avatar.imagePath)} aria-hidden="true"></img>
                                {profile.displayName}
                            </button>
                        </li>
                    );
                })}
                {!profiles.length && <span className={styles.SuggestionsPicker__helpText}>{noResultsMessage}</span>}
            </ul>
        </div>
    );
}
