import { useInitialAuthCheck } from '@/hooks/auth';
import { useAppSelector } from '@/state/store';

export default function SceneSelectorLayout({ children }: { children: React.ReactNode }) {
    useInitialAuthCheck();
    const auth = useAppSelector((state) => state.auth);
    if (!auth.loggedIn) {
        return <></>;
    }
    return (
        <>
            {children}
            {/* <div className="absolute left-0 top-[50%] -translate-y-[50%] z-[100]">
                <SideMenu />
            </div> */}
        </>
    );
}
