import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as arise from '@/arise/api';
import { Avatar, Faction, FactionSettings, Profile, StorageProfile } from '@/models/profile';
import { RootState } from '@/state/store';
import { processProfileData } from '@/utils/profile';

interface ProfileState {
    avatars?: Avatar[];
    areAvatarsLoading: boolean;
    factions?: Faction[];
    areFactionsLoading: boolean;
    factionSettings?: FactionSettings;
    areFactionSettingsLoading: boolean;
    isCreatingProfile: boolean;
    isCreateProfileSuccessful: boolean;
    createProfileError?: string;
    isEditingProfile: boolean;
    isEditProfileSuccessful: boolean;
    editProfileError?: string;
    currentUserProfile?: Profile;
    currentUserProfileCheckInProgress: boolean;
    currentUserProfileCheckComplete: boolean;

    profileByUserId: Record<string, StorageProfile>;
    isFetchingProfileById: Record<string, boolean>;
}

const initialState: ProfileState = {
    areAvatarsLoading: false,
    areFactionsLoading: false,
    areFactionSettingsLoading: false,
    isCreatingProfile: false,
    isCreateProfileSuccessful: false,
    isEditingProfile: false,
    isEditProfileSuccessful: false,
    currentUserProfileCheckInProgress: false,
    currentUserProfileCheckComplete: false,

    profileByUserId: {},
    isFetchingProfileById: {},
};

export const initialProfileState = initialState;

export const loadAvailableAvatars = createAsyncThunk<Avatar[], undefined>('profile/loadAvailableAvatars', async () => {
    const avatars = await arise.getAvatars();
    return avatars;
});

export const loadAvailableFactions = createAsyncThunk<Faction[], undefined>(
    'profile/loadAvailableFactions',
    async () => {
        const factions = await arise.getFactions();
        return factions;
    },
);

export const loadFactionSettings = createAsyncThunk<FactionSettings, undefined>(
    'profile/loadFactionSettings',
    async () => {
        const factions = await arise.getFactionSettings();
        return factions;
    },
);

export const createProfile = createAsyncThunk<boolean, [displayName: string, avatarID: string, factionID: string]>(
    'profile/create',
    async ([displayName, avatarID, factionID]) => {
        const result = await arise.createProfile(displayName, avatarID, factionID);
        return result.success;
    },
);

export const editProfile = createAsyncThunk<
    boolean,
    [profileId: string, displayName: string, avatarID: string, factionID: string]
>('profile/edit', async ([profileId, displayName, avatarID, factionID]) => {
    const result = await arise.editProfile(profileId, displayName, avatarID, factionID);
    return result.success;
});

export const editProfileTitle = createAsyncThunk<boolean, string>('profile/editProfileTitle', async (id) => {
    const result = await arise.editProfileTitle(id);
    return result.success;
});

export const loadCurrentUserProfile = createAsyncThunk<Profile, [userId: string]>('profile/load', async (userId) => {
    const profiles = await arise.getProfiles(userId);
    return profiles[0] ?? undefined;
});

export const loadUserProfiles = createAsyncThunk<Profile[] | null, string[]>(
    'profile/loadUserProfiles',
    async (userIds, thunkApi) => {
        const state = thunkApi.getState() as RootState;

        const ttl = Date.now() + 1000 * 60;
        const cleanedUserIds = userIds.filter((id) => {
            return (
                !state.profile.isFetchingProfileById[id] &&
                (!state.profile.profileByUserId[id] ||
                    (state.profile.profileByUserId[id] && state.profile.profileByUserId[id].fetchedAt > ttl))
            );
        });

        if (cleanedUserIds.length === 0) {
            return null;
        }

        thunkApi.dispatch(profile.actions.markProfilesAsFetching(cleanedUserIds));

        return await arise.getProfiles(cleanedUserIds);
    },
);

const profile = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        markProfilesAsFetching: (state, action) => {
            action.payload.forEach((id) => {
                state.isFetchingProfileById[id] = true;
            });
        },
        updateProfile: (state, action: PayloadAction<Profile>) => {
            const data = action.payload as Profile;

            const profile = processProfileData(data);

            const now = Date.now();
            profile.fetchedAt = now;

            // Not currenlty using the profile cache so commenting this out to avoid using unnecessary memory
            // state.profileByUserId[profile.userID] = profile;

            if (state.currentUserProfile?.id === profile.id) {
                state.currentUserProfile = profile;
            }
        },
        resetProfileState: (state) => {
            const newState = {
                ...initialState,
                profileByUserId: state.profileByUserId,
                isFetchingProfileById: state.isFetchingProfileById,
            };
            Object.assign(state, newState);
        },
        resetProfileCreationStatus: (state) => {
            state.isCreatingProfile = null;
            state.isEditingProfile = null;
            state.isCreateProfileSuccessful = null;
            state.isEditProfileSuccessful = null;
            state.createProfileError = '';
            state.editProfileError = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadAvailableAvatars.pending, (state) => {
            state.areAvatarsLoading = true;
        });

        builder.addCase(loadAvailableAvatars.fulfilled, (state, action) => {
            state.avatars = action.payload;
            state.areAvatarsLoading = false;
        });

        builder.addCase(loadAvailableAvatars.rejected, (state, action) => {
            console.error('Failed to load avatars', action.error);
            state.areAvatarsLoading = false;
            state.avatars = []; // TODO - Show error message?
        });

        builder.addCase(loadAvailableFactions.pending, (state) => {
            state.areFactionsLoading = true;
        });

        builder.addCase(loadAvailableFactions.fulfilled, (state, action) => {
            state.factions = action.payload;
            state.areFactionsLoading = false;
        });

        builder.addCase(loadAvailableFactions.rejected, (state, action) => {
            console.error('Failed to load factions', action.error);
            state.areFactionsLoading = false;
            state.factions = []; // TODO - Show error message?
        });

        builder.addCase(loadFactionSettings.pending, (state) => {
            state.areFactionSettingsLoading = true;
        });

        builder.addCase(loadFactionSettings.fulfilled, (state, action) => {
            state.factionSettings = action.payload;
            state.areFactionSettingsLoading = false;
        });

        builder.addCase(loadFactionSettings.rejected, (state, action) => {
            console.error('Failed to load faction settings', action.error);
            state.areFactionSettingsLoading = false;
            state.factionSettings = null; // TODO - Show error message?
        });

        builder.addCase(loadCurrentUserProfile.pending, (state) => {
            state.currentUserProfileCheckInProgress = true;
            state.currentUserProfileCheckComplete = false;
        });

        builder.addCase(loadCurrentUserProfile.fulfilled, (state, action) => {
            state.currentUserProfile = action.payload;
            state.currentUserProfileCheckInProgress = false;
            state.currentUserProfileCheckComplete = true;
        });

        builder.addCase(loadCurrentUserProfile.rejected, (state, action) => {
            console.error('Failed to load current user profile', action.error);
            state.currentUserProfileCheckInProgress = false;
            state.currentUserProfileCheckComplete = true;
        });

        builder.addCase(createProfile.pending, (state) => {
            state.isCreatingProfile = true;
            state.createProfileError = undefined;
            state.isCreateProfileSuccessful = false;
        });

        builder.addCase(createProfile.fulfilled, (state) => {
            state.isCreatingProfile = false;
            state.isCreateProfileSuccessful = true;
        });

        builder.addCase(createProfile.rejected, (state) => {
            state.isCreatingProfile = false;
            state.createProfileError = 'An error occurred when creating your profile';
            state.isCreateProfileSuccessful = false;
        });

        builder.addCase(editProfile.pending, (state) => {
            state.isEditingProfile = true;
            state.editProfileError = undefined;
            state.isEditProfileSuccessful = false;
        });

        builder.addCase(editProfile.fulfilled, (state) => {
            state.isEditingProfile = false;
            state.isEditProfileSuccessful = true;
        });

        builder.addCase(editProfile.rejected, (state) => {
            state.isEditingProfile = false;
            state.editProfileError = 'An error occurred when updating your profile';
            state.isEditProfileSuccessful = false;
        });

        builder.addCase(loadUserProfiles.fulfilled, (state, action) => {
            action.meta.arg.forEach((id) => {
                state.isFetchingProfileById[id] = false;
            });

            const now = Date.now();

            action.payload?.forEach((profile) => {
                const p = profile as StorageProfile;
                p.fetchedAt = now;
                state.profileByUserId[p.userID] = p;
            });
        });

        builder.addCase(loadUserProfiles.rejected, (state, action) => {
            console.error(action.error);
            action.meta.arg.forEach((id) => {
                state.isFetchingProfileById[id] = false;
            });
        });
    },
});

export const { updateProfile, resetProfileState, resetProfileCreationStatus } = profile.actions;
export const { actions: profileActions, reducer: profileReducer } = profile;

export default profileReducer;
