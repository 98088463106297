import classNames from 'classnames';

import * as styles from './styles.module.scss';

interface ItemContainerProps {
    imagePath: string;
    backgroundImagePath: string;
    textBackgroundImagePath: string;
    title: string;
    subtitle?: string;
    highlightImagePath?: string;
    isSelected?: boolean;
    onSelected?: () => void;
    imageAlt?: string;
    interactable?: boolean;
}

export default function ItemContainer({
    imagePath,
    backgroundImagePath,
    textBackgroundImagePath,
    highlightImagePath,
    title,
    subtitle,
    isSelected,
    onSelected,
    imageAlt,
    interactable,
}: ItemContainerProps) {
    const highlightImageStyle = highlightImagePath ? { backgroundImage: `url(${highlightImagePath})` } : {};

    return (
        <div className={styles.ItemContainer}>
            <button
                className={classNames(styles.ItemContainer__Button, {
                    [styles['btn-selected']]: isSelected,
                    [styles['ItemContainer__Button--interactable']]: interactable,
                })}
                onClick={() => onSelected && onSelected()}
                disabled={!interactable}
            >
                <div
                    className={styles.ItemContainer__BackgroundContainer}
                    style={{ backgroundImage: `url(${backgroundImagePath})` }}
                ></div>
                <div className={styles.ItemContainer__Border}></div>
                <img src={imagePath} className={styles.ItemContainer__Image} alt={imageAlt} />
                <div
                    className={styles.ItemContainer__Text}
                    style={{ backgroundImage: `url(${textBackgroundImagePath})` }}
                >
                    <p className={classNames(styles.ItemContainer__Title, { [styles.longTitle]: title.length > 12 })}>
                        {title}
                    </p>
                    <p className={styles.ItemContainer__Subtitle}>{subtitle}</p>
                </div>
            </button>
            {highlightImagePath && (
                <div className={styles.ItemContainer__HighlightImage} style={highlightImageStyle}></div>
            )}
        </div>
    );
}
