// Temporary place to put themes.
// In the future these should be loaded from a database.
// TODO - Remove things from here which are now delivered via CMS

import { Theme } from '@/models/instance';

export enum ThemeIds {
    default = 'default',
    Titan = 'titan',
}

export const defaultTheme: Theme = {
    themeId: ThemeIds.default,
    loaded: false,
    assetBasePath: '/assets',
    fonts: {
        primary: 'Arial',
        secondary: 'Times New Roman',
        tertiary: 'Courier New',
        quaternary: 'Courier New',
    },
    files: {
        logo: '',
        splash_bg: '',
        inventory_backdrop: '',
        instance_mark: '',
    },
    tokens: {
        // TODO: remove tokens from here?
        // Defaults are mostly greys for now
        AriseTheme1RarityRarityCommonLight: '#7f8693ff',
        AriseTheme1RarityRarityUncommonLight: '#8fff97ff',
        AriseTheme1RarityRarityRareLight: '#a7f0feff',
        AriseTheme1RarityRarityEpicLight: '#e569fbff',
        AriseTheme1RarityRarityLegendaryLight: '#fd8917ff',
        AriseTheme1RarityRarityCommonMid: '#292f3aff',
        AriseTheme1RarityRarityUncommonMid: '#265d3fff',
        AriseTheme1RarityRarityRareMid: '#19646fff',
        AriseTheme1RarityRarityEpicMid: '#582f59ff',
        AriseTheme1RarityRarityLegendaryMid: '#803e18ff',
        AriseTheme1StateStateErrorLight: '#fc3232ff',
        AriseTheme1StateStateErrorMid: '#200506ff',
        AriseTheme1StateStateSuccessLight: '#8fff97ff',
        AriseTheme1StateStateSuccessMid: '#265d3fff',
        AriseTheme1StateStateSuccessDark: '#12251bff',
        AccentTheme1ButtonButtonDefault: '#2a2a2aff',
        AccentTheme1ButtonButtonSecondary: '#3a3a3aff',
        AccentTheme1ButtonButtonHighlight: '#3a3a3aff',
        AccentTheme1ButtonButtonInactive: '#0f0f0fff',
        AccentTheme1BorderBorderHighlight: '#9d9d9dff',
        AccentTheme1BorderBorderDefault: '#474747ff',
        AccentTheme1BorderBorderActive: '#9d9d9dff',
        AccentTheme1TextTextActive: '#ffffffff',
        AccentTheme1TextTextDefault: '#828282ff',
        AccentTheme1TextTextSecondary: '#9d9d9dff',
        AccentTheme1TextTextInactive: '#3a3a3aff',
        AccentTheme1InterfaceInterfaceActive: '#9d9d9dff',
        AccentTheme1InterfaceInterfaceForeground: '#2a2a2aff',
        AccentTheme1InterfaceInterfaceMidground: '#141414ff',
        AccentTheme1InterfaceInterfaceBackground: '#0f0f0fff',
        AccentTheme1InterfaceInterfaceWhite: '#ffffffff',
        AccentTheme1InterfaceInterfaceGrey: '#828282ff',
        AccentTheme1InterfaceInterfaceBlack: '#0b0b0bff',
        AccentTheme1BrandColour: '#8c8c8cff',
        AccentTheme1BrandColourContrast: '#0b0b0bff',
    },
    customJSON: {},
};

export const TitanTheme: Theme = {
    ...defaultTheme,
    themeId: ThemeIds.Titan,
    fonts: {
        primary: 'CheapSignage',
        secondary: 'Alexandria',
        tertiary: 'Inter',
        quaternary: 'Oswald',
    },
    tokens: {
        AriseTheme1RarityRarityCommonLight: '#7f8693ff',
        AriseTheme1RarityRarityUncommonLight: '#8fff97ff',
        AriseTheme1RarityRarityRareLight: '#a7f0feff',
        AriseTheme1RarityRarityEpicLight: '#e569fbff',
        AriseTheme1RarityRarityLegendaryLight: '#fd8917ff',
        AriseTheme1RarityRarityCommonMid: '#292f3aff',
        AriseTheme1RarityRarityUncommonMid: '#265d3fff',
        AriseTheme1RarityRarityRareMid: '#19646fff',
        AriseTheme1RarityRarityEpicMid: '#582f59ff',
        AriseTheme1RarityRarityLegendaryMid: '#803e18ff',
        AriseTheme1StateStateErrorLight: '#fc3232ff',
        AriseTheme1StateStateErrorMid: '#200506ff',
        AriseTheme1StateStateSuccessLight: '#8fff97ff',
        AriseTheme1StateStateSuccessMid: '#265d3fff',
        AriseTheme1StateStateSuccessDark: '#12251bff',
        AccentTheme1ButtonButtonDefault: '#792124ff',
        AccentTheme1ButtonButtonSecondary: '#dcdcdcff',
        AccentTheme1ButtonButtonHighlight: '#e81f25ff',
        AccentTheme1ButtonButtonInactive: '#46191aff',
        AccentTheme1BorderBorderHighlight: '#e81f25ff',
        AccentTheme1BorderBorderDefault: '#46191aff',
        AccentTheme1BorderBorderActive: '#ff7e7eff',
        AccentTheme1TextTextActive: '#ffffffff',
        AccentTheme1TextTextDefault: '#ffffffff',
        AccentTheme1TextTextSecondary: '#bdbdbdff',
        AccentTheme1TextTextInactive: '#1e1e1eff',
        AccentTheme1InterfaceInterfaceActive: '#ffffffff',
        AccentTheme1InterfaceInterfaceForeground: '#3d3d3dff',
        AccentTheme1InterfaceInterfaceMidground: '#1e1e1eff',
        AccentTheme1InterfaceInterfaceBackground: '#151515ff',
        AccentTheme1InterfaceInterfaceWhite: '#ffffffff',
        AccentTheme1InterfaceInterfaceGrey: '#bdbdbdff',
        AccentTheme1InterfaceInterfaceBlack: '#000000ff',
        AccentTheme1BrandColour: '#e81f25ff',
        AccentTheme1BrandColourContrast: '#ffffffff',
    },
};
