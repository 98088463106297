import { useTheme } from '@/theme';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { toggleShowingSettingsMenu } from '@/state/features/app';

import * as styles from './styles.module.scss';
import classNames from 'classnames';

export default function HeaderBar() {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const isSettingsMenuShowing = useAppSelector((state) => state.app.isSettingsMenuShowing);
    const loggedIn = useAppSelector((state) => state.auth.loggedIn);
    const buttonEnabled = loggedIn;

    return (
        <div className={styles.HeaderBar}>
            <button
                aria-hidden={!buttonEnabled}
                disabled={!buttonEnabled}
                aria-label={buttonEnabled ? (isSettingsMenuShowing ? 'Close Settings Menu' : 'Open Settings Menu') : ''}
                className={classNames(styles.logoIconWrapper, {
                    [styles.enabled]: buttonEnabled,
                    [styles.active]: isSettingsMenuShowing,
                })}
                onClick={() => {
                    buttonEnabled && dispatch(toggleShowingSettingsMenu());
                }}
            >
                {theme.files.logo && <img className={styles.logoIcon} src={theme.files.logo} alt="Logo Icon" />}
                <div className={styles.menuIcon}></div>
            </button>
            <div className="flex-1"></div>
        </div>
    );
}
