import * as styles from './styles.module.scss';
import { LegacyRef } from 'react';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    type?: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    style?: React.CSSProperties;
    className?: string;
    tabIndex?: number;
    passedRef?: LegacyRef<HTMLInputElement>;
    autoComplete?: string;
    onSubmit?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    id?: string;
}

export default function Input({
    value,
    onChange,
    placeholder,
    style,
    onSubmit,
    className,
    type = 'text',
    tabIndex,
    passedRef,
    autoComplete,
    id,
    ...props
}: InputProps) {
    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (!props.disabled) {
            if (e.key === 'Enter' && onSubmit) onSubmit(e);
        }
    }

    const classname = className ? styles.Input + ' ' + className : styles.Input;

    return (
        <input
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={classname}
            style={style}
            onKeyDown={handleKeyDown}
            tabIndex={tabIndex}
            ref={passedRef}
            autoComplete={autoComplete}
            id={id}
            {...props}
        />
    );
}
