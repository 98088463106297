import classNames from 'classnames';
import { setCurrentlyViewingProfile, setIsSidebarOpen, setShowingSettingsMenu } from '@/state/features/app';
import { Profile } from '@/models/profile';
import { useAppDispatch } from '@/state/store';

import * as styles from './styles.module.scss';

interface DisplayNameProps {
    shouldLinkToProfile?: boolean;
    profile: Profile;
    classname?: string;
}

export default function DisplayName({ profile, shouldLinkToProfile, classname }: DisplayNameProps) {
    const dispatch = useAppDispatch();

    if (shouldLinkToProfile) {
        return (
            <button
                className={classNames(styles.DisplayName, styles.hasHover, classname)}
                onClick={() => {
                    dispatch(setCurrentlyViewingProfile(profile));
                    dispatch(setShowingSettingsMenu(false));
                    dispatch(setIsSidebarOpen(true));
                }}
                title={`View ${profile?.displayName}'s Profile`}
            >
                {profile?.displayName}
            </button>
        );
    } else {
        return <span className={classNames(classname)}>{profile?.displayName}</span>;
    }
}
