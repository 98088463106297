import { createElement } from 'react';

interface BaseHeadingProps extends React.HTMLProps<HTMLHeadingElement> {
    fontType?: 'primary' | 'secondary';
    elementName?: string;
}

function Heading({ elementName, fontType = 'primary', className, children, style = {}, ...props }: BaseHeadingProps) {
    return createElement(
        elementName,
        {
            ...props,
            style: {
                fontFamily: `var(--font-${fontType})`,
                ...style,
            },
        },
        children,
    );
}

interface HeadingProps extends React.HTMLProps<HTMLHeadingElement> {
    fontType?: 'primary' | 'secondary';
}

export function H1({ style = {}, ...props }: HeadingProps) {
    return (
        <Heading
            {...props}
            elementName="h1"
            style={{
                fontSize: 55.31,
                ...style,
            }}
        />
    );
}

export function H2({ style = {}, ...props }: HeadingProps) {
    return (
        <Heading
            {...props}
            elementName="h2"
            style={{
                fontSize: 44.3,
                ...style,
            }}
        />
    );
}

export function H3({ style = {}, ...props }: HeadingProps) {
    return (
        <Heading
            {...props}
            elementName="h3"
            style={{
                fontSize: 35.4,
                ...style,
            }}
        />
    );
}

export function H4({ style = {}, ...props }: HeadingProps) {
    return (
        <Heading
            {...props}
            elementName="h4"
            style={{
                fontSize: 28.3,
                ...style,
            }}
        />
    );
}

export function H5({ style = {}, ...props }: HeadingProps) {
    return (
        <Heading
            {...props}
            elementName="h5"
            style={{
                fontSize: 22.7,
                ...style,
            }}
        />
    );
}

export function H6({ style = {}, ...props }: HeadingProps) {
    return (
        <Heading
            {...props}
            elementName="h6"
            style={{
                fontSize: 18.1,
                ...style,
            }}
        />
    );
}
