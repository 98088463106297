import { Profile, Rarity, Title } from '@/models/profile';
import { msTimeDifferenceFromNow } from './time';
import { pathToURL } from './urls';

// TODO: remove pathToURL once backend supplies urls.
export function processProfileData(profile: Profile) {
    return {
        ...profile,
        avatarImageUrl: pathToURL(profile.avatar.imagePath),
        factionImageUrl: pathToURL(profile.faction.imagePath),
        faction: {
            ...profile.faction,
            iconImageUrl: pathToURL(profile.faction.iconPath),
        },
        isBanned: profile.bannedUntil && msTimeDifferenceFromNow(profile.bannedUntil) > 0,
    };
}

export function processTitles(titles: Title[]): Title[] {
    // Remove duplicates based on the title property
    const uniqueTitlesMap = new Map<string, Title>();
    titles.forEach((title) => {
        uniqueTitlesMap.set(title.title, title);
    });

    // Convert the map back to an array
    const uniqueTitles = Array.from(uniqueTitlesMap.values());

    // Define the order of rarities
    const rarityOrder: Record<Rarity, number> = {
        Common: 1,
        Uncommon: 2,
        Rare: 3,
        Epic: 4,
        Legendary: 5,
    };

    // Sort the unique titles based on the rarity order
    uniqueTitles.sort((a, b) => rarityOrder[a.rarity] - rarityOrder[b.rarity]);

    return uniqueTitles;
}
