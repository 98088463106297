import { useEffect, useRef } from 'react';

interface InitialHeightMeasurerProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    onSizeUpdate: (size: { width: number; height: number }) => void;
    visible?: boolean;
}

export default function InitialHeightMeasurer({
    children,
    onSizeUpdate,
    style,
    visible = true,
    ...props
}: InitialHeightMeasurerProps) {
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (ref.current) {
            const { width, height } = ref.current.getBoundingClientRect();
            onSizeUpdate({ width, height });
        }
        return () => {
            // Gets called when is unmounted
            onSizeUpdate({ width: 0, height: 0 });
        };
    }, []);

    return (
        <div
            style={{
                width: '100%',
                height: !visible ? '0' : undefined,
                overflow: !visible ? 'hidden' : undefined,
            }}
            {...props}
        >
            <div style={{ width: '100%', ...style }} {...props} ref={ref}>
                {children}
            </div>
        </div>
    );
}
