import { Rarity } from '@/models/profile';
import * as styles from './styles.module.scss';
import classNames from 'classnames';

interface BadgeProps {
    text: string;
    rarity?: Rarity;
    isSystemBadge?: boolean; // e.g. Moderator
    iconImageUrl?: string;
    classname?: string;
    title?: string;
}

export default function Badge({ text, rarity, isSystemBadge, iconImageUrl, title, classname }: BadgeProps) {
    return (
        <div
            className={classNames(styles.Badge, styles[isSystemBadge ? 'system' : rarity?.toLowerCase()], classname)}
            title={title}
        >
            {iconImageUrl && <div className={styles.icon} style={{ maskImage: `url(${iconImageUrl})` }}></div>}
            <p>{text}</p>
        </div>
    );
}
