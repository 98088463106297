@import '../../style/globals.scss';

.SnackBar {
    position: absolute;
    z-index: 5;
    bottom: 50;
    left: 50%;
    box-sizing: border-box;
    width: 140px;
    height: 60px;
    background-color: var(--token-accent-theme-1-interface-interface-midground);
    transition: transform 200ms linear;
    border-radius: 60px;
    transform: translate(-50%, 0);
    overflow: hidden;

    &.hidden {
        transform: translate(-50%, calc(100% + 50px));
    }

    ul {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    li {
        button {
            width: 70px;
            height: 60px;
        }

        &:first-child {
            button {
                padding-left: 23px;
            }
        }

        &:last-child {
            button {
                padding-right: 23px;
            }
        }
    }

    .inventoryIcon.iconBounce {
        animation: icon-bounce 2s ease-in-out 3;

        @keyframes icon-bounce {
            0% {
                -webkit-transform: translateY(0);
            }
            15% {
                -webkit-transform: translateY(-15px);
            }
            20% {
                -webkit-transform: translateY(-15px);
            }
            35% {
                -webkit-transform: translateY(-6px);
            }
            40% {
                -webkit-transform: translateY(4px);
            }
            50% {
                -webkit-transform: translateY(-3px);
            }
            55% {
                -webkit-transform: translateY(1px);
            }
            60% {
                -webkit-transform: translateY(0);
            }
        }
    }
}
