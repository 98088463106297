@import '../../style/globals.scss';

.Settings {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    transition-property: transform;
    transition-duration: 0.4s;
    transition-timing-function: ease-in;
    transform: translate(-100%, 0);
    @media screen and (max-width: $bp-xs) {
        transform: translate(-150%, 0);
    }
}

.backdropBlur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    backdrop-filter: blur(0px);
    background-color: rgba(0, 0, 0, 0);
    visibility: hidden;
    // Parcel (or a downstream tool) will automatically convert backdrop-filter to -webkit-backdrop-filter, backdrop-filter (even if it's already mentioned), hence we have four duration,delay values and only three properties.
    transition-property: visibility, background-color, backdrop-filter;
    transition-duration: 0ms, 400ms, 400ms, 400ms;
    transition-delay: 450ms, 0ms, 0ms, 0ms;
}

.header {
    position: absolute;
    top: 0;
    left: $header-height;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: $header-height;
}

.content {
    position: absolute;
    top: $header-height;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 727px;
    max-width: 100%;
    height: calc(100% - 70px);
    color: var(--token-accent-theme-1-text-text-active);
    font-size: $font-size-h6;
    font-weight: 500;

    .logoutButton {
        width: 245px;
    }

    &:focus {
        outline: none;
    }

    .top {
        width: 400px;
        max-width: 100%;
        padding: 40px;

        @media screen and (max-width: $bp-s) {
            width: 300px;
            padding: 20px;
            font-size: $font-size-p;
        }
    }

    .middle {
        width: 550px;
        max-width: 100%;
        padding: 40px;
        margin-top: auto;

        a {
            color: var(--token-accent-theme-1-text-text-secondary);
            text-decoration: underline;
        }

        @media screen and (max-width: $bp-s) {
            width: 400px;
            padding: 20px;
            font-size: $font-size-p;
        }
    }

    .bottom {
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: end;
        height: 40%;
        padding: 40px;
        background-position: bottom left;
        background-size: contain;
        background-repeat: no-repeat;

        @media screen and (max-width: $bp-s) {
            padding: 20px;
        }
    }
}

.bottomLogo {
    width: 132px;
    padding-left: 15px;
}

.secondaryLogo {
    width: 130px;
    margin-left: 30px;
}

.Settings.visible {
    transform: translate(0%, 0);
    transition-timing-function: ease-out;
}

.backdropBlur.visible {
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.3);
    visibility: visible;
    transition-delay: 0ms, 0ms, 0ms, 0ms;
}
