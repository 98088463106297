import { useState, useEffect } from 'react';
import classNames from 'classnames';

import { msTimeDifferenceFromNow } from '@/utils/time';

import * as styles from './styles.module.scss';

interface CountdownProps extends React.HTMLAttributes<HTMLDivElement> {
    endTime?: string;
    startTime?: string;
    description?: string;
    className?: string;
    omitDaysAndHoursIfEmpty?: boolean; // e.g if 00:00:10:20 just show 10:20
    maxPreCountdownSeconds?: number; // e.g. 3
}

const msSeconds = 1000;
const msMinutes = 1000 * 60;
const msDay = 1000 * 60 * 60 * 24;
const msHour = 1000 * 60 * 60;

const EMPTY_COUNTDOWN_CONTENT = {
    days: '--',
    hours: '--',
    minutes: '--',
    seconds: '--',
};

const ZERO_COUNTDOWN_CONTENT = {
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
};

export default function Countdown({
    startTime,
    endTime,
    description,
    omitDaysAndHoursIfEmpty,
    className,
    maxPreCountdownSeconds,
}: CountdownProps) {
    const formatTimeLeft = (): { days: string; hours: string; minutes: string; seconds: string } => {
        if (!endTime) {
            return EMPTY_COUNTDOWN_CONTENT;
        }

        const difference = msTimeDifferenceFromNow(endTime);

        if (isNaN(difference)) {
            return EMPTY_COUNTDOWN_CONTENT;
        }
        if (difference <= 0) {
            return ZERO_COUNTDOWN_CONTENT;
        }

        const pad = (num: number): string => `${num}`.padStart(2, '0');
        const timeLeft = {
            days: pad(Math.floor(difference / msDay)),
            hours: pad(Math.floor((difference % msDay) / msHour)),
            minutes: pad(Math.floor((difference % msHour) / msMinutes)),
            seconds: pad(Math.floor((difference % msMinutes) / msSeconds)),
        };
        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(formatTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(formatTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    // Render pre-countdown, where 0 is when the main countdown begins
    if (startTime) {
        const secondsTillStart = Math.floor((msTimeDifferenceFromNow(startTime) % msMinutes) / msSeconds);

        if (secondsTillStart > 0) {
            if (maxPreCountdownSeconds && secondsTillStart > maxPreCountdownSeconds) {
                return null;
            }
            return (
                <div className={classNames(styles.Countdown, className)}>
                    <div className={styles.Countdown__timer} role="timer">
                        <span>{secondsTillStart}...</span>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={classNames(styles.Countdown, className)}>
            <span className={styles.Countdown__description}>{description}</span>
            <div className={styles.Countdown__timer} role="timer">
                {!(omitDaysAndHoursIfEmpty && timeLeft.days === '00') && <span>{timeLeft.days}:</span>}
                {!(omitDaysAndHoursIfEmpty && timeLeft.days === '00' && timeLeft.hours === '00') && (
                    <span>{timeLeft.hours}:</span>
                )}
                <span>{timeLeft.minutes}:</span>
                <span>{timeLeft.seconds}</span>
            </div>
        </div>
    );
}
