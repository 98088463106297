// For logic about locations which are specific to only one location ID
import { getArisewareState } from '@/arise/api';

interface LocationOverride {
    locationID: string;
    isLockedOut: boolean;
}

interface LocationOverrides {
    [locationID: string]: LocationOverride;
}

const getIsMineComplete = async () => {
    try {
        const result = await getArisewareState(process.env.PUBLIC_TITAN_MINE_ARISEWARE_ID);
        return result?.state?.pc === true;
    } catch (error) {
        return false;
    }
};
// Fetches the overrides for a given locationID
const getLocationOverride = async (locationID: string): Promise<LocationOverride> => {
    if (process.env.PUBLIC_INSTANCE === 'titan') {
        if (locationID === process.env.PUBLIC_TITAN_CAMP_ID) {
            const isMineComplete = await getIsMineComplete();
            return { locationID: locationID, isLockedOut: !isMineComplete };
        }
    }
    return { locationID: locationID, isLockedOut: false };
};

/**
 * getLocationOverrides
 * @param {string[]} locationIDs - An array of location ID strings
 * @example
 * getLocationOverrides(['585f76c3-751f-4ac1-bc4a-ed443c2aad20', '5c487ba3-533b-444a-9751-2acbd012c06f'])
 * @return {Promise<LocationOverrides>}
 * @example
 * {
 *    '585f76c3-751f-4ac1-bc4a-ed443c2aad20': { locationID: '585f76c3-751f-4ac1-bc4a-ed443c2aad20', isLockedOut: true },
 *    '5c487ba3-533b-444a-9751-2acbd012c06f': { locationID: '5c487ba3-533b-444a-9751-2acbd012c06f',isLockedOut: false },
 * }
 */

export const getLocationOverrides = async (locationIDs: string[]): Promise<LocationOverrides> => {
    const overridePromises = locationIDs.map(getLocationOverride);
    const overrides = await Promise.all(overridePromises);
    // Combine results into a single object
    return overrides.reduce((combinedOverrides, override) => {
        combinedOverrides[override.locationID] = override;
        return combinedOverrides;
    }, {});
};
