import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import SceneSelectorTile from '@/components/SceneSelector/SceneSelectorTile';
import { transitionStates } from '@/utils/pageTransition';
import { useOnLoadImages } from '@/hooks/useOnLoadImages';
import { useAudio } from '@/hooks/audio';
import { SceneSelectorScene, SceneSelectorSettings } from '@/models/misc';
import { sendEvent } from '@/utils/analytics';
import { pathToURL } from '@/utils/urls';

import * as styles from './styles.module.scss';

interface SceneSelectorProps {
    scenes: SceneSelectorScene[];
    sceneSelectorSettings: SceneSelectorSettings;
    transitionState: transitionStates;
    onSceneSelected?: (id: string) => void;
    onParallaxLoaded: () => void;
}

export default function SceneSelector({
    scenes,
    sceneSelectorSettings,
    transitionState,
    onSceneSelected,
    onParallaxLoaded,
}: SceneSelectorProps) {
    const parallaxImagesWrapperRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const areParallaxImagesLoaded = useOnLoadImages(parallaxImagesWrapperRef, 5000);
    const [currentScene, setCurrentScene] = useState<SceneSelectorScene>(scenes?.[0]);
    const lastSwipeTime = useRef(0);
    const previousScenesValue = useRef<SceneSelectorScene[]>([]);

    const { audio } = useAudio();

    const SWIPE_DELAY = 1000; // Should match SASS $transition-tiles duration
    const currentSceneId = currentScene?.environmentID;
    const initialSceneNumber = sceneSelectorSettings.initialSceneOrder;

    const getCurrentIndex = () => {
        const currentSceneIndex = scenes.map((scene) => scene.environmentID).indexOf(currentSceneId);
        return currentSceneIndex < 0 ? 0 : currentSceneIndex;
    };

    const currentIndex = getCurrentIndex();

    function onClick(sceneID: string) {
        if (sceneID === currentSceneId) {
            onSceneSelected(currentSceneId);
        } else {
            // Animate to scene
            setCurrentScene(scenes.find((scene) => scene.environmentID === sceneID));
            audio.play(SCROLL_AUDIO_ID);
            sendEvent('scene_selector_navigate', { scene_id: sceneID });
        }
    }

    function doesScrollNeedResetting(previousScenes, newScenes) {
        if (previousScenes.length !== newScenes.length) {
            return true;
        }
        for (let i = 0; i < previousScenes.length; i++) {
            if (previousScenes[i].locationId !== newScenes[i].locationId) {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        if (doesScrollNeedResetting(previousScenesValue.current, scenes)) {
            setCurrentScene(scenes[initialSceneNumber] ?? scenes[0]);
        }
        previousScenesValue.current = scenes;
    }, [scenes]);

    useEffect(() => {
        if (areParallaxImagesLoaded) {
            onParallaxLoaded();
        }
    }, [areParallaxImagesLoaded]);

    useEffect(() => {
        // Handle swiping left and right on mobile
        let start = null;
        function onTouchStart(e: TouchEvent) {
            start = e.changedTouches[0];
        }

        function onTouchEnd(e: TouchEvent) {
            const end = e.changedTouches[0];
            if (end?.screenX - start?.screenX > 0) {
                swipe(-1);
            } else if (end?.screenX - start?.screenX < 0) {
                swipe(1);
            }
        }

        containerRef.current?.addEventListener('touchstart', onTouchStart);
        containerRef.current?.addEventListener('touchend', onTouchEnd);

        const swipe = (direction: number) => {
            // Do not allow another swipe for SWIPE_DELAY ms
            if (Date.now() - lastSwipeTime.current < SWIPE_DELAY) {
                return;
            }
            const newId = scenes[currentIndex + direction]?.environmentID;
            if (newId) {
                // If swipe valid, animate to next or previous scene
                setCurrentScene(scenes.find((scene) => scene.environmentID === newId));
                lastSwipeTime.current = Date.now();
            }
        };

        return () => {
            containerRef.current?.removeEventListener('touchstart', onTouchStart);
            containerRef.current?.removeEventListener('touchend', onTouchEnd);
        };
    }, [currentSceneId]);

    // Load audio from CMS
    const SCROLL_AUDIO_ID = 'scene-selector-scroll';
    const BACKGROUND_AUDIO_ID = 'scene-selector-bg-music';
    const getSceneAudioID = (sceneIndex: number) => 'scene-selector-bg-audio-' + sceneIndex;
    const loadAudio = () => {
        if (!audio.hasTrack(SCROLL_AUDIO_ID)) {
            audio.addTrack({
                id: SCROLL_AUDIO_ID,
                src: sceneSelectorSettings.scrollAudio,
                ambient: false,
                volume: 0.8,
            });
        }

        if (!audio.hasTrack(BACKGROUND_AUDIO_ID)) {
            audio.addTrack({
                id: BACKGROUND_AUDIO_ID,
                src: sceneSelectorSettings.backgroundAudio,
                ambient: true,
                volume: 0.5,
            });
        }

        scenes.forEach((scene, index) => {
            if (!audio.hasTrack(getSceneAudioID(index))) {
                audio.addTrack({
                    id: getSceneAudioID(index),
                    src: scene.backgroundAudio,
                    ambient: true,
                    volume: 0.5,
                });
            }
        });
    };

    useEffect(() => {
        loadAudio();
        // Play scene selector background audio for all scenes
        audio.playOnly(BACKGROUND_AUDIO_ID);

        setCurrentScene(scenes[0]);
        setTimeout(() => {
            setCurrentScene(scenes[initialSceneNumber] ?? scenes[0]);
        }, 100);

        // Returning this function to stop all audio when the component is unmounted
        return () => {
            // TODO: this should fade out slowly
            audio.stopAll();
        };
    }, []);

    useEffect(() => {
        // Stop all background music from other scenes
        scenes.forEach((scene, index) => {
            if (audio.hasTrack(getSceneAudioID(index))) {
                audio.stop(getSceneAudioID(index));
            }
        });

        // Play scene background music from CMS
        audio.play(getSceneAudioID(currentIndex));
    }, [currentSceneId]);

    const renderScenes = () => {
        if (scenes && scenes.length) {
            const getIsOnScreen = (index: number) => {
                if (currentIndex === 0) return index < 2;
                return index > currentIndex - 2 && index < currentIndex + 2;
            };
            return scenes.map((scene, index) => (
                <li className={styles.SceneSelector__sceneContainer} key={index}>
                    <SceneSelectorTile
                        description={scene.openLowerText}
                        lockedText={scene.closedLowerText}
                        tabbable={getIsOnScreen(index)}
                        onClick={() => onClick(scene.environmentID)}
                        isInCenter={index === currentIndex}
                        isOpen={scene.isOpen}
                        imageUrl={pathToURL(scene.openThumbnailImagePath)}
                        lockedImageUrl={pathToURL(scene.closedThumbnailImagePath)}
                        imageMaskUrl={pathToURL(sceneSelectorSettings.thumbnailImageMaskPath)}
                    />
                </li>
            ));
        } else {
            return <></>;
        }
    };

    const renderTextboxes = () => {
        const backgroundImage = pathToURL(sceneSelectorSettings.upperTextBackgroundImagePath);
        if (scenes && scenes.length) {
            return scenes.map((scene, index) => {
                const text = scene.isOpen ? scene.openUpperText : scene.closedUpperText;
                return (
                    <div
                        className={classNames(styles.StoryText__Item)}
                        key={index}
                        style={{ width: `${100 / scenes.length}%` }}
                    >
                        <p
                            style={{
                                borderImageSource: `url(${backgroundImage})`,
                                backgroundImage: `url(${backgroundImage})`,
                                visibility: text ? 'visible' : 'hidden',
                            }}
                        >
                            {text}
                        </p>
                    </div>
                );
            });
        } else {
            return <></>;
        }
    };

    function getParallaxValue(layerId: string) {
        // Distance layer is from camera
        const distances = {
            bg: -10,
            mg: 0,
            fg: 5,
        };
        // First and last scenes have distances of 0
        const isLastScene = currentIndex === scenes.length - 1;
        const distance = isLastScene ? 0 : distances[layerId];
        const percent = currentIndex * (100 + distance);
        return `-${percent}%`;
    }

    const renderParallax = () => {
        const getImageUrl = (path: string) => {
            const url = pathToURL(path);
            return window.innerWidth <= 1400 ? url + '?h=540' : url + '?h=1080';
        };

        return (
            <div className={styles.SceneSelector__parallaxContainer}>
                <div className={styles.SceneSelector__parallax} ref={parallaxImagesWrapperRef}>
                    <img
                        className={classNames(styles.parallaxLayer, styles['parallaxLayer-bg'])}
                        key={0}
                        src={getImageUrl(sceneSelectorSettings.backgroundImagePath)}
                        style={{ left: getParallaxValue('bg') }}
                        aria-hidden="true"
                    ></img>
                    <img
                        className={classNames(styles.parallaxLayer, styles['parallaxLayer-mg'])}
                        key={1}
                        src={getImageUrl(sceneSelectorSettings.midgroundImagePath)}
                        style={{ left: getParallaxValue('mg') }}
                        aria-hidden="true"
                    ></img>
                    <img
                        className={classNames(styles.parallaxLayer, styles['parallaxLayer-fg'])}
                        key={2}
                        src={getImageUrl(sceneSelectorSettings.foregroundImagePath)}
                        style={{ left: getParallaxValue('fg') }}
                        aria-hidden="true"
                    ></img>
                    <div className={styles.parallaxLayerFrontCover}></div>
                </div>
                <div
                    className={styles.StoryText}
                    style={{ left: `-${currentIndex * 100}%`, width: `${scenes.length * 100}%` }}
                >
                    {renderTextboxes()}
                </div>
            </div>
        );
    };

    return (
        <div
            className={classNames(styles.SceneSelector, styles[`scene-${currentIndex}`], styles[transitionState])}
            ref={containerRef}
        >
            {renderParallax()}
            <div className={styles.SceneSelector__scenes}>
                <ul>{renderScenes()}</ul>
            </div>
        </div>
    );
}
