import classNames from 'classnames';
import * as styles from './styles.module.scss';
import { useAppDispatch, useAppSelector } from '@/state/store';
import {
    setIsInventoryOpen,
    setIsExitLocationModalOpen,
    setIsSnackbarVisible,
    setIsUnseenItemInInventory,
} from '@/state/features/app';

import IconButton from '../IconButton';
import { sendEvent } from '@/utils/analytics';

export default function SnackBar() {
    const dispatch = useAppDispatch();
    const { isSnackbarVisible, arisewareIsSnackbarVisible, isUnseenItemInInventory } = useAppSelector(
        (state) => state.app,
    );
    const isVisible = isSnackbarVisible && arisewareIsSnackbarVisible !== false;

    return (
        <div className={classNames(styles.SnackBar, { [styles.hidden]: !isVisible })} aria-hidden={!isVisible}>
            <ul>
                <li className={classNames(styles.inventoryIcon, { [styles.iconBounce]: isUnseenItemInInventory })}>
                    <IconButton
                        title="Open Inventory"
                        iconType="inventory"
                        ariaLabel="Open Inventory"
                        buttonStyle="icon"
                        onClick={() => {
                            dispatch(setIsInventoryOpen(true));
                            dispatch(setIsUnseenItemInInventory(false));
                            dispatch(setIsSnackbarVisible(false));
                            sendEvent('open_inventory');
                        }}
                        disabled={!isVisible}
                    />
                </li>
                <li>
                    <IconButton
                        title="Return to World"
                        iconType="world"
                        ariaLabel="Return to World"
                        buttonStyle="icon"
                        onClick={() => {
                            dispatch(setIsExitLocationModalOpen(true));
                            dispatch(setIsInventoryOpen(false));
                            dispatch(setIsSnackbarVisible(false));
                            sendEvent('open_exit_modal');
                        }}
                        disabled={!isVisible}
                    />
                </li>
            </ul>
        </div>
    );
}
