import { useTheme } from '@/theme';

export default function EditProfileLayout({ children }: { children: React.ReactNode }) {
    const theme = useTheme();

    if (!theme.loaded) return <></>;

    return (
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
            <div>
                <div>{children}</div>
            </div>
        </div>
    );
}
