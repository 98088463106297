@import '../../../style/globals.scss';

.FeedInputOverlay {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: 52px;
    padding: 10px 35px 10px 20px;
    border: 1px solid var(--token-accent-theme-1-border-border-default);
    background-color: var(--token-arise-theme-1-state-state-error-mid);
    color: var(--token-accent-theme-1-brand-colour);
    font-size: $font-size-p;
    border-radius: 10px;

    svg {
        position: absolute;
        right: 10px;
        path {
            fill: var(--token-accent-theme-1-brand-colour);
        }
    }
}
