@import '../../../style/globals.scss';

.Input {
    border-radius: 10px;
    padding: 15px;
    border: 1px solid #ffffff;
    border-color: var(--token-accent-theme-1-border-border-default);
    background-color: rgba($color: #000000, $alpha: 0.5);
    outline: none;
    color: var(--token-accent-theme-1-text-text-default);
    font-size: $font-size-p;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    transition: 0.35s border;

    &::placeholder {
        color: var(--token-accent-theme-1-text-text-secondary);
    }
    &:focus {
        border-color: var(--token-accent-theme-1-border-border-highlight);
        outline: none;
    }
}
