import { useAppSelector } from '@/state/store';

export function useLocations() {
    const { locations, isFetchingLocations, areas, isFetchingAreas, regions, isFetchingRegions } = useAppSelector(
        (state) => state.app,
    );

    const isLoading = isFetchingLocations || isFetchingAreas || isFetchingRegions;

    return {
        locations,
        areas,
        regions,
        isLoading,
    };
}
