@import '../../style/globals.scss';

.IconButton:disabled {
    pointer-events: none;
}

.isBlockStyle {
    width: $header-height;
    height: $header-height;
    background-color: var(--token-accent-theme-1-button-button-default);

    svg {
        path {
            fill: var(--token-accent-theme-1-interface-interface-white);
        }
    }

    &:focus,
    &:hover {
        background-color: var(--token-accent-theme-1-button-button-highlight);
    }
}

.isRoundStyle {
    width: 60px;
    height: 60px;
    background-color: var(--token-accent-theme-1-interface-interface-midground);
    border-radius: 100%;

    svg {
        path {
            fill: var(--token-accent-theme-1-interface-interface-white);
        }
    }

    &:focus,
    &:hover {
        background-color: var(--token-accent-theme-1-interface-interface-foreground);
    }
}

.isIconStyle {
    width: 24px;
    height: 24px;

    svg {
        path {
            fill: var(--token-accent-theme-1-interface-interface-grey);
        }
    }

    &:focus,
    &:hover {
        svg {
            path {
                fill: var(--token-accent-theme-1-interface-interface-white);
            }
        }
    }
}

.isDiamondStyle {
    width: 75px;
    height: 75px;
    mask-image: url('/assets/themes/titan/images/diamond-button-bg.svg');
    mask-size: 100% 100%;
    background-color: var(--token-accent-theme-1-interface-interface-foreground);

    svg {
        path {
            fill: var(--token-accent-theme-1-button-button-secondary);
        }
    }

    &:focus,
    &:hover {
        background-color: var(--token-accent-theme-1-interface-interface-active);
        svg {
            path {
                fill: var(--token-accent-theme-1-button-button-highlight);
            }
        }
    }
}
