import { useMemo } from 'react';
import classnames from 'classnames';

import MessageRenderer from '@/components/Feed/ChatMessage/MessageRenderer';
import ModerationActions from '@/components/Feed/ChatMessage/ModerationActions';
import Avatar from '@/components/Avatar';
import Reactions from '@/components/Feed/Reactions';
import IsAdmin from '@/components/UserLogic/IsAdmin';
import DisplayNameWithBadges from '@/components/DisplayNameWithBadges';
import { ChatMessage as ChatMessageI } from '@/models/feed';
import { toggleMessageReaction } from '@/state/features/feed';
import { setCurrentlyViewingProfile } from '@/state/features/app';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { sendEvent } from '@/utils/analytics';
import { pathToURL } from '@/utils/urls';

import * as styles from './styles.module.scss';

interface ChatMessageProps {
    message: ChatMessageI;
}

export default function ChatMessage({ message }: ChatMessageProps) {
    const locationId = useAppSelector((state) => state.app.location.id);
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);
    const dispatch = useAppDispatch();

    const profile = message.profile;

    function handleReactionSubmit(reaction: string) {
        dispatch(toggleMessageReaction([message.id, reaction, locationId]));
        sendEvent('toggle_reaction', { reaction });
    }

    const humanTimestamp = useMemo(() => {
        const date = new Date(message.timestamp * 1000);
        return date.toLocaleString();
    }, [message.timestamp]);

    const isVisible = !!profile;

    let profileImageUrl = profile?.avatarImageUrl;
    if (!profileImageUrl) {
        profileImageUrl = pathToURL(profile?.avatar.imagePath);
    }

    const containsMentionForCurrentUser = message.mentions?.some(
        (profile) => profile.userID === currentUserProfile.userID,
    );

    return (
        <div className={styles.ChatMessageWrapper}>
            <div
                className={classnames('text-[14px] relative', styles.ChatMessage, {
                    [styles.visible]: isVisible,
                    [styles.highlighted]: containsMentionForCurrentUser,
                })}
            >
                <Avatar
                    classname={styles.avatar}
                    imageUrl={profileImageUrl}
                    onClick={() => dispatch(setCurrentlyViewingProfile(profile))}
                    title={`View ${profile.displayName}'s Profile`}
                />
                <div>
                    <DisplayNameWithBadges
                        profile={profile}
                        shouldLinkToProfile
                        displayNameClassname={styles.displayName}
                    />
                    <div title={humanTimestamp}>
                        <MessageRenderer message={message} />
                    </div>
                    <div>
                        <Reactions
                            reactions={message.reactions}
                            onSubmitReaction={handleReactionSubmit}
                            controlsClassName={styles.reactionsMenu}
                            disabled={currentUserProfile?.isBanned}
                            currentUserId={currentUserProfile?.userID}
                        />
                    </div>
                </div>
                <IsAdmin>
                    <ModerationActions
                        messageId={message.id}
                        userId={message.senderId}
                        userDisplayName={profile?.displayName}
                        userBannedUntil={profile?.bannedUntil}
                    />
                </IsAdmin>
            </div>
        </div>
    );
}
