import { ChatEvent } from '@/models/feed';
import { submitFeedUpdate } from '@/state/features/app';
import { submitMessage } from '@/state/features/feed';
import { editProfileTitle } from '@/state/features/profile';

export function getHasPastedChatEventWinString(text: string, currentEvent: ChatEvent) {
    // TODO check if participant
    if (currentEvent?.isActive) {
        if (text.includes(currentEvent.eventParams.winString)) {
            return true;
        }
    }
    return false;
}

export function submitPastedChatEventWinString(locationId: string, dispatch) {
    dispatch(submitMessage([locationId, 'I tried to cheat by copy and pasting']));
    dispatch(submitFeedUpdate('paste_and_debased'));
    dispatch(editProfileTitle('paste_and_debased'));
}
