@import '../../style/globals.scss';

.ItemContainer {
    $transition-duration: 400ms;

    position: relative;

    &:last-child {
        margin-right: 0px;
    }

    &__BackgroundContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        transition: filter $transition-duration $animation-bounce;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &__Button {
        position: relative;
        // width: 203px;
        // height: 237px;
        width: 100%;
        aspect-ratio: 203 / 237;
        background-size: 100% 100%;

        &--interactable {
            &:hover {
                .ItemContainer {
                    &__Border {
                        border-color: var(--token-accent-theme-1-border-border-highlight);
                    }
                }
            }
            &:focus,
            &:hover,
            &.btn-selected {
                .ItemContainer {
                    &__Image {
                        transform: scale(1.2);
                    }

                    &__BackgroundContainer {
                        filter: brightness(1.2);
                    }
                }
            }

            &.btn-selected {
                .ItemContainer {
                    &__Border {
                        border-color: var(--token-accent-theme-1-brand-colour-contrast);
                    }
                    &__Image {
                        filter: brightness(1.75);
                    }
                }

                & ~ .ItemContainer__HighlightImage {
                    transition-duration: 200ms;
                    transition-timing-function: $animation-bounce;
                    transition-property: background-size, opacity;
                    opacity: 1;
                    background-size: 100% 100%;
                }
            }
        }
    }

    &__Border {
        $outline-offset: 10px;
        position: absolute;
        width: calc(100% - ($outline-offset * 2));
        height: calc(100% - ($outline-offset * 2));
        border: 1.5px solid var(--token-accent-theme-1-border-border-default);
        margin: $outline-offset;
        inset: 0;
        transition: border-color 500ms $animation-bounce;
    }

    &__Image {
        position: relative;
        width: 50%;
        margin-right: auto;
        margin-left: auto;
        transition: transform $transition-duration $animation-bounce;
        user-select: none;
        pointer-events: none;
    }

    &__HighlightImage {
        position: absolute;
        z-index: -1;
        top: -135px;
        left: -15px;
        width: 224px;
        height: 491px;
        background-repeat: no-repeat;
        background-position-y: bottom;
        rotate: 30deg;
        transition: none;
        opacity: 0;
        background-size: 100% 0%;
    }

    &__Text {
        position: absolute;
        right: 0;
        bottom: -8px;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        aspect-ratio: 162 / 47;
        padding: 9px;
        margin-left: 12px;
        margin-right: 12px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    &__Title {
        font-size: $font-size-p;
        font-family: var(--font-primary);
        text-transform: uppercase;

        &.longTitle {
            font-size: $font-size-tiny;
        }

        @media screen and (max-height: 800px) {
            font-size: $font-size-small;

            &.longTitle {
                font-size: $font-size-tiny;
            }
        }
    }

    &__Subtitle {
        font-size: $font-size-tiny;
        margin-top: -4px;
    }

    @keyframes slide-in {
        from {
            bottom: -200px;
            opacity: 0;
        }
        to {
            bottom: 0px;
            opacity: 1;
        }
    }
}
