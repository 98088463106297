import { FAILURE_TIMEOUT_SECONDS } from '@/globals';
import { useAppSelector } from '@/state/store';
import { millisecondsLeftFromTimeAndDuration } from '@/utils/time';
import { useEffect, useMemo, useState } from 'react';

export default function useFailed() {
    const failedAt = useAppSelector((state) => state.app.failedAt);
    // const hasFailed = useMemo(() => Date.now() - failedAt < FAILURE_TIMEOUT_SECONDS * 1000, [failedAt]);

    return { hasFailed: !!failedAt, failedAt };
}

export function useFailedWithTimer() {
    const { hasFailed, failedAt } = useFailed();
    const [secondsLeft, setSecondsLeft] = useState(0);
    const [canReattempt, setCanReattempt] = useState(false);

    useEffect(() => {
        function calcAndSetSecondsLeft() {
            const seconds = Math.ceil(millisecondsLeftFromTimeAndDuration(failedAt, FAILURE_TIMEOUT_SECONDS) / 1000);
            setSecondsLeft(seconds);
            if (seconds <= 0) {
                setCanReattempt(true);
            } else {
                setCanReattempt(false);
            }
        }

        calcAndSetSecondsLeft();

        if (hasFailed) {
            const interval = setInterval(() => {
                calcAndSetSecondsLeft();
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [hasFailed, failedAt]);

    return { secondsLeft, hasFailed, failedAt, canReattempt };
}
