import { AriseLocation } from '@/models/location';
import { msTimeDifferenceFromNow } from './time';

export function processLocationData(location: AriseLocation) {
    return {
        ...location,
        isOpen: isLocationOpen(location),
        isFeedOpen: isFeedOpen(location),
    };
}

export function isLocationOpen(location: AriseLocation) {
    // Default to open if there is no opening time
    const isAfterOpeningTime = !location.opensTime || msTimeDifferenceFromNow(location.opensTime) <= 0;
    const isAfterClosingTime = location.closesTime && msTimeDifferenceFromNow(location.closesTime) <= 0;
    return isAfterOpeningTime && !isAfterClosingTime;
}

export function isFeedOpen(location: AriseLocation) {
    // Default to open if there is no opening time
    const isAfterFeedOpeningTime = !location.feedOpensTime || msTimeDifferenceFromNow(location.feedOpensTime) <= 0;
    const isAfterFeedClosingTime = location.feedClosesTime && msTimeDifferenceFromNow(location.feedClosesTime) <= 0;
    return isAfterFeedOpeningTime && !isAfterFeedClosingTime;
}
