@import '../../style/globals.scss';

.modalDrawer {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    transition: top 300ms ease-out, transform 300ms ease-out;
    z-index: 5;

    &.visible {
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.Modal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 565px;
    max-width: 100%;
    height: 227px;
    padding: 25px 20px 55px 30px;
    background-size: 100% 100%;
    color: var(--token-accent-theme-1-text-text-default);
    text-align: center;

    &.noBackgroundImage {
        background-color: var(--token-accent-theme-1-interface-interface-foreground);
    }

    .closeButton {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -5px;
    }
}
