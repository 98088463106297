import { mapPath } from '@/theme/vars';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface MapGateProps {
    children: React.ReactNode;

    targetPath: string;
    redirectPath?: string;
}

export default function MapGate({ children, targetPath, redirectPath }: MapGateProps) {
    const navigate = useNavigate();

    const redirPath = redirectPath || mapPath;

    useEffect(() => {
        if (mapPath !== targetPath) {
            navigate(mapPath);
        }
    }, []);

    if (mapPath === targetPath) {
        return <>{children}</>;
    } else {
        return <></>;
    }
}
