import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from '@/state/store';
import { setIsProfileOpen } from '@/state/features/app';
import { getArisewareScoresForUser, getTitles } from '@/arise/api';
import { useAudio } from '@/hooks/audio';
import { ArisewareScore } from '@/models/misc';
import { Title } from '@/models/profile';
import { secondsIntoTimeString } from '@/utils/time';
import IconButton from '@/components/IconButton';
import LoadingScreen from '@/components/LoadingScreen';
import Badge from '@/components/Badge';
import { processTitles } from '@/utils/profile';

import * as styles from './styles.module.scss';

export default function ProfileDrawer() {
    const dispatch = useAppDispatch();
    const { audio } = useAudio();
    const { isProfileOpen, currentlyViewingProfile: profile } = useAppSelector((state) => state.app);
    const [previousUserID, setPreviousUserID] = useState(null);
    const [arisewareScores, setArisewareScores] = useState(null);
    const [titles, setTitles] = useState(null);
    const [avatarUrl, setAvatarUrl] = useState(null);
    const [factionImageUrl, setFactionImageUrl] = useState(null);

    const isLoading = !(
        arisewareScores !== null &&
        titles !== null &&
        avatarUrl !== null &&
        factionImageUrl !== null &&
        previousUserID === profile?.userID
    );

    useEffect(() => {
        if (isProfileOpen && profile?.userID) {
            let areRequestsStale = false; // To stop stale request issues
            if (previousUserID !== profile.userID) {
                // If is a different user, clear the old data
                setArisewareScores(null);
                setTitles(null);
                setAvatarUrl(null);
                setFactionImageUrl(null);
                // Load images
                const avatarImg = new Image();
                avatarImg.src = profile?.avatarImageUrl;
                avatarImg.onload = () => setAvatarUrl(profile?.avatarImageUrl);
                avatarImg.onerror = () => setAvatarUrl('');

                const factionImg = new Image();
                factionImg.src = profile?.factionImageUrl;
                factionImg.onload = () => setFactionImageUrl(profile?.factionImageUrl);
                factionImg.onerror = () => setFactionImageUrl('');
            }
            getArisewareScoresForUser(profile.userID)
                .then((result) => {
                    if (!areRequestsStale) {
                        setArisewareScores(result);
                    }
                })
                .catch(() => {
                    if (!areRequestsStale) {
                        setArisewareScores([]);
                    }
                });

            getTitles(profile.userID)
                .then((result) => {
                    if (!areRequestsStale) {
                        const processedTitles = processTitles(result);
                        setTitles(processedTitles);
                    }
                })
                .catch(() => {
                    if (!areRequestsStale) {
                        setTitles([]);
                    }
                });
            setPreviousUserID(profile.userID);
            return () => {
                // Drawer closed, or ID changed, so set all requests as stale
                areRequestsStale = true;
            };
        }
    }, [isProfileOpen, profile?.userID, profile?.title?.title]);

    useEffect(() => {
        if (isProfileOpen) {
            audio?.play('gust-open');
        }
    }, [isProfileOpen]);

    const getFormattedScoreString = (score: ArisewareScore) => {
        if (score.score) {
            return secondsIntoTimeString(score.score);
        }
        return score.isCompleted ? 'Complete' : 'Incomplete';
    };

    return (
        <div
            className={classNames(styles.ProfileDrawer, {
                [styles['isOpen']]: isProfileOpen,
                [styles.isLoading]: isLoading,
            })}
            inert={!isProfileOpen ? '' : undefined}
        >
            <div className={styles.factionImage} style={{ backgroundImage: `url(${factionImageUrl})` }}></div>
            <div className={styles.topBar}>
                <IconButton
                    iconType="close"
                    buttonStyle="icon"
                    ariaLabel="Close Profile"
                    className={styles.closeButton}
                    onClick={() => {
                        audio?.play('gust-close');
                        dispatch(setIsProfileOpen(false));
                    }}
                />
                <div className={styles.heading}>
                    <span>User</span>
                    <span>Profile</span>
                </div>
            </div>
            <div className={styles.content} inert={isLoading ? '' : undefined}>
                {profile && (
                    <>
                        <div className={styles.userInfo}>
                            <div className={styles.userInfoContent}>
                                {avatarUrl ? (
                                    <img alt="User Avatar" className={classNames(styles.avatar)} src={avatarUrl}></img>
                                ) : (
                                    <div className={styles.loadingAvatar}></div>
                                )}
                                <h2>{profile.displayName}</h2>
                                {profile?.role === 'superadmin' && (
                                    <Badge text="Moderator" isSystemBadge={true} classname={styles.badge} />
                                )}
                                <Badge
                                    text={profile.faction?.name}
                                    rarity={profile.title?.rarity}
                                    iconImageUrl={profile.faction?.iconImageUrl}
                                    classname={styles.badge}
                                />
                            </div>
                        </div>
                        {!!arisewareScores?.length && (
                            <div className={styles.scores}>
                                <ul>
                                    {arisewareScores?.map((score: ArisewareScore, index: number) => {
                                        return (
                                            <li key={index}>
                                                <span>{score.displayName}:</span>
                                                <span
                                                    className={classNames({
                                                        [styles.success]: score.isCompleted,
                                                        [styles.failure]: !score.isCompleted,
                                                    })}
                                                >
                                                    {getFormattedScoreString(score)}
                                                </span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                        {!!titles?.length && (
                            <div className={styles.titlesUnlocked}>
                                <h3>Titles Unlocked:</h3>
                                <ul>
                                    {titles?.map((title: Title, index: number) => (
                                        <li key={index}>
                                            <Badge
                                                text={title.title}
                                                rarity={title.rarity}
                                                title={title.rarity?.toString()}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </>
                )}
                <LoadingScreen show={isLoading} classname={styles.loadingScreen} />
            </div>
        </div>
    );
}
