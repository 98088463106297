import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { BP_MOBILE_CHAT } from '@/globals';
import Feed from '@/components/Feed';
import SidebarEvent from '@/components/Events/SidebarEvent';
import FailedOverlay from '@/components/Sidebar/FailedOverlay';
import IconButton from '@/components/IconButton';
import ProfileDrawer from '@/components/ProfileDrawer';
import { setIsSidebarOpen, toggleSidebarOpen } from '@/state/features/app';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { useAudio } from '@/hooks/audio';
import { sendEvent } from '@/utils/analytics';
import * as styles from './styles.module.scss';

interface SidebarProps {
    locationTitle: string;
    locationName: string;
}

export default function Sidebar({ locationTitle, locationName }: SidebarProps) {
    const dispatch = useAppDispatch();
    const { isSidebarOpen, location } = useAppSelector((state) => state.app);
    const { audio } = useAudio();
    const [buttonHasBeenClickedOnce, setButtonHasBeenClickedOnce] = useState(false);

    useEffect(() => {
        // Open the sidebar if the user is not on mobile
        const isMobile = window.innerWidth <= BP_MOBILE_CHAT;

        dispatch(setIsSidebarOpen(!isMobile));
    }, []);

    return (
        <div className={styles.Sidebar} style={{ backdropFilter: `blur(10px)` }}>
            <div className={styles.top}>
                <IconButton
                    iconType={isSidebarOpen ? 'chat-close' : 'chat'}
                    ariaLabel={isSidebarOpen ? 'Close chat' : 'Open chat'}
                    className={classNames(styles.openButton, {
                        [styles.isOpen]: isSidebarOpen,
                        [styles.shouldFlash]: !buttonHasBeenClickedOnce && location.isFeedOpen,
                    })}
                    buttonStyle="block"
                    onClick={() => {
                        audio?.play(isSidebarOpen ? 'gust-close' : 'gust-open');
                        dispatch(toggleSidebarOpen());
                        sendEvent(isSidebarOpen ? 'chat_close' : 'chat_open');
                        if (!buttonHasBeenClickedOnce) {
                            setButtonHasBeenClickedOnce(true);
                        }
                    }}
                />
                <div className="text">
                    {locationTitle && (
                        <h2>
                            {locationTitle}
                            {locationName && ':'}
                        </h2>
                    )}
                    {locationName && <h1>{locationName}</h1>}
                </div>
                <SidebarEvent />
            </div>
            <div className={styles.bottom} inert={isSidebarOpen ? undefined : ''}>
                <div className={styles.infobar}>&nbsp;</div>
                <div className={styles.feedContainer}>
                    <Feed />
                </div>
            </div>
            <ProfileDrawer />
            <FailedOverlay />
        </div>
    );
}
