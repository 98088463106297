import { Provider as RRProvider } from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import authReducer from '@/state/features/auth';
import appReducer from '@/state/features/app';
import { feedReducer } from '@/state/features/feed';
import profileReducer from '@/state/features/profile';

const store = configureStore({
    reducer: {
        app: appReducer,
        auth: authReducer,
        feed: feedReducer,
        profile: profileReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;

export function Provider({ children }: { children: React.ReactNode }) {
    return <RRProvider store={store}>{children}</RRProvider>;
}
