import { useNavigate } from 'react-router-dom';

import SideMenuIcons from '@/components/SideMenu/SideMenuIcons';
import { useAppDispatch } from '@/state/store';
import { setIsInventoryOpen, setIsProfileOpen, toggleProfile } from '@/state/features/app';
import { toggleInventory } from '@/state/features/app';

import * as styles from './styles.module.scss';
import { mapPath } from '@/theme/vars';

export default function SideMenu() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
        <div className={styles.SideMenu}>
            <SideMenuIcons
                onClickProfile={() => {
                    dispatch(toggleProfile());
                }}
                onClickInventory={() => {
                    dispatch(toggleInventory());
                }}
                onClickWorld={() => {
                    dispatch(setIsInventoryOpen(false));
                    dispatch(setIsProfileOpen(false));
                    navigate(mapPath);
                }}
            />
        </div>
    );
}
