import Pusher, { Channel } from 'pusher-js';

import { getAuthToken } from '@/arise/api';

const PUSHER_KEY = process.env.PUBLIC_PUSHER_KEY;
const PUSHER_CLUSTER = process.env.PUBLIC_PUSHER_CLUSTER;

const PUSHER_USERAUTH_ENDPOINT = process.env.PUBLIC_ARISEENGINE_HOST + '/pusher/userAuth';
const PUSHER_CHANNELAUTH_ENDPOINT = process.env.PUBLIC_ARISEENGINE_HOST + '/pusher/channelAuth';

export class PusherService {
    private pusher: Pusher;
    private static instance: PusherService;

    private subscribedChannels: { [channelName: string]: Channel } = {};

    constructor() {
        this.pusher = new Pusher(PUSHER_KEY, {
            cluster: PUSHER_CLUSTER,
            userAuthentication: {
                endpoint: PUSHER_USERAUTH_ENDPOINT,
                transport: 'ajax',
                params: {},
                headers: {
                    'X-Auth-Token': getAuthToken(),
                },
            },
            channelAuthorization: {
                endpoint: PUSHER_CHANNELAUTH_ENDPOINT,
                transport: 'ajax',
                headers: {
                    'X-Auth-Token': getAuthToken(),
                },
            },
        });
    }

    private static getInstance(): PusherService {
        if (!this.instance) {
            this.instance = new PusherService();
        }
        return this.instance;
    }

    public static subscribe(channelName: string): Channel {
        if (!getAuthToken()) return;
        const instance = this.getInstance();

        if (instance.subscribedChannels[channelName]) {
            return instance.subscribedChannels[channelName];
        }

        const p = instance.pusher;
        instance.subscribedChannels[channelName] = p.subscribe(channelName);
        return instance.subscribedChannels[channelName];
    }

    public static unsubscribe(channelName: string) {
        if (!getAuthToken()) return;
        const instance = this.getInstance();

        if (!instance.subscribedChannels[channelName]) {
            return;
        }

        const p = instance.pusher;
        p.unsubscribe(channelName);
        delete instance.subscribedChannels[channelName];
    }
}
