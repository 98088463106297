interface SideMenuIconsProps {
    onClickProfile: () => void;
    onClickInventory: () => void;
    onClickWorld: () => void;
}

export default function SideMenuIcons(props: SideMenuIconsProps) {
    return (
        <svg viewBox="0 0 125 227" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M88 42.8398C94.6667 46.6888 94.6667 56.3113 88 60.1603L40.75 87.4401C34.0833 91.2891 25.75 86.4778 25.75 78.7798L25.75 24.2202C25.75 16.5222 34.0833 11.7109 40.75 15.5599L88 42.8398Z"
                fill="black"
                fillOpacity="0.27"
                stroke="#D49D76"
                strokeWidth="2"
                onClick={props.onClickProfile}
            />
            <path
                d="M52 52.0162C53.2034 52.0162 54.2265 51.5824 55.0692 50.7149C55.9119 49.8474 56.3333 48.7942 56.3333 47.5553C56.3333 46.3165 55.9119 45.2633 55.0692 44.3957C54.2265 43.5282 53.2034 43.0944 52 43.0944C50.7966 43.0944 49.7735 43.5282 48.9308 44.3957C48.0881 45.2633 47.6667 46.3165 47.6667 47.5553C47.6667 48.7942 48.0881 49.8474 48.9308 50.7149C49.7735 51.5824 50.7966 52.0162 52 52.0162ZM52 64C48.9436 63.136 46.5128 61.3718 44.7077 58.7076C42.9026 56.0434 42 53.058 42 49.7515V41.8538L52 38L62 41.8538V49.7515C62 53.058 61.0974 56.0434 59.2923 58.7076C57.4872 61.3718 55.0564 63.136 52 64ZM52 61.8303C53.2513 61.4132 54.3718 60.7745 55.3615 59.914C56.3513 59.0535 57.2111 58.0663 57.941 56.9523C57.0197 56.4667 56.0594 56.0945 55.0602 55.8358C54.0611 55.5771 53.041 55.4478 52 55.4478C50.959 55.4478 49.9389 55.5771 48.9398 55.8358C47.9406 56.0945 46.9803 56.4667 46.059 56.9523C46.7889 58.0663 47.6487 59.0535 48.6385 59.914C49.6282 60.7745 50.7487 61.4132 52 61.8303Z"
                fill="#F3D6C1"
                onClick={props.onClickProfile}
            />
            <path
                d="M37.4639 122.096C30.9427 118.221 30.9427 108.779 37.4639 104.904L84.3909 77.0135C91.0568 73.0517 99.5 77.8555 99.5 85.6098L99.5 141.39C99.5 149.144 91.0568 153.948 84.3909 149.987L37.4639 122.096Z"
                fill="black"
                fillOpacity="0.27"
                stroke="#D49D76"
                strokeWidth="2"
                onClick={props.onClickInventory}
            />
            <path
                d="M67.5714 124.75C66.8643 124.75 66.2589 124.505 65.7554 124.016C65.2518 123.526 65 122.938 65 122.25V108.5C65 107.812 65.2518 107.224 65.7554 106.734C66.2589 106.245 66.8643 106 67.5714 106H70.1429V102.875C70.1429 102.354 70.3304 101.911 70.7054 101.547C71.0804 101.182 71.5357 101 72.0714 101H75.9286C76.4643 101 76.9196 101.182 77.2946 101.547C77.6696 101.911 77.8571 102.354 77.8571 102.875V106H80.4286C81.1357 106 81.7411 106.245 82.2446 106.734C82.7482 107.224 83 107.812 83 108.5V122.25C83 122.938 82.7482 123.526 82.2446 124.016C81.7411 124.505 81.1357 124.75 80.4286 124.75C80.4286 125.104 80.3054 125.401 80.0589 125.641C79.8125 125.88 79.5071 126 79.1429 126C78.7786 126 78.4732 125.88 78.2268 125.641C77.9804 125.401 77.8571 125.104 77.8571 124.75H70.1429C70.1429 125.104 70.0196 125.401 69.7732 125.641C69.5268 125.88 69.2214 126 68.8571 126C68.4929 126 68.1875 125.88 67.9411 125.641C67.6946 125.401 67.5714 125.104 67.5714 124.75ZM68.8571 121H70.7857V109.75H68.8571V121ZM73.0357 121H74.9643V109.75H73.0357V121ZM77.2143 121H79.1429V109.75H77.2143V121ZM72.0714 106H75.9286V102.875H72.0714V106Z"
                fill="#F3D6C1"
                onClick={props.onClickInventory}
            />
            <path
                d="M88 166.84C94.6667 170.689 94.6667 180.311 88 184.16L40.75 211.44C34.0833 215.289 25.75 210.478 25.75 202.78L25.75 148.22C25.75 140.522 34.0833 135.711 40.75 139.56L88 166.84Z"
                fill="black"
                fillOpacity="0.27"
                stroke="#D49D76"
                strokeWidth="2"
                onClick={props.onClickWorld}
            />
            <path
                d="M51.5 188C49.7708 188 48.1458 187.672 46.625 187.016C45.1042 186.359 43.7812 185.469 42.6562 184.344C41.5312 183.219 40.6406 181.896 39.9844 180.375C39.3281 178.854 39 177.229 39 175.5C39 173.771 39.3281 172.146 39.9844 170.625C40.6406 169.104 41.5312 167.781 42.6562 166.656C43.7812 165.531 45.1042 164.641 46.625 163.984C48.1458 163.328 49.7708 163 51.5 163C53.2292 163 54.8542 163.328 56.375 163.984C57.8958 164.641 59.2188 165.531 60.3438 166.656C61.4688 167.781 62.3594 169.104 63.0156 170.625C63.6719 172.146 64 173.771 64 175.5C64 177.229 63.6719 178.854 63.0156 180.375C62.3594 181.896 61.4688 183.219 60.3438 184.344C59.2188 185.469 57.8958 186.359 56.375 187.016C54.8542 187.672 53.2292 188 51.5 188ZM50.25 185.438V183C49.5625 183 48.974 182.755 48.4844 182.266C47.9948 181.776 47.75 181.188 47.75 180.5V179.25L41.75 173.25C41.6875 173.625 41.6302 174 41.5781 174.375C41.526 174.75 41.5 175.125 41.5 175.5C41.5 178.021 42.3281 180.229 43.9844 182.125C45.6406 184.021 47.7292 185.125 50.25 185.438ZM58.875 182.25C59.2917 181.792 59.6667 181.297 60 180.766C60.3333 180.234 60.6094 179.682 60.8281 179.109C61.0469 178.536 61.2135 177.948 61.3281 177.344C61.4427 176.74 61.5 176.125 61.5 175.5C61.5 173.458 60.9323 171.594 59.7969 169.906C58.6615 168.219 57.1458 167 55.25 166.25V166.75C55.25 167.438 55.0052 168.026 54.5156 168.516C54.026 169.005 53.4375 169.25 52.75 169.25H50.25V171.75C50.25 172.104 50.1302 172.401 49.8906 172.641C49.651 172.88 49.3542 173 49 173H46.5V175.5H54C54.3542 175.5 54.651 175.62 54.8906 175.859C55.1302 176.099 55.25 176.396 55.25 176.75V180.5H56.5C57.0417 180.5 57.5312 180.661 57.9688 180.984C58.4062 181.307 58.7083 181.729 58.875 182.25Z"
                fill="#F3D6C1"
                onClick={props.onClickWorld}
            />
        </svg>
    );
}
