@import '../../style/globals.scss';

.loadingScreen {
    top: calc($header-height * -1);
    height: calc(100% + $header-height);
    padding-top: $header-height;
    opacity: 1;
    transition: opacity 500ms ease-out, height 0ms linear 500ms;
    user-select: none;

    img {
        pointer-events: none;
    }
}

.hidden {
    opacity: 0;
    pointer-events: none;
}

@keyframes rotate-and-back {
    0%,
    100% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(180deg);
        animation-timing-function: ease-in-out;
    }
    50% {
        transform: rotate(180deg);
    }
    60% {
        transform: rotate(0deg);
        animation-timing-function: ease-in-out;
    }
}

.loadingSpinner {
    animation: rotate-and-back 2.2s infinite; /* Total duration: 300ms rotation + 800ms wait + 300ms back rotation + 800ms wait */
}
