import Icon from '../Icon';
import * as styles from './styles.module.scss';

interface LocationIntroductionProps {
    titlePrefix?: string;
    titleText: string;
    text?: string;
    imageUrl?: string;
    hasSoundWarning?: boolean;
    onComplete?: () => void;
}

export default function LocationIntroduction({
    titlePrefix,
    titleText,
    text,
    imageUrl,
    hasSoundWarning,
    onComplete,
}: LocationIntroductionProps) {
    setTimeout(onComplete, 2500);
    return (
        <div className={styles.locationIntroduction}>
            {imageUrl && (
                <div
                    className={styles.locationIntroduction__image}
                    style={{ backgroundImage: `url('${imageUrl}')` }}
                ></div>
            )}
            <span className={styles.locationIntroduction__title}>
                <span className={styles.locationIntroduction__titlePrefix}>
                    {titlePrefix}
                    {titlePrefix ? ': ' : ' '}
                </span>
                <br />
                <span className={styles.locationIntroduction__titleText}>{titleText}</span>
                <br />
                {hasSoundWarning && (
                    <span className={styles.locationIntroduction__soundWarning}>
                        <Icon type="sound" size={24} />
                        Requires sound
                    </span>
                )}
            </span>
            <span className={styles.locationIntroduction__text}>{text}</span>
        </div>
    );
}
