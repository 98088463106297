import { useEffect, useRef } from 'react';
import { Channel } from 'pusher-js';
import { PusherService } from '@/arise/api/pusher/PusherService';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { ChatParticipantUpdatePayload } from '@/models/feed';
import { updateEventParticipant } from '@/state/features/feed';

const pusherEventUpdateEvent = 'client-event-update';

export function useCurrentUserPusherSubscribe() {
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);
    const channelName = `private-user-${currentUserProfile?.userID}`;
    const dispatch = useAppDispatch();
    const channelRef = useRef<Channel>();

    useEffect(() => {
        if (!currentUserProfile?.userID) {
            return;
        }

        function handleEventUpdate(data: ChatParticipantUpdatePayload) {
            dispatch(updateEventParticipant(data));
        }

        channelRef.current = PusherService.subscribe(channelName);
        channelRef.current?.bind(pusherEventUpdateEvent, handleEventUpdate);

        return () => {
            channelRef.current?.unbind(pusherEventUpdateEvent, handleEventUpdate);
            PusherService.unsubscribe(channelName);
        };
    }, [channelName]);

    return {
        channelRef,
    };
}
