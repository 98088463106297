@import '../../style/globals.scss';

.Countdown {
    position: relative;

    &__description {
        color: var(--token-accent-theme-1-text-text-default);
        width: 100%;
        display: block;
        text-align: center;
        text-transform: uppercase;
        font-size: $font-size-h6;
    }

    &__timer {
        display: block;

        span {
            // width: 192px;
            display: inline-block;
            text-align: center;
        }
    }
}
