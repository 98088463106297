import platform from 'platform';

export function getPlatformData() {
    const data = {
        platform: platform.name,
        device: platform.product,
        device_manufacturer: platform.manufacturer,
        device_model: platform.product,
        os_name: platform.os.family,
        os_version: platform.os.version,
    };

    if (!data.device_manufacturer) {
        if (data.os_name === 'OS X') {
            data.device_manufacturer = 'Apple';
        }
    }

    return data;
}
