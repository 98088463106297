import * as arise from '@/arise/api';
import * as insiders from '@/third-party/insiders';

export class AriseBridgeHost extends EventTarget {
    iframeRef: React.MutableRefObject<HTMLIFrameElement | null>;
    allowedOrigins: string[];
    arisewareProjectId: string;

    internalEvents: string[] = [
        'inventory_list_request',
        'inventory_takeItem_request',
        'inventory_dropItem_request',
        'ariseware_state_request',
        'ariseware_state_update_request',
        'profile_setTitle_request',
        'insiders_token_request',
    ];

    constructor(
        iFrameElement: React.MutableRefObject<HTMLIFrameElement | null>,
        allowedOrigins: string[],
        arisewareProjectId: string,
    ) {
        super();
        this.iframeRef = iFrameElement;
        this.allowedOrigins = allowedOrigins || ['*'];
        this.arisewareProjectId = arisewareProjectId;

        this.initialiseEvents();
        console.log('AriseBridgeHost created');
    }

    setArisewareProjectId(id: string) {
        this.arisewareProjectId = id;
    }

    private initialiseEvents() {
        window.addEventListener('message', this.handleReceivedMessage.bind(this));
    }

    private handleReceivedMessage(event: MessageEvent) {
        if (this.allowedOrigins[0] !== '*' && this.allowedOrigins.indexOf(event.origin) === -1) {
            console.error('[Host] received message from unknown origin', event, this.allowedOrigins);
            return;
        }

        const data = event.data;

        if (this.internalEvents.indexOf(data.type) !== -1) {
            this.handleInternalEvent(data);
            return;
        }

        this.dispatchEvent(
            // TODO: proper types
            new CustomEvent<any>(data.type, {
                detail: data.payload,
            }),
        );
    }

    // TODO: proper types
    sendEvent(event: any, payload: any) {
        this.iframeRef.current.contentWindow.postMessage(
            {
                type: event,
                payload,
            },
            '*',
        );
    }

    on(event: string, callback: EventListenerOrEventListenerObject) {
        this.addEventListener(event, callback);
        return () => {
            this.removeEventListener(event, callback);
        };
    }

    off(event: string, callback: EventListenerOrEventListenerObject) {
        this.removeEventListener(event, callback);
    }

    private async handleInternalEvent(data: any) {
        try {
            const result = await this.internalEventSwitch(data);
            let payload = null;
            let event = null;
            if (result) {
                [event, payload] = result;
                this.sendEvent(event, payload);
            }
            this.dispatchEvent(
                new CustomEvent<any>(data.type + '__success', {
                    detail: {
                        requestData: data.payload,
                        responseData: payload,
                    },
                }),
            );
        } catch (error) {
            this.dispatchEvent(
                new CustomEvent<any>(data.type + '__error', {
                    detail: {
                        requestData: data.payload,
                    },
                }),
            );
            console.error(error);
        }
    }

    private async internalEventSwitch(data: any): Promise<void | [string, any]> {
        let res: any;
        switch (data.type) {
            case 'inventory_list_request':
                res = await arise.getInventory();
                return ['inventory_list_response', res];

            case 'inventory_takeItem_request':
                res = await arise.takeItem(data.payload);
                return ['inventory_takeItem_response', res];

            case 'inventory_dropItem_request':
                res = await arise.dropItem(data.payload);
                return ['inventory_dropItem_response', res];

            case 'ariseware_state_request':
                if (!this.arisewareProjectId) {
                    console.error('No arisewareProjectId set');
                    return;
                }
                res = await arise.getArisewareState(this.arisewareProjectId);
                return ['ariseware_state_response', res];

            case 'ariseware_state_update_request':
                if (!this.arisewareProjectId) {
                    console.error('No arisewareProjectId set');
                    return;
                }
                res = await arise.updateArisewareState(this.arisewareProjectId, { state: data.payload });
                return ['ariseware_state_update_response', res];

            case 'profile_setTitle_request':
                const titleId = data?.payload?.id;
                res = await arise.editProfileTitle(titleId);
                return ['profile_setTitle_response', res];

            case 'insiders_token_request':
                res = insiders.getToken();
                return ['insiders_token_response', res];

            default:
                console.error('Unknown internal event', data);
                return;
        }
    }
}
