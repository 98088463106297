import React = require('react');

import { AriseBridgeHost } from '@/arise/bridge/AriseBridgeHost';

export class AriseBridgeService extends EventTarget {
    private static instance: AriseBridgeService;
    private bridge: AriseBridgeHost;

    private static eventListeners: {
        event: string;
        callback: (event: Event) => void;
    }[] = [];

    private constructor(
        ref: React.MutableRefObject<HTMLIFrameElement | null>,
        allowedOrigins: string[],
        setArisewareProjectId: string,
    ) {
        super();
        this.bridge = new AriseBridgeHost(ref, allowedOrigins, setArisewareProjectId);
    }

    public static initialise(
        ref: React.MutableRefObject<HTMLIFrameElement | null>,
        allowedOrigins: string[],
        setArisewareProjectId: string,
    ) {
        if (!AriseBridgeService.instance) {
            AriseBridgeService.instance = new AriseBridgeService(ref, allowedOrigins, setArisewareProjectId);
            this.applyEventListeners();
        } else {
            AriseBridgeService.instance.bridge.iframeRef = ref;
            AriseBridgeService.instance.bridge.setArisewareProjectId(setArisewareProjectId);
        }
        return AriseBridgeService.instance;
    }

    public static getInstance() {
        return AriseBridgeService.instance;
    }

    public getBridge() {
        return this.bridge;
    }

    public static sendEvent(event: string, payload: any) {
        if (AriseBridgeService.instance) {
            AriseBridgeService.instance.bridge.sendEvent(event, payload);
        } else {
            console.error('AriseBridgeService not initialised. Is there an iframe?');
        }
    }

    public static on(event: string, callback: (event: Event) => void) {
        this.eventListeners.push({ event, callback });

        if (AriseBridgeService.instance) {
            AriseBridgeService.instance.bridge.on(event, callback);
        }

        // Return a function to remove the event listener
        return () => {
            const index = this.eventListeners.findIndex(
                (listener) => listener.event === event && listener.callback === callback,
            );
            if (index > -1) {
                this.eventListeners.splice(index, 1);
            }
            if (AriseBridgeService.instance) {
                AriseBridgeService.instance.bridge.removeEventListener(event, callback);
            }
        };
    }

    private static applyEventListeners() {
        if (AriseBridgeService.instance) {
            this.eventListeners.forEach((listener) => {
                AriseBridgeService.instance.bridge.on(listener.event, listener.callback);
            });
        }
    }
}
