import { useMemo } from 'react';
import { ChatMessage } from '@/models/feed';

import * as styles from './styles.module.scss';

interface MessageRendererProps {
    message: ChatMessage;
}

export default function MessageRenderer({ message }: MessageRendererProps) {
    const m = useMemo(() => {
        const regex = /(<<user:[0-9a-fA-F-]{36}>>)/g;
        const parts = message.message.split(regex);

        return parts.map((part, index) => {
            if (part.match(regex)) {
                const userId = part.match(/[0-9a-fA-F-]{36}/)?.[0];
                const profile = message.mentions?.find((profile) => profile.userID === userId);
                if (profile) {
                    return (
                        <span key={index} className={styles.mention}>
                            @{profile.displayName}
                        </span>
                    );
                } else {
                    console.warn('Could not find matching profile for mention:', part);
                    return part;
                }
            }
            return part;
        });
    }, [message]);
    return <>{m}</>;
}
