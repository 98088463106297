@import '../../../style/globals.scss';

.contentWrapper {
    position: absolute;
    top: $header-height;
    left: 0;
    bottom: 0;
    right: 0;
    transition: $sidebar-transition;

    &--sidebarOpen {
        right: $sidebar-width;
    }

    @media (max-width: $bp-mobile-chat) {
        right: 0;
    }
}

.sidebarWrapper {
    z-index: 30;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: $sidebar-width;
    right: -$sidebar-width;
    transition: $sidebar-transition;

    @media (max-width: $bp-mobile-chat) {
        width: 100%;
        right: -100%;
    }

    &--sidebarOpen {
        right: 0;
    }
}
