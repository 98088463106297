// Decode a JWT token.
// Note: This does not verify the token.
export function decodeJWT(token: string) {
    // Split the token into parts
    const parts = token.split('.');
    if (parts.length !== 3) {
        throw new Error('Invalid JWT: The token should consist of three parts.');
    }

    // Decode the header and payload
    const header = JSON.parse(window.atob(parts[0].replace(/-/g, '+').replace(/_/g, '/')));
    const payload = JSON.parse(window.atob(parts[1].replace(/-/g, '+').replace(/_/g, '/')));

    return { header, payload };
}

let checkTokenInterval: any | number | null = null;

export function manageTokenExpiry() {
    const insidersToken = localStorage.getItem('ARISE_INSIDERS_TOKEN');
    const ariseToken = localStorage.getItem('ARISEENGINE_AUTHTOKEN');

    if (!insidersToken && !ariseToken) {
        // If there are no tokens to check, return
        return;
    }

    let expiry: Date | null = null;

    if (insidersToken) {
        expiry = getTokenExpiry(insidersToken);
    }

    if (ariseToken) {
        const ariseTokenExpiry = getTokenExpiry(ariseToken);
        if (!expiry || ariseTokenExpiry < expiry) {
            expiry = ariseTokenExpiry;
        }
    }

    const hasExpired = () => {
        if (new Date() > expiry) {
            localStorage.removeItem('ARISE_INSIDERS_TOKEN');
            localStorage.removeItem('ARISEENGINE_AUTHTOKEN');

            console.log('Token has expired, refreshing page.');

            // TODO: add a toast or modal to notify the user that they have been logged out
            window.location.reload();
        }
    };
    hasExpired();
    clearInterval(checkTokenInterval);
    checkTokenInterval = setInterval(hasExpired, 60 * 1000);
}

function getTokenExpiry(token: string) {
    const { payload } = decodeJWT(token);
    return new Date(payload.exp * 1000);
}
