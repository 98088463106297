import classNames from 'classnames';

import { Update as IUpdate } from '@/models/feed';
import { useTheme } from '@/theme';
import ThemeIcon from '@/components/ThemeIcon';
import Reactions from '@/components/Feed/Reactions';
import DisplayName from '@/components/DisplayName';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { toggleMessageReaction } from '@/state/features/feed';
import { sendEvent } from '@/utils/analytics';

import * as styles from './styles.module.scss';

export default function Update({ subjectId, action, type, icon, profile, reactions, id }: IUpdate) {
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);
    const locationId = useAppSelector((state) => state.app.location.id);

    const dispatch = useAppDispatch();
    const color = useUpdateTypeColor(type);

    const actionDesc = profile ? `${profile.displayName} ${action}` : '';

    function renderLabel() {
        if (!subjectId) {
            return <span className={styles.action}>{action}</span>;
        }

        if (profile) {
            return (
                <>
                    <DisplayName profile={profile} shouldLinkToProfile classname={styles.displayName} />
                    <span className={styles.action}> {action}</span>
                </>
            );
        }
        return <span>&nbsp;</span>;
    }

    function handleReactionSubmit(reaction: string) {
        dispatch(toggleMessageReaction([id, reaction, locationId]));
        sendEvent('toggle_reaction', { reaction });
    }

    return (
        <div className={styles.Update}>
            <div className={classNames(styles.wrapper, styles[type])} title={actionDesc}>
                <div className={styles.icon}>
                    <ThemeIcon icon={icon} color={color} />
                </div>
                <div className={styles.label}>{renderLabel()}</div>
            </div>
            <Reactions
                reactions={reactions}
                onSubmitReaction={handleReactionSubmit}
                controlsClassName={styles.reactionsMenu}
                existingReactionsClassName={styles.existingReactions}
                disabled={currentUserProfile?.isBanned}
                currentUserId={currentUserProfile?.userID}
            />
        </div>
    );
}

function useUpdateTypeColor(type: string) {
    const theme = useTheme();

    switch (type) {
        case 'success':
            return theme.tokens.AriseTheme1StateStateSuccessLight;
        case 'danger':
            return theme.tokens.AriseTheme1StateStateErrorLight;
        case 'default':
            return theme.tokens.AccentTheme1InterfaceInterfaceWhite;
        default:
            return theme.tokens.AriseTheme1StateStateSuccessLight;
    }
}
