import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import AuthLayout from '@/app/auth/layout';
import LoginPage from '@/app/auth/login/page';
import RegisterPage from '@/app/auth/register/page';
import EditProfilePage from '@/app/edit-profile/page';
import EditProfileLayout from '@/app/edit-profile/layout';
import RootLayout from '@/app/layout';
import LocationPage from '@/app/location/[id]/page';
import LocationLayout from '@/app/location/layout';
import MapLayout from '@/app/map/layout';
import SceneSelectorLayout from '@/app/selector/layout';
import SceneSelectorPage from '@/app/selector/page';
import HomePage from '@/app/page';
import InsidersLogin from '@/app/auth/insiders/page';
import InsidersExternalRedirect from './app/auth/insiders/externalRedirect';
import { initSentry } from './utils/analytics/sentry';
import MapBasicPage from '@/app/map/basic';
import MapGate from '@/components/MapGate';

const getAuthRoutesTitan = () => {
    // Previously used <InsidersIntro />
    const authRoutes = [
        {
            path: 'login',
            element: (
                <RootLayout>
                    <AuthLayout>
                        <InsidersExternalRedirect />
                    </AuthLayout>
                </RootLayout>
            ),
        },
        {
            path: 'insiders',
            element: (
                <RootLayout>
                    <InsidersLogin />
                </RootLayout>
            ),
        },
    ];
    if (process.env.PUBLIC_ENV !== 'prod') {
        const ariseAuthRoutes = {
            path: 'arise',
            element: null,
            children: [
                {
                    path: 'login',
                    element: (
                        <RootLayout>
                            <AuthLayout>
                                <LoginPage />
                            </AuthLayout>
                        </RootLayout>
                    ),
                },
                {
                    path: 'register',
                    element: (
                        <RootLayout>
                            <AuthLayout>
                                <RegisterPage />
                            </AuthLayout>
                        </RootLayout>
                    ),
                },
            ],
        };
        authRoutes.push(ariseAuthRoutes);
    }
    return authRoutes;
};

const getAuthRoutesDefault = () => {
    return [
        {
            path: 'login',
            element: (
                <RootLayout>
                    <AuthLayout>
                        <LoginPage />
                    </AuthLayout>
                </RootLayout>
            ),
        },
        {
            path: 'register',
            element: (
                <RootLayout>
                    <AuthLayout>
                        <RegisterPage />
                    </AuthLayout>
                </RootLayout>
            ),
        },
    ];
};

const getAuthRoutes = () => {
    if (process.env.PUBLIC_INSTANCE === 'titan') {
        return getAuthRoutesTitan();
    }
    return getAuthRoutesDefault();
};

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <RootLayout>
                <HomePage />
            </RootLayout>
        ),
    },
    {
        path: '/auth',
        children: getAuthRoutes(),
    },
    {
        path: '/edit-profile',
        element: (
            <RootLayout>
                <EditProfileLayout>
                    <EditProfilePage />
                </EditProfileLayout>
            </RootLayout>
        ),
    },
    {
        path: '/map',
        element: (
            <RootLayout>
                <MapLayout>
                    <MapGate targetPath="/map">
                        <MapBasicPage />
                    </MapGate>
                </MapLayout>
            </RootLayout>
        ),
    },
    {
        path: '/selector',
        element: (
            <RootLayout>
                <SceneSelectorLayout>
                    <MapGate targetPath="/selector">
                        <SceneSelectorPage />
                    </MapGate>
                </SceneSelectorLayout>
            </RootLayout>
        ),
    },
    {
        path: '/location/:locationId',
        element: (
            <RootLayout>
                <LocationLayout>
                    <LocationPage />
                </LocationLayout>
            </RootLayout>
        ),
    },
    {
        path: '*',
        element: <Navigate to="/" />, // 404
    },
]);

initSentry();

const root = document.createElement('div');
document.body.appendChild(root);

ReactDOM.createRoot(root).render(<RouterProvider router={router} />);
