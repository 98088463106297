import * as styles from './styles.module.scss';
import classNames from 'classnames';
import { msTimeDifferenceFromNow } from '@/utils/time';
import Icon from '@/components/Icon';
import IconButton from '@/components/IconButton';
// TODO - timer?
// TODO - rollover for locked state?

interface SceneSelectorTileProps {
    onClick: () => void;
    imageUrl?: string;
    lockedImageUrl?: string;
    imageMaskUrl?: string;
    description?: string;
    lockedText?: string;
    lockedSubtext?: string;
    tabbable?: boolean;
    isInCenter?: boolean;
    isOpen?: boolean;
}

export default function SceneSelectorTile({
    onClick,
    imageUrl,
    imageMaskUrl,
    lockedImageUrl,
    description,
    lockedText,
    lockedSubtext,
    tabbable = true,
    isInCenter = true,
    isOpen,
}: SceneSelectorTileProps) {
    function handleKeyDown(e: React.KeyboardEvent<HTMLAnchorElement>) {
        if (e.key === 'Enter' && onClick) onClick();
    }

    const renderLockedText = () => (
        <div className={styles.SceneSelectorTile__textContainer}>
            <p>{lockedText}</p>
            <p className={styles.SceneSelectorTile__lockedSubtext}>{lockedSubtext}</p>
        </div>
    );

    const renderUnlockedText = () => (
        <div className={styles.SceneSelectorTile__textContainer}>
            <p>{description}</p>
        </div>
    );

    return (
        <div
            className={classNames(styles.SceneSelectorTile, {
                [styles['SceneSelectorTile--locked']]: !isOpen,
                [styles['SceneSelectorTile--inCenter']]: isInCenter,
            })}
        >
            <a
                className={styles.SceneSelectorTile__anchor}
                onClick={onClick}
                tabIndex={tabbable ? 0 : -1}
                onKeyDown={handleKeyDown}
            >
                <div
                    className={styles.SceneSelectorTile__imageContainer}
                    style={imageMaskUrl ? { maskImage: `url(${imageMaskUrl})` } : {}}
                >
                    <div
                        className={styles.SceneSelectorTile__image}
                        style={{ backgroundImage: isOpen ? `url(${imageUrl})` : `url(${lockedImageUrl})` }}
                    ></div>
                </div>
                <svg
                    className={styles.SceneSelectorTile__customBorder}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 476 234"
                    preserveAspectRatio="none"
                >
                    <path
                        fill="none"
                        strokeWidth="3"
                        d="m36.97773,2.5l-35.6444,0l0,219.564l4.1447,10.436l104.0323,-2.922l26.111,2.922l95.328,0l41.033,0l58.44,-2.922l87.868,0l54.71,0l-2.487,-64.283l0,-105.6081l2.487,-57.1869l-15.336,0l-31.085,2.50454l-63.414,0l-75.433,2.50453l-82.48,-5.00907l-38.131,0l-23.625,2.50454l-106.5186,-2.50454z"
                    />
                </svg>
                {isOpen ? null : (
                    <div className={styles.SceneSelectorTile__lockedIcon}>
                        <Icon type="locked" size={72} />
                    </div>
                )}
                <IconButton
                    className={styles.SceneSelectorTile__playButton}
                    iconType={isOpen ? 'play' : 'padlock'}
                    ariaLabel=""
                    buttonStyle="diamond"
                    disabled={!isOpen}
                    aria-hidden={true}
                    tabIndex={-1}
                />
            </a>
            {isOpen ? renderUnlockedText() : renderLockedText()}
        </div>
    );
}
