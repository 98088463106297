import { useEffect, useRef } from 'react';
import classNames from 'classnames';

import { AuthDrawer, authDrawerWidth } from '@/components/AuthDrawer';
import DisplayNameWithBadges from '@/components/DisplayNameWithBadges';
import Button from '@/components/Forms/Button';
import { useTheme } from '@/theme';
import { logout } from '@/state/features/auth';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { useAudio } from '@/hooks/audio';

import { setShowingSettingsMenu } from '@/state/features/app';

import * as styles from './styles.module.scss';

interface SettingsProps {
    visible: boolean;
    displayNameShouldLinkToProfile?: boolean;
}

export default function Settings({ visible, displayNameShouldLinkToProfile }: SettingsProps) {
    const theme = useTheme();
    const audio = useAudio();
    const settingsContentRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);

    // Close the setting menu when the user presses the escape key
    useEffect(() => {
        function keyUpHandler(e: KeyboardEvent) {
            if (e.key === 'Escape') {
                dispatch(setShowingSettingsMenu(false));
            }
        }

        if (visible) {
            window.addEventListener('keyup', keyUpHandler);
            settingsContentRef.current.focus();
        } else {
            window.removeEventListener('keyup', keyUpHandler);
        }

        return () => {
            window.removeEventListener('keyup', keyUpHandler);
        };
    }, [visible]);

    // Hide the settings menu when the user clicks outside of it
    function clickHandler(e: any) {
        if (settingsContentRef.current && !settingsContentRef.current.contains(e.target as Node)) {
            dispatch(setShowingSettingsMenu(false));
        }
    }

    return (
        <>
            <div
                className={classNames(styles.backdropBlur, {
                    [styles.visible]: visible,
                })}
            ></div>
            <div
                className={classNames(styles.Settings, {
                    [styles.visible]: visible,
                })}
                onClick={clickHandler}
                aria-hidden={!visible}
                inert={visible ? undefined : ''}
            >
                <div className="absolute top-0 left-0 w-full h-full"></div>
                <AuthDrawer
                    imagePath={theme.customJSON.loginDrawerImage}
                    imageColour={theme.customJSON.loginDrawerColour}
                    animationDurationMs={0}
                    width={authDrawerWidth.mediumContent}
                    style={{
                        minWidth: 520,
                    }}
                ></AuthDrawer>
                <div className={styles.header}>
                    <img src={theme.customJSON.secondaryLogo} alt="" className={styles.secondaryLogo} />
                </div>
                <div className={styles.content} ref={settingsContentRef} tabIndex={visible ? 0 : -1}>
                    <div className={styles.top}>
                        <DisplayNameWithBadges
                            profile={currentUserProfile}
                            shouldLinkToProfile={displayNameShouldLinkToProfile}
                        />
                    </div>
                    <div className={styles.middle}>
                        <p>{theme.customJSON.settingsParagraph1}</p>
                        <br></br>
                        <p>{theme.customJSON.settingsParagraph2}</p>
                        <br></br>
                        <p>
                            For further information or if you wish to leave{' '}
                            <a href={theme.customJSON.feedbackUrl} target="_blank" rel="noopener noreferrer">
                                feedback
                            </a>{' '}
                            visit our{' '}
                            <a href={theme.customJSON.faqUrl} target="_blank" rel="noopener noreferrer">
                                FAQ
                            </a>
                            .
                        </p>
                    </div>
                    <div
                        className={styles.bottom}
                        style={{ backgroundImage: `url(${theme.customJSON.settingsGraphicUrl})` }}
                    >
                        <Button
                            className={styles.logoutButton}
                            disabled={!visible}
                            onClick={() => {
                                dispatch(logout());
                            }}
                        >
                            Log Out
                        </Button>
                        <img
                            src="/assets/global/those-beyond-logo.png"
                            alt="Those Beyond logo"
                            className={styles.bottomLogo}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
