export const getCursorPosition = (element: Element) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    return preCaretRange.toString().length;
};

export const setCursorPosition = (element: Element, cursorPosition: number) => {
    const selection = window.getSelection();
    const range = document.createRange();
    let charCount = 0;
    const nodeStack = [element];
    let node,
        foundStart = false;

    while (nodeStack.length && !foundStart) {
        node = nodeStack.pop();

        if (node.nodeType === 3) {
            const nextCharCount = charCount + node.textContent.length;
            if (!foundStart && cursorPosition <= nextCharCount) {
                range.setStart(node, cursorPosition - charCount);
                foundStart = true;
            }
            charCount = nextCharCount;
        } else {
            let i = node.childNodes.length;
            while (i--) {
                nodeStack.push(node.childNodes[i]);
            }
        }
    }

    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
};

export const moveCursorAfterElement = (element: Element) => {
    if (!element) return;

    const selection = window.getSelection();
    if (!selection.rangeCount) return;
    const range = selection.getRangeAt(0);

    const nextNode = element.nextSibling;

    // Create a text node containing a space
    const spaceNode = document.createTextNode('\u00A0');

    if (nextNode) {
        // If there's a next sibling node, insert the space node after the element
        element.parentNode.insertBefore(spaceNode, nextNode);
    } else {
        // If there's no next sibling node, append the space node to the parent node
        element.parentNode.appendChild(spaceNode);
    }

    // Move the cursor to the space node
    range.setStart(spaceNode, 1);
    range.collapse(true);

    // Update the selection range
    selection.removeAllRanges();
    selection.addRange(range);
};
