import React, { Dispatch } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { FeedItem } from '@/models/feed';
import FeedItemRenderer from '@/components/Feed/FeedItemRenderer';
import InitialHeightMeasurer from '@/components/Feed/InitialHeightMeasurer';
import { addItemsToTop } from '@/state/features/feed';
import { isTouchDevice } from '@/utils/mobile';
import { FEED_MESSAGES_TO_LOAD } from '@/globals';

import * as arise from '@/arise/api';

import * as styles from './styles.module.scss';

// Map dispatch to props type
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
    dispatch,
});

// Create a connector
const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface FeedScrollerProps extends PropsFromRedux {
    locationId: string;
    items: FeedItem[];
    feedUpdateTicker: number;
    shouldAutoScrollRef: React.MutableRefObject<boolean>;
    scrollToBottomRef: React.MutableRefObject<() => void>;
    onScrollBack: () => void;
    onScrollToBottom: () => void;
}

interface FeedScrollerState {
    itemsToRender: FeedItem[];
    itemsToMeausure: FeedItem[];
    isLoadingMore: boolean;
    autoLoadEnabled: boolean;
    hasMoreToLoad: boolean;
}

interface ElementSize {
    width: number;
    height: number;
    type: string;
    hasReactions: boolean;
}

/**
 * FeedScroller
 *
 * Handling the feed items in a virtualized way. This component will only render the items that are visible in the viewport.
 * At some point it becomes easier to simply use class component rather than work around a functional component.
 *
 */

class FeedScroller extends React.Component<FeedScrollerProps, FeedScrollerState> {
    viewportElemRef: React.RefObject<HTMLDivElement> = React.createRef();

    amountVisible: number = 250;
    bufferSize: number = 15;
    elementSizes: Record<string, ElementSize> = {};

    startingIndexFromBottom: number = -1;
    topIndex: number = 0;
    itemsToMeausure = 0;
    itemsMeasued = 0;

    autoScroll = true;
    lastScrollPosition = 0;
    heightToAdd = 0;

    currentAction: null | 'addingToTop' | 'addingToBottom' = null;
    removingFromTopCount = 0;
    isLoadingMore = false;
    newItemsAddedToTop = 0;
    heightAdded = 0;

    distanceLeftBeforeLoadMore = 750;
    spacingBetweenItems = 10;

    debug = false;

    constructor(props: FeedScrollerProps) {
        super(props);
        this.state = {
            itemsToRender: [],
            itemsToMeausure: [],
            isLoadingMore: false,
            hasMoreToLoad: false,

            // Disable auto load for touch devices. This is due to scroll events being triggered differently on iOS devices causing unpredictable behavior.
            // Touch device users simply have to click the "Load More" button to load more items. This only affects users that want to see a significant amount of chat history.
            autoLoadEnabled: !isTouchDevice(),
        };
    }

    componentDidMount(): void {
        this.prepareItemsToRender();
        this.props.scrollToBottomRef.current = this.scrollToBottomHandler;
    }

    componentDidUpdate(prevProps: Readonly<FeedScrollerProps>, prevState: Readonly<FeedScrollerState>): void {
        if (
            this.newItemsAddedToTop > 0 &&
            prevProps.items.length + this.newItemsAddedToTop === this.props.items.length
        ) {
            this.log('case 1');
            // If new items are added to the top, we need to adjust the starting index from the bottom.
            // We basically need to move the starting index from the bottom up by the number of new items added so there should be no change.
            this.topIndex += this.newItemsAddedToTop;
            this.startingIndexFromBottom += this.newItemsAddedToTop;
            this.newItemsAddedToTop = 0;
            this.prepareItemsToRender();
            setTimeout(() => {
                this.addToTop(20);
            }, 100);
            return;
        }

        // If less than FEED_MESSAGES_TO_LOAD items are loaded, that means that there are no more to load.
        // If FEED_MESSAGES_TO_LOAD items are loaded, we can assume that there are more items to load.
        // Currently there's no way to but since we load 500 at a time we can assume this is the max.
        if (prevProps.items.length === 0 && this.props.items.length === FEED_MESSAGES_TO_LOAD) {
            this.log('case 2');
            this.setState({
                hasMoreToLoad: true,
            });
        }

        // If there are items, and we haven't set the starting index from the bottom, do so.
        // Once set, the starting index from the bottom will never go to 0, so it's safe to assume this only happens once when the component mounts, and the chat messages have been loaded.
        // OR
        // If new items are added, we also need to scroll to the bottom.
        if (
            (this.props.items.length > 0 && this.startingIndexFromBottom === -1) ||
            (prevProps.items.length < this.props.items.length && this.props.shouldAutoScrollRef.current)
        ) {
            this.log('case 3');
            this.setInitialIndexes();
            this.prepareItemsToRender();
            return;
        }

        if (prevProps.items !== this.props.items || prevProps.feedUpdateTicker !== this.props.feedUpdateTicker) {
            this.log('case 4');
            this.prepareItemsToRender();
        }

        if (prevProps.feedUpdateTicker !== this.props.feedUpdateTicker && this.props.shouldAutoScrollRef.current) {
            this.log('case 5');
            this.scrollToBottom();
        }

        if (prevState.itemsToRender !== this.state.itemsToRender) {
            this.log('case 6');
            if (this.props.shouldAutoScrollRef.current) {
                this.scrollToBottom();
            } else if (
                prevState.itemsToRender[1] &&
                this.state.itemsToRender[0] &&
                prevState.itemsToRender[1].id === this.state.itemsToRender[0].id
            ) {
                // When one item is added at the bottom, one is removed from the top. If this is the case, we need to adjust the scroll position to keep the same item in view.

                const heightOfRemovedElement = this.elementSizes[prevState.itemsToRender[0].id].height;
                this.viewportElemRef.current.scrollTop =
                    this.viewportElemRef.current.scrollTop - heightOfRemovedElement;
            } else if (this.currentAction === 'addingToBottom' && this.removingFromTopCount > 0) {
                let totalHeightOfRemovedElements = 0;
                for (let i = 0; i < this.removingFromTopCount; i++) {
                    if (prevState.itemsToRender[i]) {
                        const key = prevState.itemsToRender[i].id;
                        totalHeightOfRemovedElements += this.elementSizes[key].height;
                    }
                }
                this.viewportElemRef.current.scrollTop =
                    this.viewportElemRef.current.scrollTop - totalHeightOfRemovedElements;

                this.removingFromTopCount = 0;
                this.currentAction = null;
            }

            if (this.currentAction === 'addingToTop') {
                this.viewportElemRef.current.scrollTop = this.viewportElemRef.current.scrollTop + this.heightToAdd;
                this.heightAdded = this.heightToAdd;
                this.currentAction = null;

                // Manually trigger scroll handle for safari.
                this.handleScroll();
                var forceRepaint = this.viewportElemRef.current.offsetHeight;
            }
        }
    }

    setInitialIndexes = () => {
        this.startingIndexFromBottom = this.props.items.length - 1;
        this.topIndex = Math.max(0, this.startingIndexFromBottom - this.amountVisible - this.bufferSize);
    };

    // Figure out which items need to be rendered, and which need to be measured.
    prepareItemsToRender = () => {
        const { items } = this.props;

        const selection = items.slice(this.topIndex, this.startingIndexFromBottom + 1);

        let tempItemMeasureList = [];
        for (let i = 0; i < selection.length; i++) {
            const item = selection[i];
            const key = item.id;

            if (
                !this.elementSizes[key] ||
                (this.elementSizes[key] && this.elementSizes[key].hasReactions !== hasReactions(item))
                // TODO: figure out if content has loaded (for event overview update)
            ) {
                tempItemMeasureList.push(item);
            }
        }

        if (tempItemMeasureList.length > 0) {
            this.measureItems(tempItemMeasureList);
        } else {
            this.setState({
                itemsToRender: selection,
                itemsToMeausure: [],
            });
        }
    };

    measureItems = (items: FeedItem[]) => {
        this.itemsToMeausure = items.length;
        this.itemsMeasued = 0;

        this.setState({
            itemsToMeausure: items,
        });
    };

    setElementSize = (id: string, size: ElementSize) => {
        this.elementSizes[id] = size;
        this.itemsMeasued++;
        this.heightToAdd += size.height + this.spacingBetweenItems;
        if (this.itemsMeasued === this.itemsToMeausure) {
            this.onItemsMeasured();
        }
    };

    onItemsMeasured = () => {
        this.prepareItemsToRender();
    };

    handleScroll = () => {
        if (this.viewportElemRef.current) {
            const MIN_SCROLL = 50;
            const newPos = this.viewportElemRef.current.scrollTop;

            if (newPos < this.distanceLeftBeforeLoadMore && this.heightAdded > 0) {
                this.log(
                    'we added height but safari has not yet caught on, so ignore for now',
                    newPos,
                    this.heightAdded,
                );
                return;
            } else {
                this.heightAdded = 0;
            }

            this.log(
                'Scrolling',
                newPos,
                this.heightAdded,
                'scrollHeight - clientHeight - distanceLeftBeforeLoadMore',
                this.viewportElemRef.current.scrollHeight -
                    this.viewportElemRef.current.clientHeight -
                    this.distanceLeftBeforeLoadMore,
                'topIndex',
                this.topIndex,
                'startingIndexFromBottom',
                this.startingIndexFromBottom,
                'items.length',
                this.props.items.length,
                'currentAction',
                this.currentAction,
            );

            const thresHold =
                this.viewportElemRef.current.scrollHeight - this.viewportElemRef.current.clientHeight - MIN_SCROLL;

            if (newPos < thresHold && this.lastScrollPosition > thresHold) {
                // Did the user UP scroll past the threshold?
                this.log('Scrolled Back');
                this.props.onScrollBack();
            } else if (
                newPos > thresHold &&
                this.lastScrollPosition < thresHold &&
                this.startingIndexFromBottom === this.props.items.length - 1
            ) {
                // Or did the user DOWN scroll past the threshold?
                this.log('Scrolled to Bottom');
                this.props.onScrollToBottom();
            }

            // Passed the {this.distanceLeftBeforeLoadMore} top threshold
            if (
                this.state.autoLoadEnabled &&
                newPos < this.distanceLeftBeforeLoadMore &&
                !this.isLoadingMore &&
                this.heightAdded == 0
            ) {
                if (this.topIndex === 0) {
                    this.log('Load more items');
                    this.loadMore();
                } else if (this.currentAction !== 'addingToTop') {
                    this.log('add to top');
                    this.addToTop(50);
                }
            }

            // If at bottom
            if (
                newPos >
                    this.viewportElemRef.current.scrollHeight -
                        this.viewportElemRef.current.clientHeight -
                        this.distanceLeftBeforeLoadMore &&
                newPos > this.distanceLeftBeforeLoadMore
            ) {
                if (this.currentAction !== 'addingToBottom') {
                    this.addToBottom(50);
                }
            }

            this.lastScrollPosition = newPos;
        }
    };

    addToTop(amount: number) {
        this.startingIndexFromBottom = Math.max(
            this.amountVisible + this.bufferSize,
            this.startingIndexFromBottom - amount,
        );
        this.topIndex = Math.max(0, this.startingIndexFromBottom - this.amountVisible - this.bufferSize);
        this.currentAction = 'addingToTop';
        this.heightToAdd = 0;
        this.prepareItemsToRender();
    }

    addToBottom(amount: number) {
        const newBottomIndex = Math.min(
            this.props.items.length - 1,
            Math.max(this.amountVisible, this.startingIndexFromBottom + amount + this.bufferSize),
        );

        if (newBottomIndex === this.startingIndexFromBottom) {
            // Already at bottom
            return;
        }
        this.log('adding to bottom');

        this.removingFromTopCount += amount;

        this.startingIndexFromBottom = newBottomIndex;
        this.topIndex = Math.max(0, this.startingIndexFromBottom - this.amountVisible - this.bufferSize);
        this.currentAction = 'addingToBottom';
        this.heightToAdd = 0;
        this.prepareItemsToRender();
    }

    scrollToBottomHandler = () => {
        // If the virtual list no longer has the latest item at the bottom, we reset the indexes and prepare the items to render.
        if (this.startingIndexFromBottom != this.props.items.length - 1) {
            this.setInitialIndexes();
            this.prepareItemsToRender();
        } else {
            this.scrollToBottom();
        }
    };

    scrollToBottom = () => {
        if (this.viewportElemRef.current && this.props.shouldAutoScrollRef.current) {
            this.viewportElemRef.current.scrollTop = this.viewportElemRef.current.scrollHeight;
        }
    };

    loadMore = async () => {
        if (this.isLoadingMore) return;
        this.isLoadingMore = true;
        this.setState({
            isLoadingMore: true,
        });

        // Simulate loading more items
        const firstItem = this.props.items[0];
        if (!firstItem) return;

        const newItems = await this.getMoreItems(FEED_MESSAGES_TO_LOAD);

        this.log('Loaded more items', newItems.length);
        this.newItemsAddedToTop = newItems.length;
        this.props.dispatch(addItemsToTop(newItems));
        this.log(newItems.length === FEED_MESSAGES_TO_LOAD, newItems.length, FEED_MESSAGES_TO_LOAD);
        this.setState({
            isLoadingMore: false,

            // If less than FEED_MESSAGES_TO_LOAD items are loaded, that means that there are no more to load.
            hasMoreToLoad: newItems.length === FEED_MESSAGES_TO_LOAD,
        });
        this.isLoadingMore = false;
    };

    handleAddMoreButton = () => {
        if (this.topIndex === 0) {
            this.loadMore();
        } else if (this.currentAction !== 'addingToTop') {
            this.addToTop(50);
        } else {
        }
    };

    getMoreItems = async (amount: number) => {
        const firstMessage = this.props.items[0];
        if (!firstMessage) return [];
        parseInt(firstMessage.timestamp);
        const newMessages = await arise.lastChats(this.props.locationId, parseInt(firstMessage.timestamp), amount);
        return newMessages;
    };

    render() {
        const offsetTop = 100;
        const paddingBottom = 10;
        const { itemsToRender, itemsToMeausure } = this.state;
        let totalHeight = offsetTop;
        const renderItems = [];
        for (let i = 0; i < itemsToRender.length; i++) {
            const item = itemsToRender[i];
            const key = item.id;

            const size = this.elementSizes[key];

            renderItems.push(
                <div key={key} className={styles.itemHolder} style={{ top: totalHeight }}>
                    <FeedItemRenderer item={item} />
                </div>,
            );
            if (size) {
                totalHeight += size.height + this.spacingBetweenItems;
            }
        }
        totalHeight += paddingBottom;

        return (
            <div className={styles.FeedVirtual} ref={this.viewportElemRef} onScroll={this.handleScroll}>
                <div>
                    {itemsToMeausure.map((item, i) => (
                        <InitialHeightMeasurer
                            key={item.id}
                            visible={false}
                            onSizeUpdate={(size) => {
                                if (size.height > 0) {
                                    this.setElementSize(item.id, {
                                        ...size,
                                        type: item.type,
                                        hasReactions: hasReactions(item),
                                    });
                                }
                            }}
                        >
                            <div>
                                <FeedItemRenderer item={item} />
                            </div>
                        </InitialHeightMeasurer>
                    ))}
                </div>
                <div
                    className={styles.slider}
                    style={{
                        height: totalHeight,
                    }}
                >
                    <div
                        className={styles.firstItem}
                        style={{
                            width: '100%',
                            top: 0,
                            height: offsetTop,
                            // backgroundColor: 'red',
                            position: 'absolute',
                        }}
                    >
                        <div
                            className={styles.loadMoreButton}
                            onClick={this.handleAddMoreButton}
                            style={{
                                display:
                                    this.state.autoLoadEnabled ||
                                    this.state.isLoadingMore ||
                                    (!this.state.hasMoreToLoad && this.topIndex === 0)
                                        ? 'none'
                                        : 'block',
                            }}
                        >
                            {this.state.isLoadingMore ? 'Loading...' : 'Load More'}
                        </div>
                    </div>
                    {renderItems}
                </div>
            </div>
        );
    }

    log(...args: any[]) {
        if (this.debug) {
            console.log(...args);
        }
    }
}

function hasReactions(item: FeedItem): boolean {
    // @ts-ignore
    return item.data && item.data.reactions && item.data.reactions.length > 0;
}

export default connector(FeedScroller);
