@import '../../../style/globals.scss';

.imagePlaceholder {
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: rgba($color: #000000, $alpha: 0);
}
.namePlaceholder {
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: rgba($color: #000000, $alpha: 0);
}

.messagePlaceholder {
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: rgba($color: #000000, $alpha: 0);
}

.reactionsPlaceholder {
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: rgba($color: #000000, $alpha: 0);
}

.ModerationActions {
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    opacity: 0;
}

.modActionsOptions {
    position: absolute;
    z-index: 30;
    width: 200px;
    right: 0px;
    border: 1px solid white;
    background-color: rgb(15, 15, 15);

    &.isOffBottom {
        bottom: 10px;
    }

    button {
        width: 100%;
        height: 100%;
        border: 1px solid white;
        padding: 5px;
        margin-top: 5px;
        &:hover {
            background-color: #330101;
            text-decoration: underline;
        }
    }
}

.ChatMessage {
    padding: 16px 0px 15px 30px;
    border-radius: 8px;
    word-break: break-word;

    .reactionsMenu {
        opacity: 0;
    }

    &.highlighted {
        background-color: var(--token-arise-theme-1-state-state-success-dark);

        &:hover,
        &:focus,
        &:focus-within {
            background-color: var(--token-arise-theme-1-state-state-success-dark);
        }
    }

    &:hover,
    &:focus,
    &:focus-within {
        background-color: var(--token-accent-theme-1-interface-interface-midground);

        .reactionsMenu {
            opacity: 1;
        }

        .ModerationActions {
            opacity: 1;
        }
    }

    .displayName {
        font-weight: bold;
    }

    .avatar {
        position: absolute;
        top: 16px;
        left: -20px;
        overflow: hidden;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .mention {
        font-weight: bold;
        color: var(--token-arise-theme-1-state-state-success-light);
    }

    opacity: 0.5;
    &.visible {
        opacity: 1;
    }
}

.ChatMessageWrapper {
    margin-left: 20px;
}
