import classNames from 'classnames';

import * as styles from './styles.module.scss';

interface FailOverlayProps {
    failed: boolean;
}

export default function FailOverlay({ failed }: FailOverlayProps) {
    // TODO: from cms
    const imgPath = '/assets/themes/titan/images/failscreen/failscreen-overlay.webp';

    return (
        <>
            <div
                className={classNames(styles.FailOverlayBg, {
                    [styles.failed]: failed,
                })}
            ></div>
            <div
                className={classNames(styles.FailOverlay, {
                    [styles.failed]: failed,
                })}
            >
                <img src={imgPath} alt="Failed" nopin="nopin" />
            </div>
        </>
    );
}
