/**
 * Returns the time difference between an ISO date string and now
 * @param dateString example: 2023-12-27T15:05:00Z
 * @returns {number} number in ms
 */

export function msTimeDifferenceFromNow(dateString: string): number {
    return new Date(dateString).getTime() - new Date().getTime();
}

export function millisecondsLeftFromTimeAndDuration(from: number, durationSeconds: number): number {
    return Math.max(0, durationSeconds * 1000 - Math.floor(Date.now() - from));
}

export function msTimeDifferenceBetweenUTCDates(dateString1: string, dateString2: string) {
    const time1 = new Date(dateString1).getTime();
    const time2 = new Date(dateString2).getTime();
    return Math.abs(time1 - time2);
}

// If timeout is too long, integer will overflow and timeout will execute immediately
export const MAX_TIMEOUT = 1000 * 60 * 60 * 24 * 7;

// e.g 1h 12m 32s
export function secondsIntoTimeString(seconds: number): string {
    if (seconds < 0) {
        throw new Error('The number of seconds cannot be negative.');
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    const parts: string[] = [];
    if (hours > 0) {
        parts.push(`${hours}h`);
    }
    if (minutes > 0 || (hours > 0 && secs > 0)) {
        parts.push(`${minutes}m`);
    }
    if (secs > 0) {
        parts.push(`${secs}s`);
    }

    return parts.join(' ');
}
