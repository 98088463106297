import * as styles from './styles.module.scss';
import classNames from 'classnames';

interface AvatarProps {
    imageUrl?: string;
    onClick?: () => void;
    title?: string;
    classname?: string;
}

export default function Avatar({ imageUrl, onClick, title, classname }: AvatarProps) {
    const props = {
        className: classNames(styles.Avatar, classname, { [styles.hasHover]: onClick }),
        style: { backgroundImage: `url(${imageUrl}?h=120)` },
        title,
    };

    if (onClick) {
        return <button {...props} onClick={onClick} />;
    } else {
        return <div {...props} />;
    }
}
