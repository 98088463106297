import { useEffect, useMemo, useState } from 'react';

import { BAN_MESSAGE_SUBTITUTION_STRING, BAN_MESSAGE_TIME_FALLBACK } from '../content';
import FeedInputOverlay from '../FeedInputOverlay';

interface FrozenFeedInputOverlayProps {
    message: string;
    bannedUntil?: string;
}

export default function FrozenFeedInputOverlay({ message, bannedUntil }: FrozenFeedInputOverlayProps) {
    const [timeLeft, setTimeLeft] = useState(null);
    const untilTime = useMemo(() => {
        return new Date(bannedUntil);
    }, [bannedUntil]);

    useEffect(() => {
        if (!bannedUntil) {
            return;
        }

        function update() {
            const timeDiff = untilTime.getTime() - Date.now();
            const secondsLeft = Math.max(Math.floor(timeDiff / 1000), 0);
            setTimeLeft(secondsLeft ? secondsToString(secondsLeft) : null);
        }

        update();

        const interval = setInterval(update, 1000);

        return () => clearInterval(interval);
    });

    const timeString = timeLeft ?? BAN_MESSAGE_TIME_FALLBACK;
    return <FeedInputOverlay message={message.replace(BAN_MESSAGE_SUBTITUTION_STRING, timeString)} />;
}

function secondsToString(secondsLeft: number) {
    const hours = Math.floor(secondsLeft / 3600);
    const minutes = Math.floor((secondsLeft % 3600) / 60);
    const seconds = secondsLeft % 60;

    const pluralise = (text: string, number: number) => (number === 1 ? text : text + 's');

    const values = [];
    hours ? values.push(`${hours} ${pluralise('hour', hours)}`) : '';
    minutes ? values.push(`${minutes} ${pluralise('minute', minutes)}`) : '';
    seconds ? values.push(`${seconds} ${pluralise('second', seconds)}`) : '';

    return values.join(', ');
}
