import * as styles from './styles.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';

import IconButton from '@/components/IconButton';
import SuggestionsPicker from '../SuggestionsPicker';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { submitCommand, submitMessage } from '@/state/features/feed';
import { sendEvent } from '@/utils/analytics';
import { Profile } from '@/models/profile';
import { moveCursorAfterElement } from './cursor';
import { getHasPastedChatEventWinString, submitPastedChatEventWinString } from '@/utils/events';

export interface ChatBarProps {
    placeholder?: string;
    onResize: () => void;
    locationId: string;
    isLocked?: boolean;
    canSendCommands?: boolean;
}
const MAX_LENGTH = 2000;

export default function ChatBar({ placeholder, onResize, locationId, isLocked, canSendCommands }: ChatBarProps) {
    const currentEvent = useAppSelector((state) => state.feed.currentEvent);
    const dispatch = useAppDispatch();
    const [queryValue, setQueryValue] = useState('');
    const [isTypingMention, setIsTypingMention] = useState(false);
    const [currentKey, setCurrentKey] = useState('');

    const hasPastedEventWinString = useRef<boolean>(false);

    const inputRef = useRef<HTMLDivElement>(null);
    const shadowInputRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    function shouldSendAsCommand(message) {
        return canSendCommands && message.startsWith('/');
    }

    function submit() {
        const processedText = processMessageForSending(inputRef.current.innerHTML);
        if (!processedText?.length || isLocked) {
            return;
        }

        if (shouldSendAsCommand(processedText)) {
            dispatch(submitCommand([locationId, processedText]));
        } else if (hasPastedEventWinString.current) {
            // Check for cheating in chat event
            submitPastedChatEventWinString(locationId, dispatch);
            sendEvent('send_chat_message');
        } else {
            dispatch(submitMessage([locationId, processedText]));
            sendEvent('send_chat_message');
        }

        setQueryValue('');
        setIsTypingMention(false);
        clearInput();
    }

    const updateShadowInput = useCallback(
        // When the content changes, shadow input must have the same content so it can be used for measuring
        throttle(() => {
            if (inputRef.current && shadowInputRef.current) {
                shadowInputRef.current.innerHTML = inputRef.current.innerHTML;
                resizeChatBox();
            }
        }, 200),
        [],
    );

    useEffect(() => {
        // Resize text area when window resizes
        const throttledResizeChatBox = throttle(resizeChatBox, 200);
        const handleResize = () => {
            throttledResizeChatBox();
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function resizeChatBox() {
        const element = inputRef.current;
        const shadowElement = shadowInputRef.current;
        const container = containerRef.current;
        if (!element || !shadowElement || !container) return;
        // Resize textarea if needed
        const maxHeight = 116; // 4 lines of text

        const lastHeight = element.style.height;
        element.style.height = shadowElement.scrollHeight + 'px';
        container.style.height = shadowElement.scrollHeight + 'px';

        // Show/hide scrollbar based on content overflow
        if (shadowElement.scrollHeight > maxHeight) {
            container.style.overflowY = 'scroll';
        } else {
            container.style.overflowY = 'hidden';
        }

        if (lastHeight !== element.style.height && onResize) {
            onResize();
        }
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
        if (e.key === 'Enter' && !isTypingMention) {
            e.preventDefault();
            submit();
        }
        if (isTypingMention) {
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter') {
                e.preventDefault();
                setCurrentKey(e.key);
            }
            if (e.key === 'Escape') {
                closePicker();
            }
        }
    }

    function convertMentionToPlainText() {
        const mention = getCurrentMention();
        if (mention) {
            moveCursorAfterMention();
            const textNode = document.createTextNode(mention.textContent);
            mention.parentNode.replaceChild(textNode, mention);
        }
    }

    function handleKeyUp(e: React.KeyboardEvent<HTMLDivElement>) {
        if (isTypingMention) {
            setCurrentKey(null);
        }
    }

    function removeLineBreaks(value: string) {
        return value.replace(/(\r\n|\n|\r)/gm, ' ');
    }

    function onMentionSelected(profile: Profile) {
        if (!profile) {
            // If user presses enter while SuggestionsPicker has no suggestions, submit message
            submit();
        }
        setIsTypingMention(false);
        const mentionElement = getCurrentMention();
        if (mentionElement && profile) {
            moveCursorAfterMention();
            mentionElement.innerText = `@${profile.displayName}`;
            mentionElement.setAttribute('data-userId', profile.userID);
            mentionElement.setAttribute('data-displayName', profile.displayName);
            mentionElement.style.backgroundColor = '';
            mentionElement.classList.remove(styles.Chatbar__mentionInProgress);
            mentionElement.classList.add(styles.Chatbar__mention);
            mentionElement.contentEditable = 'true';
            setQueryValue('');
            updateShadowInput();
        }
    }

    function closePicker() {
        setQueryValue('');
        setIsTypingMention(false);
        convertMentionToPlainText();
    }

    const moveCursorAfterMention = () => {
        moveCursorAfterElement(getCurrentMention());
    };

    const handleBeforeInput = (e) => {
        if (inputRef.current.textContent.length >= MAX_LENGTH) {
            // Enforce character limit
            e.preventDefault();
            return;
        }
        // If caret is at end of mention, and user types, make text go after the mention
        if (!isTypingMention && getCurrentMention()) {
            const range = window.getSelection().getRangeAt(0);
            const element = getCurrentMention();
            if (range.endOffset === element.innerText.length) {
                moveCursorAfterMention();
            }
        }
    };

    const clearInput = () => {
        const range = document.createRange();
        const selection = window.getSelection();

        // Set the range to cover all the text nodes within the div
        range.selectNodeContents(inputRef.current);

        // Select the range
        selection.removeAllRanges();
        selection.addRange(range);

        // Delete the selected range
        selection.deleteFromDocument();
        // As not updating the inputHtml state to preserve caret, manually update shadow input height
        shadowInputRef.current.innerHTML = '';
        resizeChatBox();
        hasPastedEventWinString.current = false;
    };

    const handleInput = (e) => {
        const isEmpty = !e.target?.innerText?.trim()?.length;
        if (isEmpty) {
            // Remove any empty elements browser may have added
            clearInput();
            return;
        }

        const selection = window.getSelection();
        if (selection.rangeCount === 0) return;
        const range = selection.getRangeAt(0);

        // Check if cursor is in text node
        if (range.startContainer.nodeType === 3) {
            const textNode = range.startContainer;
            const textContent = textNode.textContent;
            const cursorPos = range.startOffset;

            // If inside Mention
            if (!isTypingMention && getCurrentMention()) {
                // Remove mention if edited after creation
                const mention = getCurrentMention();
                if (mention.innerText.slice(1) !== mention.getAttribute('data-displayName')) {
                    moveCursorAfterMention();
                    mention.remove();
                }
                return;
            }

            // If just typed an @
            if (!isTypingMention && textContent[cursorPos - 1] === '@') {
                // If user typed @, create new in progress mention
                setIsTypingMention(true);
                // Create a new Mention element
                const mentionElement = document.createElement('span');
                mentionElement.contentEditable = 'true';
                mentionElement.classList.add(styles.Chatbar__mentionInProgress);
                mentionElement.textContent = '@';

                // Insert the new Mention at the cursor position
                range.setStart(textNode, cursorPos - 1);
                range.setEnd(textNode, cursorPos);
                range.deleteContents();
                range.insertNode(mentionElement);

                // Move the cursor inside the new Mention
                range.setStart(mentionElement, 1);
                range.collapse(true);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }

        if (isTypingMention) {
            const query = getCurrentMention()?.innerText?.slice(1);
            setQueryValue(query);
            if (!query || query.match(/^\s/)) {
                // If in progress mention is empty or begins with spaces, cancel mention
                setIsTypingMention(false);
                convertMentionToPlainText();
            }
        }
        updateShadowInput();
    };

    const getCurrentMention = () => {
        // Returns Mention element caret is currently inside
        const node = document.getSelection()?.anchorNode?.parentNode;
        if (node?.nodeName === 'SPAN') {
            return node as HTMLElement;
        }
    };

    const onPaste = (e) => {
        // Only allow plain text to be pasted
        e.preventDefault();
        const pastedText = e.clipboardData ? e.clipboardData.getData('text/plain') : '';
        const pastedTextFiltered = removeLineBreaks(pastedText);
        // Trim pasted text to fit within remaining character limit
        const characterLimitRemaining = MAX_LENGTH - inputRef.current.textContent.length;
        const pastedTextTrimmed = pastedTextFiltered.slice(0, characterLimitRemaining);
        // Manually insert the pasted text
        const selection = window.getSelection();
        if (!selection) return;
        const range = selection.getRangeAt(0);
        range.deleteContents();
        range.insertNode(new Text(pastedTextTrimmed));
        range.collapse(); // select nothing
        selection.removeAllRanges(); // position caret after inserted text
        selection.addRange(range); // show carets
        hasPastedEventWinString.current = getHasPastedChatEventWinString(pastedTextTrimmed, currentEvent);
        updateShadowInput();
    };

    function processMessageForSending(html) {
        // Format message like: What's up <<user:215cae35-99de-48ca-999c-b3c0865082ae>> and <<user:4c5e4025-621d-4c57-9354-53c97832f280>>?
        const clonedDiv = document.createElement('div');
        clonedDiv.innerHTML = html;

        let messageString = '';
        clonedDiv.childNodes.forEach((node) => {
            if (node.nodeName === 'SPAN') {
                const elementNode = node as HTMLElement;
                const userId = elementNode.getAttribute('data-userid');
                if (userId) {
                    messageString += `<<user:${elementNode.getAttribute('data-userid')}>>`;
                } else {
                    messageString += elementNode.innerText;
                }
            } else if (node.textContent) {
                messageString += node.textContent;
            }
        });

        return messageString.trim();
    }

    function onBlur() {
        if (isTypingMention) {
            closePicker();
        }
    }

    return (
        <div onBlur={onBlur}>
            <div className={styles.Chatbar} ref={containerRef}>
                <div
                    ref={shadowInputRef}
                    aria-hidden="true"
                    tabIndex={-1}
                    className={styles.Chatbar__input}
                    style={{ opacity: 0, zIndex: -100, left: '-10000px', transition: 'none' }}
                />
                <div
                    role="textbox"
                    contentEditable={!isLocked}
                    className={styles.Chatbar__input}
                    ref={inputRef}
                    placeholder={placeholder}
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyDown}
                    onBeforeInput={handleBeforeInput}
                    onInput={handleInput}
                    onPaste={onPaste}
                />
                <IconButton
                    iconType="send"
                    buttonStyle="icon"
                    className={styles.Chatbar__sendButton}
                    ariaLabel="Send"
                    onClick={submit}
                    disabled={isLocked}
                />
            </div>
            <SuggestionsPicker
                query={queryValue}
                onProfileSelected={onMentionSelected}
                visible={isTypingMention}
                currentKey={currentKey}
                onPickerClosed={closePicker}
                closePickerOnEmptyResult
            />
        </div>
    );
}
