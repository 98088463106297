import { initaliseGA, sendEvent as sendGAEvent } from './ga';
import { sendEvent as sendAmplitudeEvent, setAmplitudeKey } from './amplitute';

let initialised = false;

export function init(gaId: string, amplitudeKey: string) {
    if (!initialised && gaId && amplitudeKey) {
        initaliseGA(gaId);
        setAmplitudeKey(amplitudeKey);
        initialised = true;
    }
}

export function sendEvent(name: string, data?: any) {
    sendGAEvent(name, data);
    sendAmplitudeEvent(name, data);
}
