import classNames from 'classnames';
import Icon, { IconTypes } from '../Icon';
import * as styles from './styles.module.scss';

export type IconButtonStyleTypes = 'block' | 'round' | 'icon' | 'diamond';

interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    iconType: IconTypes;
    ariaLabel: string;
    buttonStyle: IconButtonStyleTypes;
    buttonRef?: React.RefObject<HTMLButtonElement>;
}

export default function IconButton({
    iconType,
    ariaLabel,
    buttonStyle = 'block',
    className,
    buttonRef,
    ...props
}: IconButtonProps) {
    return (
        <button
            className={classNames(styles.IconButton, className, {
                [styles['isBlockStyle']]: buttonStyle === 'block',
                [styles['isRoundStyle']]: buttonStyle === 'round',
                [styles['isIconStyle']]: buttonStyle === 'icon',
                [styles['isDiamondStyle']]: buttonStyle === 'diamond',
            })}
            aria-label={ariaLabel}
            ref={buttonRef}
            {...props}
        >
            <Icon type={iconType} size={24} />
        </button>
    );
}
