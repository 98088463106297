import { useEffect, useRef } from 'react';
import { Channel } from 'pusher-js';
import { PusherService } from '@/arise/api/pusher/PusherService';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { updateProfile } from '@/state/features/profile';
import { Profile } from '@/models/profile';
import { useUser } from '@/hooks/auth';
import { msTimeDifferenceFromNow } from '@/utils/time';
import { processProfileData } from '@/utils/profile';
import { updateFeedProfile } from '@/state/features/feed';
import { updateCurrentlyViewingProfileIfMatches } from '@/state/features/app';

const channelName = 'private-profile-update';
const pusherMessageUpdateProfileEvent = 'client-profile-update';

export function useProfilePusherSubscribe() {
    const dispatch = useAppDispatch();
    const channelRef = useRef<Channel>();
    const user = useUser();

    useEffect(() => {
        function handleUpdateProfile({ data }: { data: Profile }) {
            const profile = processProfileData(data);
            dispatch(updateFeedProfile(profile));
            dispatch(updateProfile(profile));
            dispatch(updateCurrentlyViewingProfileIfMatches(profile));
        }

        channelRef.current = PusherService.subscribe(channelName);
        channelRef.current?.bind(pusherMessageUpdateProfileEvent, handleUpdateProfile);

        return () => {
            channelRef.current?.unbind(pusherMessageUpdateProfileEvent, handleUpdateProfile);
            PusherService.unsubscribe(channelName);
        };
    }, [user]);

    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);

    useEffect(() => {
        if (currentUserProfile) {
            const timeUntilUnbanned = msTimeDifferenceFromNow(currentUserProfile.bannedUntil);
            if (currentUserProfile.bannedUntil && timeUntilUnbanned >= 0) {
                const timeout = setTimeout(() => {
                    dispatch(
                        updateProfile({
                            ...currentUserProfile,
                            isBanned: false,
                        }),
                    );
                }, timeUntilUnbanned);
                return () => clearTimeout(timeout);
            }
        }
    }, [currentUserProfile]);

    return {
        channelRef,
    };
}
