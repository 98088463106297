@import '../../style/globals.scss';

$padding: 30px;
$paddingSmall: 10px;

.ProfileDrawer {
    position: absolute;
    z-index: 120;
    top: 0;
    right: -$sidebar-width;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(30px);
    color: var(--token-accent-theme-1-text-text-default);
    transition: $sidebar-transition;

    @media (max-width: $bp-mobile-chat) {
        width: 100%;
        right: -100%;
    }

    &.isOpen {
        right: 0;
    }

    &.isLoading {
        .factionImage {
            z-index: -1;
        }
    }

    .factionImage {
        position: absolute;
        top: -50px;
        width: 100%;
        height: 287px;
        background-position: top;
        background-size: auto 100%;
        background-repeat: no-repeat;
        opacity: 0.1;
        pointer-events: none;
    }

    .topBar {
        background-color: black;
        height: $header-height;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .closeButton {
            position: absolute;
            width: $header-height;
            height: $header-height;
            left: 0;
        }

        .heading {
            text-align: center;
            text-transform: uppercase;

            span {
                display: block;

                &:first-child {
                    color: var(--token-accent-theme-1-brand-colour);
                }
            }
        }
    }

    .content {
        @include scrollbar-styles;
        position: relative;
        z-index: 1;
        overflow-y: auto;
        height: calc(100% - $header-height);
        padding-right: 20px;
        padding-left: 20px;
    }

    .userInfo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: end;
        width: 100%;
        min-height: 315px;
        border-bottom: $line-style;

        .userInfoContent {
            padding: $padding;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .avatar,
            .loadingAvatar {
                display: block;
                width: 90px;
                height: 90px;
                border-radius: 50%;
            }

            .loadingAvatar {
                background-color: var(--token-accent-theme-1-interface-interface-grey);
            }

            h2 {
                display: block;
                font-size: $font-size-h5;
            }

            .badge {
                display: block;
                margin-top: $paddingSmall;
            }
        }
    }

    .scores {
        height: 88px;
        border-bottom: $line-style;
        font-family: var(--font-primary);
        font-size: $font-size-p;
        .success {
            color: var(--token-arise-theme-1-state-state-success-light);
        }
        .failure {
            color: var(--token-arise-theme-1-state-state-error-light);
        }

        ul {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        li {
            display: flex;
            flex: 1 1 33.33%;
            flex-direction: column;
            text-align: center;

            span {
                display: block;
            }
        }
    }

    .titlesUnlocked {
        padding: $padding;

        h3 {
            width: 100%;
            text-align: center;
            margin-bottom: $paddingSmall;
            font-family: var(--font-primary);
            font-size: $font-size-p;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            gap: 7px;
        }
    }

    .loadingScreen {
        z-index: 10;
        background-color: black;
        transition: none;
    }
}
