import Icon from '@/components/Icon';
import * as styles from './styles.module.scss';

interface FeedInputOverlayProps {
    message: string;
}

export default function FeedInputOverlay({ message }: FeedInputOverlayProps) {
    return (
        <div className={styles.FeedInputOverlay}>
            {message}
            <Icon type="padlock" size={24} />
        </div>
    );
}
