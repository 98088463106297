import classNames from 'classnames';

import Button from '@/components/Forms/Button';
import { useAppDispatch } from '@/state/store';
import { clearFailed } from '@/state/features/app';
import { AriseBridgeService } from '@/arise/bridge/AriseBridgeService';
import { useAudio } from '@/hooks/audio';

import * as styles from './styles.module.scss';

import { useFailedWithTimer } from '@/hooks/failed';

export default function FailedInputOverlay() {
    const dispatch = useAppDispatch();
    const { audio } = useAudio();

    const { hasFailed, canReattempt } = useFailedWithTimer();
    const isVisible = hasFailed && canReattempt;

    function onClick() {
        AriseBridgeService.sendEvent('reattempt', {});
        dispatch(clearFailed());
        audio.play('slam');
    }

    return (
        <div
            className={classNames(styles.FailedInputOverlay, {
                [styles.visible]: isVisible,
            })}
        >
            <Button onClick={onClick} disabled={!isVisible}>Reattempt</Button>
        </div>
    );
}
