@import '../../style/globals.scss';

.Badge {
    display: inline-block;
    height: 21px;
    padding: 2px;
    padding: 0 7px;
    font-size: $font-size-p;
    font-weight: 500;
    border-radius: 5px;

    // Default is common
    color: var(--token-arise-theme-1-rarity-rarity-common-light);
    background-color: var(--token-arise-theme-1-rarity-rarity-common-mid);

    $rarities: 'common', 'uncommon', 'rare', 'epic', 'legendary';

    @each $rarity in $rarities {
        &.#{$rarity} {
            color: var(--token-arise-theme-1-rarity-rarity-#{$rarity}-light);
            background-color: var(--token-arise-theme-1-rarity-rarity-#{$rarity}-mid);

            .icon {
                background-color: var(--token-arise-theme-1-rarity-rarity-#{$rarity}-light);
            }
        }
    }

    &.system {
        color: var(--token-accent-theme-1-interface-interface-white);
        background-color: var(--token-accent-theme-1-interface-interface-foreground);
    }

    .icon {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 5px;
        margin-bottom: 1px;
        background-color: var(--token-arise-theme-1-rarity-rarity-common-light);
        mask-size: 100%;
        vertical-align: text-bottom;
    }

    p {
        display: inline-block;
    }
}
