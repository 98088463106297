@import '../../style/globals.scss';

$loginContentMarginLeftLarge: 70px;
$loginContentMarginLeftSmall: 30px;
$inputHeight: 45px;
$contentWidth: 365px;

.authNextStepButton {
    height: $inputHeight;
}

.loginPage,
.registerPage {
    position: absolute;
    width: 100%;
    height: 100%;

    @media (max-width: $bp-s) {
        background-color: var(--token-accent-theme-1-interface-interface-black);
    }

    &__content {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: calc(100% - $header-height);
        max-height: 800px;
        padding: 10px $loginContentMarginLeftLarge;
        margin-top: $header-height;
        transition: opacity 1000ms;
        opacity: 1;

        @media (max-width: $bp-s) {
            width: 100%;
            padding: 10px $loginContentMarginLeftSmall;
        }
    }

    &__logo {
        width: $contentWidth;
        max-width: 100%;
        height: 237px;
        margin: 15px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        animation: login-logo-fade 1500ms $animation-bounce;

        @keyframes login-logo-fade {
            from {
                transform: translateY(100px);
                opacity: 0;
            }
            to {
                transform: translateY(0px);
                opacity: 1;
            }
        }
    }

    &__formContent {
        width: $contentWidth;

        @media (max-width: $bp-s) {
            width: 100%;
            max-width: $contentWidth;
        }
    }

    &__inputContainer {
        position: relative;
        display: inline-block;
        width: 100%;
        height: $inputHeight;
        margin-bottom: 12px;
    }

    &__loginTextInput {
        position: absolute;
        z-index: auto;
        left: 0px;
        display: inline-block;
        width: 100%;
        margin-bottom: 14px;
        transition-property: transform, opacity;
        transition-timing-function: $animation-bounce;
        opacity: 1;

        &--preVisible {
            z-index: -100;
            transform: translateX(100%);
        }

        &--postVisible {
            transform: translateX(-200%);
        }
    }

    &__errorMessage {
        color: var(--token-arise-theme-1-state-state-error-light);

        &:empty::before {
            content: '';
            display: inline-block;
        }
    }

    .parallaxContainer {
        transition: opacity 1s ease-in-out;
    }

    /* Custom elements styles */
    .titan {
        .loginPage__customElement {
            position: absolute;
            width: 100%;
            height: 130%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom left;
            transition-timing-function: $animation-bounce;
            transition-property: bottom;

            @media (max-width: $bp-s) {
                width: 140%;
            }
        }

        .loginPage__customElementForeground {
            left: 655px;

            @media (max-width: $bp-s) {
                left: -10%;
            }

            &--frame-0 {
                bottom: -20%;
                transition-duration: 0;
            }
            &--frame-1 {
                bottom: -5%;
                transition-duration: 1500ms;
            }
            &--frame-2,
            &--frame-3 {
                bottom: -1%;
                transition-duration: 500ms;
            }
        }

        .loginPage__customElementMidground {
            left: 660px;

            @media (max-width: $bp-s) {
                left: 0%;
            }

            &--frame-0 {
                bottom: -50%;
                transition-duration: 0;
            }
            &--frame-1 {
                bottom: -5%;
                transition-duration: 1500ms;
            }
            &--frame-2,
            &--frame-3 {
                bottom: -2%;
                transition-duration: 500ms;
            }
        }

        .loginPage__customElementBackground {
            left: 660px;

            @media (max-width: $bp-s) {
                left: -12%;
            }

            &--frame-0 {
                bottom: -40%;
                transition-duration: 0;
            }
            &--frame-1 {
                bottom: -5%;
                transition-duration: 1500ms;
            }
            &--frame-2,
            &--frame-3 {
                bottom: 0px;
                transition-duration: 500ms;
            }
        }
    }
}
