import { useTheme } from '@/theme';
import * as styles from './styles.module.scss';

type Icons = 'facebook' | 'twitter' | 'copy' | 'native';
interface ThemedIconButtonProps {
    text: string;
    onClick: () => void;
    disabled?: boolean;
    icon: Icons;
}

export default function ThemedIconButton({ text, onClick, disabled, icon }: ThemedIconButtonProps) {
    const theme = useTheme();
    const maskImage = theme.customJSON.themedButtonBackgroundMaskImage;
    return (
        <button
            className={styles.ThemedIconButton}
            onClick={onClick}
            style={maskImage ? { maskImage: `url(${maskImage})` } : {}}
            disabled={disabled}
        >
            <img src={iconUrls[icon]} alt={icon} />
            <span>{text}</span>
        </button>
    );
}

const iconUrls = {
    facebook:
        'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5OC4zNiA5OCI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogI2ZmZjsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPGcgaWQ9InN2ZzkiPgogICAgPGcgaWQ9ImcyMSI+CiAgICAgIDxnIGlkPSJnMjciPgogICAgICAgIDxwYXRoIGlkPSJwYXRoMjkiIGNsYXNzPSJjbHMtMSIgZD0ibTk4LjM2LDQ5LjE4Qzk4LjM2LDIyLjAyLDc2LjM0LDAsNDkuMTgsMFMwLDIyLjAyLDAsNDkuMThjMCwyMy4wNiwxNS44OCw0Mi40MiwzNy4zLDQ3Ljczdi0zMi43aC0xMC4xNHYtMTUuMDNoMTAuMTR2LTYuNDhjMC0xNi43NCw3LjU4LTI0LjUsMjQuMDEtMjQuNSwzLjEyLDAsOC40OS42MSwxMC42OSwxLjIydjEzLjYyYy0xLjE2LS4xMi0zLjE4LS4xOC01LjY4LS4xOC04LjA2LDAtMTEuMTgsMy4wNS0xMS4xOCwxMXY1LjMxaDE2LjA2bC0yLjc2LDE1LjAzaC0xMy4zdjMzLjc5YzI0LjM1LTIuOTQsNDMuMjItMjMuNjgsNDMuMjItNDguODIiLz4KICAgICAgPC9nPgogICAgPC9nPgogIDwvZz4KPC9zdmc+',
    twitter:
        'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5Ny45MiA5OCI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogI2ZmZjsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPGcgaWQ9InN2ZzkiPgogICAgPHBhdGggY2xhc3M9ImNscy0xIiBkPSJtNTguMjgsNDEuNUw5NC43NCwwaC04LjY0bC0zMS42NywzNi4wM0wyOS4xNiwwSDBsMzguMjMsNTQuNDhMMCw5OGg4LjY0bDMzLjQyLTM4LjA1LDI2LjcsMzguMDVoMjkuMTZNMTEuNzUsNi4zOGgxMy4yN2w2MS4wOCw4NS41NmgtMTMuMjciLz4KICA8L2c+Cjwvc3ZnPg==',
    copy: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MC4yMSA5OC40NiI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogI2ZmZjsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPGcgaWQ9InN2ZzkiPgogICAgPHBhdGggY2xhc3M9ImNscy0xIiBkPSJtMCw0My45N3YtMTguODdjMC02Ljk1LDIuNDUtMTIuODcsNy4zNC0xNy43NkMxMi4yNCwyLjQ1LDE4LjE2LDAsMjUuMSwwczEyLjg3LDIuNDUsMTcuNzYsNy4zNGM0LjksNC45LDcuMzQsMTAuODIsNy4zNCwxNy43NnYxOC44N2gtMTAuOTh2LTE4Ljg2YzAtMy45My0xLjM3LTcuMjctNC4xMi0xMC4wMi0yLjc1LTIuNzUtNi4wOC00LjEyLTEwLjAxLTQuMTJzLTcuMjYsMS4zNy0xMC4wMSw0LjEyYy0yLjc1LDIuNzUtNC4xMiw2LjA5LTQuMTIsMTAuMDJ2MTguODZIMFptMjAuMDUtMTQuNzFoMTAuMTF2MzkuOTNoLTEwLjExczAtMzkuOTMsMC0zOS45M1pNMCw1NC40OWgxMC45OHYxOC44NmMwLDMuOTMsMS4zNyw3LjI3LDQuMTIsMTAuMDIsMi43NSwyLjc1LDYuMDgsNC4xMiwxMC4wMSw0LjEyLDMuOTIsMCw3LjI2LTEuMzcsMTAuMDEtNC4xMiwyLjc1LTIuNzUsNC4xMi02LjA5LDQuMTItMTAuMDJ2LTE4Ljg2aDEwLjk4djE4Ljg3YzAsNi45NS0yLjQ1LDEyLjg3LTcuMzQsMTcuNzYtNC45LDQuOS0xMC44Miw3LjM0LTE3Ljc2LDcuMzQtNi45NSwwLTEyLjg3LTIuNDUtMTcuNzYtNy4zNC00LjktNC45LTcuMzQtMTAuODItNy4zNC0xNy43NnYtMTguODdaIi8+CiAgPC9nPgo8L3N2Zz4=',
    native: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3Ny4wMSAxMDUuNTEiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6ICNmZmY7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIGlkPSJzdmc5Ij4KICAgIDxwYXRoIGNsYXNzPSJjbHMtMSIgZD0ibTEwLjY4LDEwNS41MWMtMi45NiwwLTUuNDctMS4wNC03LjU2LTMuMTItMi4wOC0yLjA4LTMuMTItNC42LTMuMTItNy41NnYtNDkuNzJjMC0yLjk2LDEuMDQtNS40OCwzLjEyLTcuNTYsMi4wOC0yLjA4LDQuNi0zLjEyLDcuNTYtMy4xMmgxMy4xdjEwLjY4aC0xMy4xdjQ5LjcyaDU1LjY1di00OS43MmgtMTMuMXYtMTAuNjhoMTMuMWMyLjk2LDAsNS40NywxLjA0LDcuNTYsMy4xMiwyLjA4LDIuMDgsMy4xMiw0LjYsMy4xMiw3LjU2djQ5LjcyYzAsMi45Ni0xLjA0LDUuNDctMy4xMiw3LjU2LTIuMDgsMi4wOC00LjYsMy4xMi03LjU2LDMuMTIsMCwwLTU1LjY1LDAtNTUuNjUsMFptMjIuNDktMzNWMjAuNGwtNy41MSw3LjUxLTcuNDctNy41NkwzOC41LDBsMjAuMzIsMjAuMzUtNy40Nyw3LjU2LTcuNTEtNy41MXY1Mi4xMmgtMTAuNjhaIi8+CiAgPC9nPgo8L3N2Zz4=',
};
