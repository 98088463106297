@import '../../style/globals.scss';

.HeaderBar {
    height: $header-height;
    position: relative;
    display: flex;
}

.logoIconWrapper {
    height: $header-height;
    width: $header-height;
    position: relative;
    background-color: var(--token-accent-theme-1-brand-colour);
    user-select: none;
    overflow: hidden;
    transition-property: background-color;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
}

.logoIcon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
}

.menuIcon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translate(0, 100%);
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 30%;
        width: 40%;
        height: 6%;
        background-color: var(--token-accent-theme-1-border-border-highlight);
        transition-property: translate, rotate;
        transition-duration: 330ms, 330ms;
        transition-delay: 330ms, 0ms;
        transition-timing-function: ease-in-out, ease-in-out;
        translate: 0 -125%;
    }
    &::after {
        top: 50%;
        translate: 0 125%;
    }
}

.logoIconWrapper.enabled {
    &:hover,
    &.active {
        background-color: var(--token-accent-theme-1-interface-interface-midground);
        .logoIcon {
            transform: translateY(-100%);
        }
        .menuIcon {
            transform: translate(0, 0);
        }
    }

    &.active {
        .menuIcon {
            &::before {
                translate: 0 0;
                rotate: -45deg;
                transition-delay: 0ms, 330ms;
            }
            &::after {
                translate: 0 0;
                rotate: 45deg;
                transition-delay: 0ms, 330ms;
            }
        }
    }
}
.spacer {
    transition-duration: $transition-duration;
    transition-property: width;
}

.spacerOpen {
    width: $sidebar-width;
}

.userWrapper {
    user-select: none;
}

.headerBarBorderBottom {
    position: absolute;
    top: $header-height;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: $line-style;
    z-index: 110;
}
