import { useAudio } from '@/hooks/audio';

import { useAppSelector } from '@/state/store';
import audiocontent from '@/theme/audiocontent';

export const useTheme = () => {
    const theme = useAppSelector((state) => state.app.theme);
    return theme;
};

export function AudioProvider() {
    const theme = useTheme();

    useAudio(audiocontent[theme.themeId] || []);

    return <></>;
}
