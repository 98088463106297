import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loadInventory, resetAppState } from './app';
import { User } from '@/models/auth';
import * as arise from '@/arise/api';
import { resetProfileState } from '@/state/features/profile';
import { setUserId } from '@/utils/analytics/amplitute';
import { sendEvent } from '@/utils/analytics';

interface AuthState {
    user?: User;
    loggedIn: boolean;
    isLoggingIn: boolean;
    loginError?: string;
    intialAuthCheckComplete: boolean;
    isInitialAuthCheckInProgress: boolean;
    isRegistering: boolean;
    isRegistrationSuccessful: boolean;
    registerError?: string;
    hasLoggedOut?: boolean;
}

const initialState: AuthState = {
    loggedIn: false,
    isLoggingIn: false,
    intialAuthCheckComplete: false,
    isInitialAuthCheckInProgress: false,
    isRegistering: false,
    isRegistrationSuccessful: false,
};

export const initialAuthState = initialState;

export const login = createAsyncThunk<User, [string, string]>('auth/login', async ([email, password], thunkApi) => {
    await arise.login(email, password);
    const user = await arise.me();

    setUserId(user.id);
    sendEvent('login');
    thunkApi.dispatch(loadInventory());

    return user;
});

export const logout = createAsyncThunk<void, undefined>('auth/logout', async (_, t) => {
    await arise.logout();
    t.dispatch(resetAppState());
    t.dispatch(resetProfileState());
});

export const register = createAsyncThunk<boolean, [username: string, email: string, password: string]>(
    'auth/register',
    async ([username, email, password]) => {
        const result = await arise.register(username, email, password);
        return result.success;
    },
);

export const initialAuthCheck = createAsyncThunk<User | null>('auth/initialAuthCheck', async (_, thunkApi) => {
    if (!arise.getAuthToken()) {
        return null;
    }

    const user = await arise.me();

    if (user) {
        setUserId(user.id);
        sendEvent('reopened_app');
        thunkApi.dispatch(loadInventory());
        return user;
    }

    return null;
});

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.isLoggingIn = true;
            state.loginError = undefined;
        });

        builder.addCase(login.fulfilled, (state, action) => {
            state.user = action.payload;
            state.loggedIn = true;
            state.isLoggingIn = false;
            state.loginError = undefined;
        });

        builder.addCase(login.rejected, (state, action) => {
            state.loggedIn = false;
            state.isLoggingIn = false;
            // state.loginError = action.error.message;
            // TODO - where should this text come from?
            state.loginError = 'Invalid email or password';
        });

        builder.addCase(register.pending, (state) => {
            state.isRegistering = true;
            state.registerError = undefined;
            state.isRegistrationSuccessful = false;
        });

        builder.addCase(register.fulfilled, (state) => {
            state.isRegistering = false;
            state.isRegistrationSuccessful = true;
        });

        builder.addCase(register.rejected, (state) => {
            state.isRegistering = false;
            // TODO - where should this text come from?
            state.registerError = 'An error occurred when registering';
            state.isRegistrationSuccessful = false;
        });

        builder.addCase(initialAuthCheck.pending, (state) => {
            state.isInitialAuthCheckInProgress = true;
        });

        builder.addCase(initialAuthCheck.fulfilled, (state, action) => {
            if (action.payload) {
                state.user = action.payload;
                state.loggedIn = !!action.payload;
            }
            state.isInitialAuthCheckInProgress = false;
            state.intialAuthCheckComplete = true;
        });

        builder.addCase(initialAuthCheck.rejected, (state, action) => {
            state.isInitialAuthCheckInProgress = false;
            state.intialAuthCheckComplete = true;
        });

        builder.addCase(logout.pending, (state) => {
            state.loggedIn = false;
            state.intialAuthCheckComplete = true;
            state.user = undefined;
            state.isLoggingIn = false;
            state.hasLoggedOut = true;
        });

        builder.addCase(logout.rejected, (state, action) => {
            console.log(action.error);
        });

        builder.addCase(logout.fulfilled, (state) => {
            // ?
        });
    },
});

export const { actions: authActions, reducer: authReducer } = auth;

export default authReducer;
