import { useState, useEffect, RefObject } from 'react';
// https://dev.to/alejomartinez8/how-to-detect-images-loaded-in-react-39fa

export const useOnLoadImages = (ref: RefObject<HTMLElement>, maxWaitMs?: number) => {
    const [status, setStatus] = useState(false);

    useEffect(() => {
        const updateStatus = (images: HTMLImageElement[]) => {
            if (!status) {
                setStatus(images.map((image) => image.complete).every((item) => item === true));
            }
        };

        if (!ref?.current) return;

        const imagesLoaded = Array.from(ref.current.querySelectorAll('img'));
        if (imagesLoaded.length === 0) {
            setStatus(true);
            return;
        }

        imagesLoaded.forEach((image) => {
            if (image.complete) {
                updateStatus(imagesLoaded);
            }
            image.addEventListener('load', () => updateStatus(imagesLoaded), {
                once: true,
            });
            image.addEventListener('error', () => updateStatus(imagesLoaded), {
                once: true,
            });
        });

        // If maximum waiting time exceeded, set status to true anyway (e.g for if UI is waiting)
        let timeout;
        if (maxWaitMs) {
            timeout = setTimeout(() => {
                setStatus(true);
            }, maxWaitMs);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return status;
};
