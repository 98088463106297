import { InteractionPrompt as IInteractionPrompt } from '@/models/feed';

export default function InteractionPrompt({
    backgroundImageURL,
    foregroundImageURL,
    prompt,
    actionLabel,
    actionType,
}: IInteractionPrompt) {
    return (
        <div className="relative pt-[50px]">
            <div className="relative text-xs  p-2 bg-[#413832] rounded-md overflow-hidden ">
                <div className="absolute inset-0 bg flex">
                    <div className="flex-1 skew-x-[-20deg] scale-150 " style={{ backgroundColor: '#413832' }}></div>
                    <div
                        className="flex-1 bg-center bg-cover"
                        style={{ backgroundImage: `url(${backgroundImageURL})` }}
                    ></div>
                </div>
                <div className="relative z-10 p-4 w-[60%]">
                    <div className="pt-6 pb-6 text-[#F6CEB2]">{prompt}</div>
                    {/* TODO: bubble should map to action type, and pull in relevant icon */}
                    <div className="interact text-[12px] text-[#F6CEB2]">:bubble: {actionLabel}</div>
                </div>
            </div>
            <img className="absolute right-0 bottom-0 h-full" src={foregroundImageURL} />
        </div>
    );
}
