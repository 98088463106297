@import '../../../style/globals.scss';

$height: 40px;
$gap: 10px;

.Update {
    // margin: 0 $chat-padding-m 5px;

    .reactionsMenu {
        opacity: 0;
    }

    .existingReactions {
        margin-left: $height + $gap;
    }

    .reactionsMenu {
        margin-right: 55px; // Aligning this to where the chat message menu appears from the right
    }

    &:hover,
    &:focus,
    &:focus-within {
        .reactionsMenu {
            opacity: 1;
        }
    }
}

.wrapper {
    display: flex;
    font-weight: 500;
    gap: $gap;
    font-size: $font-size-p;
    padding-bottom: 5px;

    .icon {
        width: $height;
        height: $height;
        border-radius: $height;
        background-color: var(--token-accent-theme-1-interface-interface-foreground);
        position: relative;
        svg {
            position: absolute;
            $space: 7px;
            top: $space;
            left: $space;
            width: $height - $space * 2;
            height: $height - $space * 2;
            object-fit: contain;
        }
    }
    .label {
        flex: 1;
        background-color: var(--token-accent-theme-1-interface-interface-midground);
        overflow: hidden;
        line-height: 1.5;
        padding: 8px 10px;
        border-radius: 10px;
        .displayName {
            color: var(--token-accent-theme-1-text-text-default);
        }
        .action {
            color: var(--token-accent-theme-1-text-text-default);
        }
    }

    &.danger {
        .icon,
        .label {
            background-color: var(--token-arise-theme-1-state-state-error-mid);
        }

        .displayName {
            color: var(--token-arise-theme-1-state-state-error-light);
        }
        .action {
            color: var(--token-accent-theme-1-text-text-default);
        }
    }

    &.success {
        .icon,
        .label {
            background-color: var(--token-arise-theme-1-state-state-success-dark);
        }

        .displayName {
            color: var(--token-arise-theme-1-state-state-success-light);
        }
        .action {
            color: var(--token-accent-theme-1-text-text-default);
        }
    }
}
