import { useTheme } from '@/theme';
import HeaderBar from '@/components/HeaderBar';
import FullSizeBackground from '@/components/FullSizeBackground';

export default function AuthLayout({ children }: { children: React.ReactNode }) {
    const theme = useTheme();

    if (!theme.loaded) return <></>;

    return (
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
            <FullSizeBackground imagePath={theme.files.splash_bg} />
            <div>
                <div>{children}</div>
            </div>
            <HeaderBar />
        </div>
    );
}
