import { ThemeIds } from '@/theme/themes';

const audiocontent = {
    // TODO: set audio in CMS
    [ThemeIds.Titan]: [
        {
            id: 'button-click',
            src: '/assets/themes/titan/audio/ui/[UI-Tap-01].mp3',
        },
        {
            id: 'hover',
            src: '/assets/themes/titan/audio/ui/[UI-Hover-01].mp3',
        },
        {
            id: 'gust-open',
            src: '/assets/themes/titan/audio/ui/[SCENE-Gust-01].mp3',
            volume: 0.05,
        },
        {
            id: 'gust-close',
            src: '/assets/themes/titan/audio/ui/[SCENE-Gust-02].mp3',
            volume: 0.05,
        },
        {
            id: 'drum',
            src: '/assets/themes/titan/audio/ui/[UI-Drum-01].mp3',
        },
        {
            id: 'drum2',
            src: '/assets/themes/titan/audio/ui/[UI-Drum-02].mp3',
        },
        {
            id: 'inventory-open',
            src: '/assets/themes/titan/audio/ui/[UI-Inventory-Open].mp3',
            volume: 0.4,
        },
        {
            id: 'inventory-close',
            src: '/assets/themes/titan/audio/ui/[UI-Inventory-Close].mp3',
            volume: 0.4,
        },
        {
            id: 'positive-achievement',
            src: '/assets/themes/titan/audio/ui/[UI-Positive-Achievement-01].mp3',
            volume: 0.5,
        },
        {
            id: 'negative-achievement',
            src: '/assets/themes/titan/audio/ui/[UI-Negative-Achievement-01].mp3',
            volume: 0.5,
        },
        {
            id: 'slam',
            src: '/assets/themes/titan/audio/ui/[SCENE-Slam-01].mp3',
            volume: 0.5,
        },
        {
            id: 'wipe-dark1',
            src: '/assets/themes/titan/audio/ui/[SCENE-WipeDark-01].mp3',
            volume: 0.5,
        },
        {
            id: 'faction-selector-bg',
            src: '/assets/themes/titan/audio/music/[MUSIC-STEM-01].mp3',
            ambient: true,
            volume: 0.5,
        },
        {
            id: 'drone-bg',
            src: '/assets/themes/titan/audio/ui/[SCENE-DroneDark-01].mp3',
            ambient: true,
            volume: 0.5,
        },
        {
            id: 'event__start',
            src: '/assets/themes/titan/audio/events/[EVENT-Start].mp3',
            volume: 0.4,
        },
        {
            id: 'event__success',
            src: '/assets/themes/titan/audio/events/[EVENT-Success].mp3',
            volume: 0.4,
        },
        {
            id: 'event__fail',
            src: '/assets/themes/titan/audio/events/[EVENT-Fail].mp3',
            volume: 0.4,
        },
        {
            id: 'event__countdown',
            src: '/assets/themes/titan/audio/events/[EVENT-Countdown-3s].mp3',
            volume: 0.4,
        },
    ],
};

export default audiocontent;
