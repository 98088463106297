@import '../../../style/globals.scss';

.EventTimer {
    background-color: var(--token-accent-theme-1-interface-interface-white);
    overflow: hidden;
    mask-image: url('/assets/themes/titan/images/events/event-summary-rect-mask.webp');
    mask-size: 100% 100%;
    font-family: var(--font-quaternary);

    .progressBar {
        position: absolute;
        background-color: var(--token-accent-theme-1-brand-colour);
        top: 0;
        height: 100%;
        width: 100%;
        transition-property: left;
        transition-timing-function: linear;
    }

    .statusText {
        text-transform: uppercase;
    }

    .statusText,
    .countdown {
        position: absolute;
        top: 50%;
        left: 25px;
        color: var(--token-accent-theme-1-interface-interface-black);
        font-size: $font-size-h2;
        font-weight: 600;
        transform: translateY(-50%);
    }

    .graphic {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: auto 100%;
        background-repeat: no-repeat;
        transition-property: left;
        transition-timing-function: linear;
        background-position-x: calc(100% + 10px);

        animation-name: move, shake;
        animation-delay: 2s, 2s;
        animation-duration: 10s, 0.2s;
        animation-fill-mode: both, none;
        animation-iteration-count: 1, infinite;
        animation-timing-function: linear;

        @keyframes move {
            0% {
                left: 100%;
            }
            10% {
                left: 25%;
            }
            100% {
                left: 0%;
            }
        }

        @keyframes shake {
            0% {
                transform: translateY(0px);
            }
            50% {
                transform: translateY(-1px);
            }
            to {
                transform: translateY(0px);
            }
        }
    }

    &.passed {
        background-color: var(--token-arise-theme-1-state-state-success-mid);

        .statusText {
            color: var(--token-arise-theme-1-state-state-success-light);
        }
    }

    &.failed {
        background-color: var(--token-accent-theme-1-brand-colour);

        .statusText {
            color: var(--token-accent-theme-1-interface-interface-black);
        }
    }
}
