.Avatar {
    background-color: var(--token-accent-theme-1-interface-interface-foreground);
    background-position: center;
    background-size: 100%;
    transition: background-size 0.5s ease-out;

    &.hasHover {
        cursor: pointer;

        &:hover,
        &:focus {
            background-size: 120%;
        }
    }
}
