@import '../../style/globals.scss';

.ThemedIconButton {
    position: relative;
    display: inline-block;

    width: 90px;
    height: 90px;
    padding: 13px 10px 13px 10px;
    background-color: var(--token-accent-theme-1-button-button-default);
    color: var(--token-accent-theme-1-text-text-default);
    font-weight: 500;
    mask-size: 200% 100%;
    font-family: var(--font-tertiary);

    img {
        width: 25px;
        height: 25px;
        object-fit: contain;
        object-position: center center;
        margin: 5px auto 10px auto;
        position: relative;
    }
    span {
        position: relative;
        font-size: $font-size-p;
    }

    &:hover,
    &:focus {
        &:before {
            left: 0;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        display: block;
        width: 100%;
        height: 100%;
        background-color: var(--token-accent-theme-1-button-button-highlight);
        transition: left 300ms ease-out;
    }
}
