import { useTheme } from '@/theme';
import * as styles from './styles.module.scss';

interface ThemedButtonProps {
    text: string;
    onClick: () => void;
    disabled?: boolean;
}

export default function ThemedButton({ text, onClick, disabled }: ThemedButtonProps) {
    const theme = useTheme();
    const maskImage = theme.customJSON.themedButtonBackgroundMaskImage;
    return (
        <button
            className={styles.ThemedButton}
            onClick={onClick}
            style={maskImage ? { maskImage: `url(${maskImage})` } : {}}
            disabled={disabled}
        >
            <span>{text}</span>
        </button>
    );
}
