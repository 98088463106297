import { Howl, Howler } from 'howler';

export interface AudioFile {
    id: string;
    src: string;
    volume?: number;
    ambient?: boolean;
}

/*
 * AudioController
 * The AudioController is the global audio controller to manage playing both ambient background sounds and sound effects.
 * It uses the Howler to play the audio files.
 * Audio files are loaded on page start are then ready to be played at any time.
 * Audio files can be either ambient or sound effects. Ambient sounds are looped and can only have one instance playing at a time.
 * Non-ambient sounds play on demand and can have multiple instances playing at the same time.
 * Basic fading in and out of ambient sounds is also supported. There are however limitations to this due to limitations in the Howler library.
 *
 * TODO:
 * - Fading needs to be improved. Currently fading when already fading causes unpredictable results.
 * - Add better support for cross fading between ambient sounds.
 * - Keep track of loading state
 * - Ability to add more files after instantiation of the controller so that not all tracks need to be loaded on page start.
 */
export default class AudioController {
    files: { [id: string]: AudioFile } = {};
    tracks: { [id: string]: Howl } = {};

    trackLoadedStates: { [id: string]: boolean } = {};
    ambientTracks: { [id: string]: number } = {};

    constructor(files: AudioFile[]) {
        // @ts-ignore
        window.audioController = this;
        this._loadFiles(files);
    }

    _loadFiles(files: AudioFile[]) {
        files.forEach((file) => {
            this.files[file.id] = file;
            this.trackLoadedStates[file.id] = false;
            this.tracks[file.id] = new Howl({
                src: [file.src],
                loop: file.ambient,
                volume: file.volume || 1,
            });

            this.tracks[file.id].on('load', () => {
                this.trackLoadedStates[file.id] = true;
            });

            this.tracks[file.id].on('loaderror', () => {
                console.error('error loading', file);
            });
        });
    }

    addTrack(file: AudioFile) {
        this._loadFiles([file]);
    }

    hasTrack(trackId: string) {
        return !!this.ambientTracks[trackId];
    }

    /*
     * Play a track. If it's an ambient track, it will only play if it's not already playing.
     * If it's a non-ambient track, it will play multiple instances.
     */
    play(trackId: string) {
        const file = this.files[trackId];
        const track = this.tracks[trackId];
        if (!track) {
            console.error('No track found for', trackId);
            return;
        }

        if (file.ambient) {
            if (this.ambientTracks[trackId] === undefined) {
                this.ambientTracks[trackId] = track.play();
            } else if (!track.playing(this.ambientTracks[trackId])) {
                track.play(this.ambientTracks[trackId]);
            }
            return;
        }

        track.play();
    }

    stop(trackId: string) {
        const file = this.files[trackId];
        const track = this.tracks[trackId];
        if (!track) {
            console.error('No track found for', trackId);
            return;
        }

        if (file.ambient) {
            if (this.ambientTracks[trackId] !== undefined) {
                track.stop(this.ambientTracks[trackId]);
            }
            return;
        }

        track.stop();
    }

    fadeIn(ambientTrackId: string, duration: number = 2000) {
        const file = this.files[ambientTrackId];
        const track = this.tracks[ambientTrackId];

        track.volume(0);
        if (!track.playing()) {
            this.ambientTracks[ambientTrackId] = track.play();
        } else {
            track.seek(0);
        }
        track.fade(0, file.volume || 1, duration);
    }

    fadeOut(ambientTrackId: string, duration: number = 2000) {
        const track = this.tracks[ambientTrackId];

        const volume = track.volume();

        track.fade(volume, 0, duration);
    }

    /*
     * Stop all ambient tracks and play only the specified track. Non-ambient tracks are not affected since they are usully short sfx and not looped.
     */
    stopAll() {
        Object.keys(this.ambientTracks).forEach((ambientTrackId) => {
            this.stop(ambientTrackId);
        });
    }

    /*
     * Stop all ambient tracks and play only the specified track.
     */
    playOnly(trackId: string | string[]) {
        if (!Array.isArray(trackId)) {
            trackId = [trackId];
        }
        Object.keys(this.ambientTracks).forEach((ambientTrackId) => {
            if (!trackId.includes(ambientTrackId)) {
                this.stop(ambientTrackId);
            }
        });

        trackId.forEach((id) => {
            this.play(id);
        });
    }

    mute(shouldMute: boolean) {
        Howler.mute(shouldMute);
    }

    toggleMute() {
        Howler.mute(
            // @ts-ignore
            !Howler._muted,
        );

        // @ts-ignore
        return Howler._muted;
    }
}
