@import '../../style/globals.scss';

.FactionPicker {
    color: var(--token-accent-theme-1-text-text-active);
    overflow: visible hidden;
    @include scrollbar-styles;
    max-width: 100%;
    // To stop background highlight getting clipped by overflow
    padding: 200px 115px 71px 115px;
    margin: -280px -115px -71px -115px;

    ul {
        white-space: nowrap;
    }
}

.FactionPickerFaction {
    backface-visibility: hidden;
    $transition-duration: 400ms;

    position: relative;
    display: inline-block;
    margin-right: 22px;
    vertical-align: top;
    animation-duration: 1s;
    animation-fill-mode: backwards;

    @for $i from 1 through 12 {
        &:nth-child(#{$i}) {
            animation-delay: $i * 100ms;
        }
    }

    &.page-transition-preVisible {
        animation-name: none;
    }

    &.page-transition-visible {
        animation-name: slide-in;
    }

    &.page-transition-postVisible {
        animation-fill-mode: forwards;
        animation-name: slide-out;
    }

    &:last-child {
        margin-right: 0px;
    }

    &__BackgroundContainer {
        filter: brightness(0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        transition: filter $transition-duration $animation-bounce;
        background-size: 100% 100%;
    }

    &__Button {
        position: relative;
        width: 203px;
        height: 237px;
        background-size: 100% 100%;

        &:focus,
        &:hover,
        &--selected {
            .FactionPickerFaction {
                &__Image {
                    transform: scale(1.2);
                    filter: brightness(1);
                }

                &__BackgroundContainer {
                    filter: brightness(1);
                }
            }
        }

        &--selected {
            .FactionPickerFaction {
                &__Border {
                    border-color: var(--token-accent-theme-1-button-button-highlight);
                }
                &__Image {
                    filter: brightness(1);
                }
            }

            & ~ .FactionPickerFaction__HighlightImage {
                transition-duration: 200ms;
                transition-timing-function: $animation-bounce;
                transition-property: background-size, opacity;
                opacity: 1;
                background-size: 100% 100%;

            }
        }
    }

    &__Border {
        $outline-offset: 10px;
        position: absolute;
        width: calc(100% - ($outline-offset * 2));
        height: calc(100% - ($outline-offset * 2));
        border: 1.5px solid var(--token-accent-theme-1-border-border-default);
        margin: $outline-offset;
        inset: 0;
        transition: border-color 500ms $animation-bounce;
    }

    &__Image {
        filter: brightness(0.2);
        position: relative;
        max-width: 100%;
        max-height: 108px;
        margin-right: auto;
        margin-left: auto;
        -webkit-user-drag: none;
        transition: transform $transition-duration $animation-bounce, filter $transition-duration $animation-bounce;
    }

    &__HighlightImage {
        position: absolute;
        z-index: -1;
        top: -135px;
        left: -15px;
        width: 224px;
        height: 491px;
        background-repeat: no-repeat;
        background-position-y: bottom;
        rotate: 30deg;
        transition: none;
        opacity: 0;
        background-size: 100% 0%;

        animation-name: fade-in;
        animation-duration: 2s;
        animation-fill-mode: backwards;
        animation-delay: 1.5s;
    }

    &__Text {
        position: absolute;
        right: 0;
        bottom: -8px;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: 162px;
        height: 47px;
        padding: 9px;
        margin-right: auto;
        margin-left: auto;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    &__Title {
        font-size: $font-size-p;
        font-family: var(--font-primary);
        text-transform: uppercase;
    }

    &__Subtitle {
        color: var(--token-accent-theme-1-text-text-secondary);
        font-size: $font-size-small;
        margin-top: -4px;
        color: var(--token-accent-theme-1-text-text-secondary);
    }

    @keyframes slide-in {
        from {
            bottom: -200px;
            opacity: 0;
        }
        to {
            bottom: 0px;
            opacity: 1;
        }
    }

    @keyframes slide-out {
        from {
            bottom: 0px;
            opacity: 1;
        }
        to {
            bottom: -200px;
            opacity: 0;
        }
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
