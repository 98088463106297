import { useEffect, useMemo, useRef, useState } from 'react';

import Modal from '@/components/Modal';
import ThemedIconButton from '@/components/ThemedIconButton';
import { setIsSnackbarVisible, setShareModalOpen } from '@/state/features/app';
import { useAppDispatch, useAppSelector } from '@/state/store';
import * as styles from './styles.module.scss';
import { tunaLab } from '@/arise/api';

export default function ShareModal() {
    const dispatch = useAppDispatch();
    const { shareModalOpen, shareMessage } = useAppSelector((state) => state.app);

    const canNativeShare = useMemo(() => !!navigator.share, []);
    const [copyText, setCopyText] = useState('Copy');
    const copyTextTimeout = useRef<number>(0);

    const url = encodeURIComponent(window.location.href);
    const title = encodeURIComponent(shareMessage);
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&title=${title}`;
    const twitterUrl = `https://twitter.com/intent/tweet?url=${url}&text=${title}`;

    const locationId = window.location.pathname.split('/').pop();

    function open(url: string) {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    // Track share button click
    function trackShare(target: string) {
        tunaLab(locationId, {
            eventType: 'share_button_click',
            eventData: {
                shareTarget: target,
            },
        });
    }

    useEffect(() => {
        if (shareModalOpen) {
            tunaLab(locationId, {
                eventType: 'share_modal_open',
                eventData: {},
            });
        }
    }, [shareModalOpen]);

    return (
        <Modal
            title="Share your results"
            onClose={() => {
                dispatch(setShareModalOpen(false));
                dispatch(setIsSnackbarVisible(true));
            }}
            isVisible={shareModalOpen}
        >
            <div className={styles.buttonContainer}>
                <ThemedIconButton
                    text="Share"
                    onClick={() => {
                        trackShare('facebook');
                        open(facebookUrl);
                    }}
                    disabled={false}
                    icon="facebook"
                />
                <ThemedIconButton
                    text="Share"
                    onClick={() => {
                        trackShare('twitter');
                        open(twitterUrl);
                    }}
                    disabled={false}
                    icon="twitter"
                />
                <ThemedIconButton
                    text={copyText}
                    onClick={() => {
                        try {
                            const copyMessage = shareMessage + ' \n' + window.location.href;
                            navigator.clipboard.writeText(copyMessage);
                            setCopyText('Copied!');
                            clearTimeout(copyTextTimeout.current);
                            copyTextTimeout.current = setTimeout(() => {
                                setCopyText('Copy');
                            }, 2000) as any as number;

                            trackShare('copy');
                        } catch (error) {
                            console.error('Failed to copy: ', error);
                        }
                    }}
                    disabled={false}
                    icon="copy"
                />
                {canNativeShare && (
                    <ThemedIconButton
                        text="Share"
                        onClick={() => {
                            trackShare('native');
                            navigator.share({
                                // Current page title
                                title: window.document.title,
                                text: shareMessage,
                                url: window.location.href,
                            });
                        }}
                        icon="native"
                        disabled={false}
                    />
                )}
            </div>
        </Modal>
    );
}
