.FailedInputOverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: translateY(200%);
    transition: height 0s, transform 300ms ease-in-out;
    transition-delay: 300ms, 0s;
    backdrop-filter: blur(3px);
    border-radius: 10px;

    button {
        position: relative;
        top: 50%;
        height: 52px;
        transform: translateY(-50%);
    }
}

.visible {
    top: 50%;
    transform: translateY(-50%);
}
