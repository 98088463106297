import * as styles from './styles.module.scss';
import classNames from 'classnames';
import { pathToURL } from '@/utils/urls';
import { Avatar } from '@/models/profile';
import { transitionStates } from '@/utils/pageTransition';

interface AvatarPickerProps {
    avatars: Avatar[];
    selectedAvatarId?: string;
    onAvatarSelected?: (id: string) => void;
    onAvatarMouseEnter?: () => void;
    transitionState: transitionStates;
}

export default function AvatarPicker({
    avatars,
    selectedAvatarId,
    onAvatarSelected,
    onAvatarMouseEnter,
    transitionState = transitionStates.visible,
}: AvatarPickerProps) {
    const renderAvatars = () => {
        if (avatars && avatars.length) {
            return avatars.map((avatar) => (
                <AvatarPickerAvatar
                    key={avatar.id}
                    name={avatar.name}
                    imagePath={pathToURL(avatar.imagePath)}
                    isSelected={avatar.id === selectedAvatarId}
                    onSelected={() => onAvatarSelected && onAvatarSelected(avatar.id)}
                    onMouseEnter={onAvatarMouseEnter}
                    transitionState={transitionState}
                />
            ));
        } else {
            return 'No avatars available';
        }
    };

    return <ul className={styles.AvatarPicker}>{renderAvatars()}</ul>;
}

interface AvatarPickerAvatarProps {
    name: string;
    imagePath: string;
    isSelected?: boolean;
    onSelected: () => void;
    onMouseEnter?: () => void;
    transitionState: transitionStates;
}

function AvatarPickerAvatar({
    name,
    imagePath,
    isSelected,
    onSelected,
    onMouseEnter,
    transitionState,
}: AvatarPickerAvatarProps) {
    const avatarClasses = classNames({
        [styles.AvatarPickerAvatar]: true,
        [styles['AvatarPickerAvatar--selected']]: isSelected,
    });

    return (
        <li className={classNames(avatarClasses, styles[transitionState])}>
            <button
                className={styles.AvatarPickerAvatar__Button}
                aria-label={`Avatar: ${name}`}
                onClick={(e) => onSelected && onSelected()}
                onMouseEnter={onMouseEnter}
            >
                <div
                    className={styles.AvatarPickerAvatar__Image}
                    style={{ backgroundImage: `url(${imagePath})` }}
                ></div>
            </button>
        </li>
    );
}
