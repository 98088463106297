import { useEffect, useRef } from 'react';
import { Channel } from 'pusher-js';
import { PusherService } from '@/arise/api/pusher/PusherService';
import { updateLocation, loadLocations, reevaluateIfFeedsAreOpen } from '@/state/features/app';
import { useCurrentUserPusherSubscribe } from '@/arise/user/pusher';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { AriseLocation } from '@/models/location';
import { MAX_TIMEOUT, msTimeDifferenceFromNow } from '@/utils/time';
import { useProfilePusherSubscribe } from '../profile/pusher';
import { useUser } from '@/hooks/auth';

// Global is subscribed to at all times, rather than in a specific location
const channelName = 'private-arise-global';
const pusherMessageUpdateLocationEvent = 'client-location-update';

export function useGlobalLocationPusherSubscribe() {
    const user = useUser();
    const dispatch = useAppDispatch();
    const channelRef = useRef<Channel>();

    useEffect(() => {
        function handleLocationUpdate({ data }: { data: AriseLocation }) {
            dispatch(updateLocation(data));
        }

        channelRef.current = PusherService.subscribe(channelName);
        channelRef.current?.bind(pusherMessageUpdateLocationEvent, handleLocationUpdate);

        return () => {
            channelRef.current?.unbind(pusherMessageUpdateLocationEvent, handleLocationUpdate);
            PusherService.unsubscribe(channelName);
        };
    }, [user]);

    return {
        channelRef,
    };
}

function setOpenAndCloseTimeLocationTimeouts() {
    const dispatch = useAppDispatch();
    const locations = useAppSelector((state) => state.app.locations);

    useEffect(() => {
        let timeouts = [];
        if (locations) {
            Object.values(locations).forEach((location) => {
                // Locations
                const locationTimeUntilOpen = msTimeDifferenceFromNow(location.opensTime);
                const locationTimeUntilClose = msTimeDifferenceFromNow(location.closesTime);

                if (location.opensTime && locationTimeUntilOpen > 0 && locationTimeUntilOpen < MAX_TIMEOUT) {
                    const timeout = setTimeout(() => {
                        // Re-request location data, as closed location response contains less information
                        // New location data will then get re-processed and have isOpen set as true
                        dispatch(loadLocations());
                    }, locationTimeUntilOpen);
                    timeouts.push(timeout);
                }
                if (location.closesTime && locationTimeUntilClose > 0 && locationTimeUntilClose < MAX_TIMEOUT) {
                    const timeout = setTimeout(() => {
                        dispatch(loadLocations());
                    }, locationTimeUntilClose);
                    timeouts.push(timeout);
                }

                // Feeds
                const feedTimeUntilOpen = msTimeDifferenceFromNow(location.feedOpensTime);
                const feedTimeUntilClose = msTimeDifferenceFromNow(location.feedClosesTime);

                if (location.feedOpensTime && feedTimeUntilOpen > 0 && feedTimeUntilOpen < MAX_TIMEOUT) {
                    const timeout = setTimeout(() => {
                        dispatch(reevaluateIfFeedsAreOpen());
                    }, feedTimeUntilOpen);
                    timeouts.push(timeout);
                }
                if (location.feedClosesTime && feedTimeUntilClose > 0 && feedTimeUntilClose < MAX_TIMEOUT) {
                    const timeout = setTimeout(() => {
                        dispatch(reevaluateIfFeedsAreOpen());
                    }, feedTimeUntilClose);
                    timeouts.push(timeout);
                }
            });
        }

        return () => {
            timeouts.forEach((timeout) => {
                clearTimeout(timeout);
            });
        };
    }, [locations]);
}

export function GlobalPusherSubscribeProvider() {
    useCurrentUserPusherSubscribe();
    useGlobalLocationPusherSubscribe();
    setOpenAndCloseTimeLocationTimeouts();
    useProfilePusherSubscribe();
    return <></>;
}
