@import '../../style/globals.scss';

.DisplayName {
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;

    &.hasHover {
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}
