import { useEffect, useRef } from 'react';
import { Channel } from 'pusher-js';
import { PusherService } from '@/arise/api/pusher/PusherService';
import { useAppDispatch } from '@/state/store';
import { FeedItem, ChatEventPayload, ReactionUpdatePayload } from '@/models/feed';
import { addItem, removeItem, updateItem, setCurrentEvent, updateMessageReaction } from '@/state/features/feed';
import { useUser } from '@/hooks/auth';
import { processProfileData } from '@/utils/profile';

const pusherMessageEvent = 'client-chat-message';
const pusherMessageUpdateEvent = 'client-chat-message-update';
const pusherMessageRemovalEvent = 'client-chat-message-removal';
const pusherMessageReactionUpdateEvent = 'client-chat-reaction-update';

const pusherEventCreationEvent = 'client-event-creation';

export function usePusherSubscribe(channelName: string) {
    const dispatch = useAppDispatch();
    const channelRef = useRef<Channel>();
    const user = useUser();

    useEffect(() => {
        function handlePusherMessage(data: FeedItem) {
            // @ts-ignore
            if (data.data.profile) {
                // @ts-ignore
                data.data.profile = processProfileData(data.data.profile);
            }
            dispatch(addItem(data));
        }

        function handleUpdateMessage(data: FeedItem) {
            // @ts-ignore
            if (data.data.profile) {
                // @ts-ignore
                data.data.profile = processProfileData(data.data.profile);
            }
            dispatch(updateItem(data));
        }

        function handleRemoveMessage(data: FeedItem) {
            dispatch(removeItem(data.id));
        }

        function handleReactionUpdate(data: ReactionUpdatePayload) {
            dispatch(updateMessageReaction(data));
        }

        function handleCreateEvent(data: ChatEventPayload) {
            dispatch(setCurrentEvent(data.data));
        }

        channelRef.current = PusherService.subscribe(channelName);
        // Chat messages
        channelRef.current?.bind(pusherMessageEvent, handlePusherMessage);
        channelRef.current?.bind(pusherMessageUpdateEvent, handleUpdateMessage);
        channelRef.current?.bind(pusherMessageRemovalEvent, handleRemoveMessage);
        channelRef.current?.bind(pusherMessageReactionUpdateEvent, handleReactionUpdate);
        // Events
        channelRef.current?.bind(pusherEventCreationEvent, handleCreateEvent);

        return () => {
            // Chat messages
            channelRef.current?.unbind(pusherMessageEvent, handlePusherMessage);
            channelRef.current?.unbind(pusherMessageUpdateEvent, handleUpdateMessage);
            channelRef.current?.unbind(pusherMessageRemovalEvent, handleRemoveMessage);
            channelRef.current?.unbind(pusherMessageReactionUpdateEvent, handleReactionUpdate);
            // Events
            channelRef.current?.unbind(pusherEventCreationEvent, handleCreateEvent);
            PusherService.unsubscribe(channelName);
        };
    }, [channelName, user]);

    return {
        channelRef,
    };
}
