import { createElement } from 'react';

interface PProps extends React.HTMLProps<HTMLHeadingElement> {
    fontType?: 'primary' | 'secondary';
}

export function P({ fontType = 'primary', style = {}, ...props }: PProps) {
    return (
        <p
            {...props}
            style={{
                fontFamily: `var(--font-${fontType})`,
                fontSize: 14.5,
                ...style,
            }}
        />
    );
}
