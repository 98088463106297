import { RefObject, useEffect, useState } from 'react';

import { AriseBridgeService } from '@/arise/bridge/AriseBridgeService';

export function useAriseBridge(iframeRef: RefObject<HTMLIFrameElement>, options: any = {}) {
    const [ariseBridge, setAriseBridge] = useState(null);
    useEffect(() => {
        const allowedOrigins = options.allowedOrigins || ['*'];
        const abs = AriseBridgeService.initialise(iframeRef, allowedOrigins, options.arisewareProjectID);
        const bridge = abs.getBridge();
        setAriseBridge(bridge);
    }, [iframeRef]);
    return ariseBridge;
}
