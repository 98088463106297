import * as styles from './styles.module.scss';
import classNames from 'classnames';
import { pathToURL } from '@/utils/urls';
import { Faction } from '@/models/profile';
import { transitionStates } from '@/utils/pageTransition';

interface FactionPickerProps {
    factions: Faction[];
    factionSubtitle?: string;
    backgroundImagePath: string;
    textBackgroundImagePath: string;
    highlightImagePath?: string;
    selectedFactionId?: string;
    onFactionSelected?: (id: string) => void;
    onFactionMouseEnter?: () => void;
    transitionState: transitionStates;
}

export default function FactionPicker({
    factions,
    factionSubtitle,
    backgroundImagePath,
    textBackgroundImagePath,
    highlightImagePath,
    selectedFactionId,
    onFactionSelected,
    onFactionMouseEnter,
    transitionState = transitionStates.visible,
}: FactionPickerProps) {
    const renderFactions = () => {
        if (factions && factions.length) {
            return factions.map((faction) => (
                <FactionPickerFaction
                    key={faction.id}
                    imagePath={pathToURL(faction.imagePath)}
                    backgroundImagePath={backgroundImagePath}
                    textBackgroundImagePath={textBackgroundImagePath}
                    highlightImagePath={highlightImagePath}
                    title={faction.name}
                    subtitle={factionSubtitle}
                    isSelected={faction.id === selectedFactionId}
                    onSelected={() => onFactionSelected && onFactionSelected(faction.id)}
                    onMouseEnter={onFactionMouseEnter}
                    transitionState={transitionState}
                />
            ));
        } else {
            return 'No factions available';
        }
    };

    return (
        <div className={styles.FactionPicker}>
            <ul>{renderFactions()}</ul>
        </div>
    );
}

interface FactionPickerFactionProps {
    imagePath: string;
    backgroundImagePath: string;
    textBackgroundImagePath: string;
    title: string;
    subtitle?: string;
    highlightImagePath?: string;
    isSelected?: boolean;
    onSelected?: () => void;
    onMouseEnter?: () => void;
    transitionState: transitionStates;
}

function FactionPickerFaction({
    imagePath,
    backgroundImagePath,
    textBackgroundImagePath,
    highlightImagePath,
    title,
    subtitle,
    isSelected,
    onSelected,
    onMouseEnter,
    transitionState,
}: FactionPickerFactionProps) {
    const factionButtonClasses = classNames({
        [styles.FactionPickerFaction__Button]: true,
        [styles['FactionPickerFaction__Button--selected']]: isSelected,
    });

    const titleFontSize = title.length < 12 ? '15px' : '10px'; // TODO - Update when font changes and also find a better method
    const highlightImageStyle = highlightImagePath ? { backgroundImage: `url(${highlightImagePath})` } : {};

    return (
        <li className={classNames(styles.FactionPickerFaction, styles[transitionState])} onMouseEnter={onMouseEnter}>
            <button className={factionButtonClasses} onClick={() => onSelected && onSelected()}>
                <div
                    className={styles.FactionPickerFaction__BackgroundContainer}
                    style={{ backgroundImage: `url(${backgroundImagePath})` }}
                ></div>
                <div className={styles.FactionPickerFaction__Border}></div>
                <img src={imagePath} className={styles.FactionPickerFaction__Image} alt="faction logo" />
                <div
                    className={styles.FactionPickerFaction__Text}
                    style={{ backgroundImage: `url(${textBackgroundImagePath})` }}
                >
                    <p className={styles.FactionPickerFaction__Title} style={{ fontSize: titleFontSize }}>
                        {title}
                    </p>
                    <p className={styles.FactionPickerFaction__Subtitle}>{subtitle}</p>
                </div>
            </button>
            <div className={styles.FactionPickerFaction__HighlightImage} style={highlightImageStyle}></div>
        </li>
    );
}
