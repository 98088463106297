import * as styles from './styles.module.scss';
import classNames from 'classnames';

interface AuthDrawerProps extends React.HTMLAttributes<HTMLDivElement> {
    width: authDrawerWidth;
    imagePath?: string;
    imageColour?: string;
    animationDurationMs?: number;
    children?: React.ReactNode;
}

export enum authDrawerWidth {
    none,
    small,
    mediumContent,
    largeContent,
    full,
}

const enumToClass: Record<authDrawerWidth, string> = {
    [authDrawerWidth.none]: styles['AuthDrawer__drawer--none'],
    [authDrawerWidth.small]: styles['AuthDrawer__drawer--small'],
    [authDrawerWidth.mediumContent]: styles['AuthDrawer__drawer--mediumContent'],
    [authDrawerWidth.largeContent]: styles['AuthDrawer__drawer--largeContent'],
    [authDrawerWidth.full]: styles['AuthDrawer__drawer--full'],
};

export function AuthDrawer({
    imagePath,
    width,
    imageColour = 'black',
    animationDurationMs = 1000,
    children,
    ...props
}: AuthDrawerProps) {
    const classes = classNames({
        [styles.AuthDrawer]: true,
        [styles['AuthDrawer--noImage']]: !imagePath,
    });

    const drawerRightStyles = imagePath ? { backgroundImage: `url(${imagePath})` } : {};

    return (
        <div className={classes} {...props}>
            <div
                className={styles.AuthDrawer__drawer + ' ' + enumToClass[width]}
                style={{
                    transitionDuration: `${animationDurationMs}ms`,
                }}
            >
                <div className={styles.AuthDrawer__contentLine}></div>
                <div className={styles.AuthDrawer__drawerLeft} style={{ backgroundColor: imageColour }}></div>
                <div className={styles.AuthDrawer__drawerRight} style={drawerRightStyles}></div>
                {children}
            </div>
        </div>
    );
}
