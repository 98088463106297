export function initaliseGA(id: string) {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    script.async = true;
    document.head.appendChild(script);
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    function gtag(a: any, b: any) {
        // @ts-ignore
        window.dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', id);

    // @ts-ignore
    window.gtag = gtag;
}

export function sendEvent(name: string, data: any) {
    // @ts-ignore
    if (window.gtag) {
        // @ts-ignore
        window.gtag('event', name, data);
    }
}
