import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FullpageWrapper from '@/components/FullpageWrapper';
import AnimatedFullscreenText from '@/components/AnimatedFullscreenText';
import SceneSelector from '@/components/SceneSelector';
import LoadingScreen from '@/components/LoadingScreen';

import { useAppSelector, useAppDispatch } from '@/state/store';
import { loadLocations, loadSceneSelectorData, setHasFailed, setHasWorldIntroPlayed } from '@/state/features/app';
import { useLocations } from '@/hooks/location';
import { getLocationOverrides } from '@/utils/locationOverrides';
import { useTheme } from '@/theme';
import { useAudio } from '@/hooks/audio';
import { transitionStates, pageTransitionTime } from '@/utils/pageTransition';
import { LS_HASSEENWORLDINTRO_KEY } from '@/globals';

export default function SceneSelectorPage() {
    const { isLoading: areLocationsLoading, locations } = useLocations();
    const theme = useTheme();
    const { audio } = useAudio();
    const { hasWorldIntroPlayed, sceneSelectorData } = useAppSelector((state) => state.app);
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [introTransitionState, setIntroTransitionState] = useState(transitionStates.preVisible);
    const [selectorTransitionState, setSelectorTransitionState] = useState(transitionStates.preVisible);
    const [areParallaxImagesLoaded, setAreParallaxImagesLoaded] = useState(false);
    const [locationOverridesData, setLocationOverridesData] = useState(null);

    const shouldShowLoadingScreen = !theme.themeId || !sceneSelectorData || !locations;

    useEffect(() => {
        dispatch(loadSceneSelectorData());
        if (areLocationsLoading) return;
        dispatch(loadLocations());

        // We set has failed to false here to reset the state if the user navigates back to this page.
        // Since the death state is only shown for 5 to 10 seconds it's not an issue if someone tries to circumvent it.
        dispatch(setHasFailed(false));
    }, []);

    useEffect(() => {
        // Only show intro to user once
        const hasSeenWorldIntro = hasWorldIntroPlayed || window.localStorage.getItem(LS_HASSEENWORLDINTRO_KEY);
        if (hasSeenWorldIntro) {
            dispatch(setHasWorldIntroPlayed(true));
        } else {
            setIntroTransitionState(transitionStates.visible);
        }
    }, []);

    useEffect(() => {
        if (hasWorldIntroPlayed) {
            setIntroTransitionState(transitionStates.postVisible);
        }
    }, [hasWorldIntroPlayed]);

    useEffect(() => {
        if (theme.loaded && !theme.customJSON.worldIntroductionText) {
            // If no CMS for data for introduction, move to next step
            dispatch(setHasWorldIntroPlayed(true));
        }
    }, [theme]);

    useEffect(() => {
        if (hasWorldIntroPlayed && !areLocationsLoading && areParallaxImagesLoaded && !shouldShowLoadingScreen) {
            setSelectorTransitionState(transitionStates.visible);
        }
    }, [areLocationsLoading, areParallaxImagesLoaded, hasWorldIntroPlayed, shouldShowLoadingScreen]);

    const environmentIDs = sceneSelectorData?.scenes.map((scene) => scene.environmentID);

    useEffect(() => {
        if (environmentIDs?.length) {
            getLocationOverrides(environmentIDs).then((overrides) => {
                // Store result in component state
                setLocationOverridesData(overrides);
            });
        }
    }, [sceneSelectorData]);

    const scenesData = useMemo(() => {
        if (!locations) return [];
        return sceneSelectorData?.scenes.map((scene) => {
            const matchingLocation = locations[scene.environmentID];
            return {
                ...scene,
                isOpen: matchingLocation?.isOpen && !locationOverridesData?.[scene?.environmentID]?.isLockedOut,
            };
        });
    }, [locations, sceneSelectorData, locationOverridesData]);

    const navigateToScene = (locationId: string) => {
        // Allow superadmins to access location when closed
        if (
            (locations[locationId]?.isOpen && !locationOverridesData?.[locationId].isLockedOut) ||
            currentUserProfile?.role === 'superadmin'
        ) {
            setSelectorTransitionState(transitionStates.postVisible);
            audio.play('slam');
            setTimeout(() => {
                navigate('/location/' + locationId);
            }, pageTransitionTime);
        }
    };

    if (shouldShowLoadingScreen) return <LoadingScreen show={true} />;

    return (
        <FullpageWrapper>
            <SceneSelector
                transitionState={selectorTransitionState}
                scenes={scenesData}
                sceneSelectorSettings={sceneSelectorData}
                onSceneSelected={(locationId) => navigateToScene(locationId)}
                onParallaxLoaded={() => setAreParallaxImagesLoaded(true)}
            />
            <AnimatedFullscreenText
                titles={theme.customJSON.worldIntroductionText}
                transitionState={introTransitionState}
                onComplete={() => {
                    dispatch(setHasWorldIntroPlayed(true));
                    window.localStorage.setItem(LS_HASSEENWORLDINTRO_KEY, 'true');
                }}
            />
        </FullpageWrapper>
    );
}
