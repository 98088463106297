@import '../../style/globals.scss';

.Achievement {
    position: relative;
    display: flex;
    width: 360px;
    min-height: 60px;
    background: none;
    font-size: $font-size-p;
    font-weight: 500;

    .background {
        // Background is separate to allow its width to animate without affecting the content
        position: absolute;
        left: 50%;
        width: 70%;
        height: 100%;
        background-color: var(--token-accent-theme-1-interface-interface-midground);
        border-radius: 30px;
        transition: width 400ms ease-in-out;
        transform: translateX(-50%);
    }

    &.visible {
        .background {
            width: 100%;
            transition: width 400ms $animation-overshoot;
        }
    }

    .label {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 42px;
        margin: 8px 40px 8px 69px;
        color: var(--token-accent-theme-1-text-text-default);

        .highlighted {
            color: var(--token-accent-theme-1-text-text-default);
        }

        .hasLineBreak {
            display: block;
        }
    }

    .icon {
        position: absolute;
        top: 5px;
        left: 5px;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background-color: var(--token-accent-theme-1-interface-interface-foreground);
        overflow: hidden;
    }

    &.danger {
        .background {
            background-color: var(--token-arise-theme-1-state-state-error-mid);
        }

        .icon {
            background-color: var(--token-arise-theme-1-state-state-error-light);
        }

        .highlighted {
            color: var(--token-arise-theme-1-state-state-error-light);
        }
    }

    &.success {
        .background {
            background-color: var(--token-arise-theme-1-state-state-success-mid);
        }

        .icon {
            background-color: var(--token-arise-theme-1-state-state-success-light);
        }

        .highlighted {
            color: var(--token-arise-theme-1-state-state-success-light);
        }
    }
}
