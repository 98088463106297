import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/state/store';
import { useTheme } from '@/theme';
import { transitionStates } from '@/utils/pageTransition';
import { pathToURL } from '@/utils/urls';
import { useInitialAuthCheck } from '@/hooks/auth';
import Button from '@/components/Forms/Button';
import AvatarPicker from '@/components/AvatarPicker';
import FactionPicker from '@/components/FactionPicker';

import * as styles from './styles.module.scss';

import {
    loadCurrentUserProfile,
    loadAvailableAvatars,
    loadAvailableFactions,
    loadFactionSettings,
    createProfile,
    editProfile,
    resetProfileCreationStatus,
} from '@/state/features/profile';
import { useAudio } from '@/hooks/audio';
import { sendEvent } from '@/utils/analytics';
import { mapPath } from '@/theme/vars';

export default function EditProfilePage() {
    const [selectedAvatarId, setSelectedAvatarId] = useState('');
    const [selectedFactionId, setSelectedFactionId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [currentStep, setCurrentStep] = useState(0);
    const [pageTransitionState, setPageTransitionState] = useState(transitionStates.preVisible);
    const [factionPickerTransitionState, setFactionPickerTransitionState] = useState(transitionStates.preVisible);
    const [avatarPickerTransitionState, setAvatarPickerTransitionState] = useState(transitionStates.preVisible);
    const [isLoaded, setIsLoaded] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const profile = useAppSelector((state) => state.profile);
    const { user, loggedIn } = useAppSelector((state) => state.auth);

    const { audio } = useAudio();

    useInitialAuthCheck();

    useEffect(() => {
        audio.playOnly(['faction-selector-bg', 'drone-bg']);
    }, []);

    function playRandomDrum() {
        const random = Math.random();
        if (random < 0.5) {
            audio?.play('drum');
        } else {
            audio?.play('drum2');
        }
    }

    function onContinue() {
        audio?.play('button-click');
        audio?.play('gust-open');
        setErrorMessage('');
        setFactionPickerTransitionState(transitionStates.postVisible);
        setTimeout(() => {
            setCurrentStep(2);
            setTimeout(() => {
                setAvatarPickerTransitionState(transitionStates.visible);
            }, 10);
        }, 1200);
    }

    function onSubmit() {
        // For now, set display name to be username
        audio?.play('button-click');
        if (!user || !selectedAvatarId || !selectedFactionId) return;

        audio?.play('slam');

        if (profile.currentUserProfile) {
            dispatch(editProfile([profile.currentUserProfile.id, user.username, selectedAvatarId, selectedFactionId]));
            sendEvent('profile_update', { avatar: selectedAvatarId, faction: selectedFactionId });
        } else {
            dispatch(createProfile([user.username, selectedAvatarId, selectedFactionId]));
            sendEvent('profile_create', { avatar: selectedAvatarId, faction: selectedFactionId });
        }
    }

    function onHover() {
        audio?.play('hover');
    }

    function navigateToSelector() {
        setAvatarPickerTransitionState(transitionStates.postVisible);
        setPageTransitionState(transitionStates.postVisible);
        setTimeout(() => {
            dispatch(resetProfileCreationStatus());
            navigate(mapPath);
            audio.play('wipe-dark1');
        }, 1200);
    }

    useEffect(() => {
        if (!profile.avatars && !profile.areAvatarsLoading) dispatch(loadAvailableAvatars());
        if (!profile.factions && !profile.areFactionsLoading) dispatch(loadAvailableFactions());
        if (!profile.factionSettings && !profile.areFactionSettingsLoading) dispatch(loadFactionSettings());
    }, []);

    useEffect(() => {
        if (!isLoaded && loggedIn && user && profile.avatars && profile.factions && profile.factionSettings) {
            setIsLoaded(true);
            setTimeout(() => {
                setCurrentStep(1);
                setTimeout(() => {
                    setFactionPickerTransitionState(transitionStates.visible);
                    setPageTransitionState(transitionStates.visible);
                }, 10);
            }, 100);
        }
    }, [loggedIn, user, profile]);

    useEffect(() => {
        if (user && !profile.currentUserProfileCheckInProgress) dispatch(loadCurrentUserProfile([user.id]));
    }, [user]);

    useEffect(() => {
        if (profile.currentUserProfile) {
            setSelectedAvatarId(profile.currentUserProfile.avatarID);
            setSelectedFactionId(profile.currentUserProfile.factionID);
        }
    }, [profile.currentUserProfile]);

    useEffect(() => {
        if (profile.isCreateProfileSuccessful) {
            if (!profile.currentUserProfile) {
                dispatch(loadCurrentUserProfile([user.id]));
            } else {
                navigateToSelector();
            }
        }
    }, [profile.isCreateProfileSuccessful, profile.currentUserProfile]);

    useEffect(() => {
        if (profile.isEditProfileSuccessful) {
            navigateToSelector();
        }
    }, [profile.isEditProfileSuccessful]);

    useEffect(() => {
        if (profile.createProfileError) {
            setErrorMessage(profile.createProfileError);
        }
    }, [profile.createProfileError]);

    useEffect(() => {
        if (profile.editProfileError) {
            setErrorMessage(profile.editProfileError);
        }
    }, [profile.editProfileError]);

    // TODO: loading
    if (!isLoaded) return <div></div>;

    function renderPicker() {
        switch (currentStep) {
            case 1:
                return (
                    <FactionPicker
                        factions={profile.factions}
                        factionSubtitle={profile.factionSettings.factionCardSubtitle}
                        backgroundImagePath={pathToURL(profile.factionSettings.factionCardBackgroundImagePath)}
                        textBackgroundImagePath={pathToURL(profile.factionSettings.factionCardTextBackgroundImagePath)}
                        highlightImagePath={pathToURL(profile.factionSettings.factionCardHighlightImagePath)}
                        selectedFactionId={selectedFactionId}
                        onFactionSelected={(factionId) => {
                            playRandomDrum();
                            setSelectedFactionId(factionId);
                            setErrorMessage('');
                        }}
                        onFactionMouseEnter={onHover}
                        transitionState={factionPickerTransitionState}
                    />
                );
            case 2:
                return (
                    <div>
                        <AvatarPicker
                            avatars={profile.avatars}
                            selectedAvatarId={selectedAvatarId}
                            onAvatarSelected={(id) => {
                                playRandomDrum();
                                setSelectedAvatarId(id);
                                setErrorMessage('');
                            }}
                            onAvatarMouseEnter={onHover}
                            transitionState={avatarPickerTransitionState}
                        />
                    </div>
                );
            default:
                return <></>;
        }
    }

    function renderButton() {
        switch (currentStep) {
            case 1:
                return (
                    <Button
                        className={styles.authNextStepButton}
                        onClick={onContinue}
                        onSubmit={onContinue}
                        disabled={!selectedFactionId}
                    >
                        Continue
                    </Button>
                );
            case 2:
                return (
                    <Button
                        className={styles.authNextStepButton}
                        onClick={onSubmit}
                        onSubmit={onSubmit}
                        disabled={!selectedAvatarId || profile.isCreatingProfile || profile.isEditingProfile}
                    >
                        Continue
                    </Button>
                );
            default:
                return <></>;
        }
    }

    // TODO - Get avatar title from CMS
    return (
        <div
            className={classNames(styles.editProfilePage, styles['step' + currentStep], styles[pageTransitionState], {
                [styles.loaded]: isLoaded,
            })}
            style={{
                backgroundImage: `url(${theme.customJSON.editProfileBackgroundImage})`,
            }}
        >
            <div className={styles.editProfilePage__pickerContainer}>
                <h2 className={styles.editProfilePage__pickerContainerTitle}>
                    {currentStep === 1 ? profile.factionSettings.factionPickerTitle : 'Choose your avatar'}
                </h2>
                {renderPicker()}
                <div className={styles.editProfilePage__bottom}>
                    {renderButton()}
                    <p className={styles.editProfilePage__errorMessage}>{errorMessage}</p>
                </div>
            </div>
        </div>
    );
}
