import { useEffect, useMemo } from 'react';
import classNames from 'classnames';

import { ChatEvent, ChatEventParticipant } from '@/models/feed';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { convertNumberToOrdinal } from '@/utils/string';
import { fetchEventById } from '@/state/features/feed';

import * as styles from './styles.module.scss';

function getUserPosition(
    successfulParticipants: ChatEventParticipant[],
    currentUserParticipantData: ChatEventParticipant,
) {
    let completionTimes = successfulParticipants.map((participant) => ({
        id: participant.profile.userID,
        completionTime: new Date(participant.eventData.completionTime).getTime(),
    }));

    // Sort participants based on completion time (earliest first)
    completionTimes.sort((a, b) => a.completionTime - b.completionTime);

    // Find the position of the current user
    let position = completionTimes.findIndex((user) => user.id === currentUserParticipantData.profile.userID) + 1;
    return position;
}

function getMostSuccessfulFaction(successfulParticipants: ChatEventParticipant[]): string {
    const factionTimes: { [key: string]: number[] } = {};
    const factionCounts: { [key: string]: number } = {};

    // Collect completion times and count successes for each faction
    successfulParticipants.forEach((participant) => {
        const factionName = participant.profile.faction.name;

        if (factionName) {
            const completionDate = new Date(participant.eventData.completionTime).getTime();
            if (!factionTimes[factionName]) {
                factionTimes[factionName] = [];
            }
            factionTimes[factionName].push(completionDate);

            if (!factionCounts[factionName]) {
                factionCounts[factionName] = 0;
            }
            factionCounts[factionName]++;
        }
    });

    // Find the maximum count of successful completions
    const maxCount = Math.max(...Object.values(factionCounts));

    // Find factions with the maximum count of successful completions
    const topFactions = Object.entries(factionCounts)
        .filter(([faction, count]) => count === maxCount)
        .map(([faction]) => faction);

    // If there's only one top faction, return it
    if (topFactions.length === 1) {
        return topFactions[0];
    }

    // Calculate average completion time for factions with the maximum count
    const factionAvgTimes: { [key: string]: number } = {};
    topFactions.forEach((faction) => {
        const times = factionTimes[faction];
        const totalCompletionTime = times.reduce((total, time) => total + time, 0);
        const avgCompletionTime = totalCompletionTime / times.length;
        factionAvgTimes[faction] = avgCompletionTime;
    });

    // Find the faction with the quickest average completion time
    let topFaction = topFactions[0];
    let bestAvgTime = factionAvgTimes[topFaction];

    topFactions.forEach((faction) => {
        if (factionAvgTimes[faction] < bestAvgTime) {
            topFaction = faction;
            bestAvgTime = factionAvgTimes[faction];
        }
    });

    return topFaction;
}

function EventSummary({ participants }: { participants: ChatEventParticipant[] }) {
    // TODO make dynamic to theme
    const backgroundImagePath = '/assets/themes/titan/images/events/event-summary-image1.webp';

    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);
    const currentUserParticipantData: ChatEventParticipant = participants.find(
        (participant) => participant.profile.userID === currentUserProfile?.userID,
    );

    const successfulParticipants = participants.filter((participant) => participant.eventData.success);
    const failedParticipants = participants.filter((participant) => !participant.eventData.success);

    const mostSuccessfulFaction = useMemo(() => getMostSuccessfulFaction(successfulParticipants), [participants]);

    const [userParticipated, userPosition] = useMemo(() => {
        if (currentUserParticipantData?.eventData.success === true) {
            return [true, convertNumberToOrdinal(getUserPosition(successfulParticipants, currentUserParticipantData))];
        } else {
            return [false, 0];
        }
    }, [participants, currentUserParticipantData]);

    const getHeading = () => {
        if (!participants.length) {
            return 'Event ended';
        }
        if (currentUserParticipantData?.eventData.success === true) {
            return 'You Survived!';
        }
        if (currentUserParticipantData?.eventData.success === false) {
            return 'You failed to Survive!';
        }
        return 'Event Summary';
    };

    const getUserPlaced = () => {
        if (userParticipated) {
            return (
                <p className={styles.bold}>
                    You placed <span className={styles.successHighlight}>{userPosition}.</span>
                </p>
            );
        }
        return null;
    };

    const getStyle = () => {
        if (currentUserParticipantData?.eventData.success === true) {
            return 'success';
        }
        if (currentUserParticipantData?.eventData.success === false) {
            return 'failed';
        }
        return 'default';
    };

    return (
        <div className={classNames(styles.EventSummaryMessage, styles[getStyle()])}>
            <div className={styles.content}>
                <div className={styles.contentCenter}>
                    <span className={styles.heading}>{getHeading()}</span>
                    {participants.length > 0 && (
                        <>
                            <div className={styles.text}>
                                {getUserPlaced()}
                                <p className={styles.bold}>
                                    <span className={styles.failedHighlight}>{failedParticipants.length} failed</span>{' '}
                                    to Survive.
                                </p>
                                {mostSuccessfulFaction && (
                                    <p>
                                        Top Survivors were <span className={styles.bold}>{mostSuccessfulFaction}.</span>
                                    </p>
                                )}
                                {!mostSuccessfulFaction && (
                                    <p>
                                        There were <span className={styles.bold}>no Survivors!</span>
                                    </p>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className={styles.image} style={{ backgroundImage: `url(${backgroundImagePath})` }}></div>
        </div>
    );
}

export default function EventSummaryMessage(event: ChatEvent) {
    const dispatch = useAppDispatch();
    const storedEvents = useAppSelector((state) => state.feed.storedEvents);
    const storedEvent = storedEvents?.[event.eventID];
    const participants: ChatEventParticipant[] = event?.participants || storedEvent?.data?.eventData?.participants;

    useEffect(() => {
        // If no participants, and not already fetched, fetch them from API
        if (!participants && !storedEvent?.lastFetched) {
            dispatch(fetchEventById(event.eventID));
        }
    }, [event.eventID]);

    return <EventSummary participants={participants || []} />;
}
