import * as styles from './styles.module.scss';
import classNames from 'classnames';
import Input, { InputProps } from '../Input';

export interface AuthInputProps extends InputProps {
    isInvalid?: boolean;
    errorAnimationDurationMs?: number;
    style?: React.CSSProperties;
}

export default function AuthInput({ isInvalid, errorAnimationDurationMs = 500, style, ...props }: AuthInputProps) {
    const authInputClasses = classNames({
        [styles.AuthInput]: true,
        [styles['AuthInput--invalid']]: isInvalid,
        [props.className]: true,
    });

    return (
        <Input
            {...props}
            className={authInputClasses}
            style={{ ...style, animationDuration: errorAnimationDurationMs + 'ms' }}
        />
    );
}
