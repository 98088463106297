import classNames from 'classnames';

import { Link } from 'react-router-dom';
import * as styles from './styles.module.scss';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    style?: React.CSSProperties;
    className?: string;
}
export default function Button({ children, onClick, style, className, ...props }: ButtonProps) {
    return (
        <button className={classNames(className, styles.Button)} style={style} onClick={onClick} {...props}>
            {children}
        </button>
    );
}

export interface LinkButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    children?: string;
    style?: React.CSSProperties;
    className?: string;
    href?: string;
}
export function LinkButton({ children, onClick, style, className, href, ...props }: LinkButtonProps) {
    return (
        <Link className={classNames(className, styles.Button)} style={style} to={href} {...props}>
            {children}
        </Link>
    );
}
