@import '../../style/globals.scss';

$whole-page-transition-duration: 1000ms;
$first-title-transition-duration: 2000ms;
$subsequent-title-transition-duration: 300ms;

.AnimatedFullscreenText {
    position: relative;
    z-index: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--token-accent-theme-1-interface-interface-black);
    transition-property: opacity;
    transition-duration: $whole-page-transition-duration;
    transition-timing-function: ease-in-out;

    &.page-transition-preVisible {
        pointer-events: none;
        opacity: 1;
    }
    &.page-transition-visible {
        opacity: 1;
    }
    &.page-transition-postVisible {
        pointer-events: none;
        opacity: 0;
    }

    .title {
        position: absolute;
        color: var(--token-accent-theme-1-text-text-active);
        font-family: var(--font-primary);
        font-size: $font-size-h2;
        transition-property: opacity, transform;
        transition-duration: $subsequent-title-transition-duration;
        white-space: nowrap;

        @media (max-width: $bp-mobile-chat) {
            font-size: $font-size-h3;
        }

        &.page-transition-preVisible {
            pointer-events: none;
            opacity: 0;
            transform: translateY(100px);
        }
        &.page-transition-visible {
            opacity: 1;
            transform: translateY(0);
        }
        &.page-transition-postVisible {
            pointer-events: none;
            opacity: 0;
            transform: translateY(-100px);
        }

        &:first-child {
            &.page-transition-preVisible {
                transition: opacity $first-title-transition-duration linear;
                opacity: 0;
            }

            &.page-transition-visible {
                transition: opacity $first-title-transition-duration linear;
                opacity: 1;
            }
            &.page-transition-postVisible {
                transition-duration: $subsequent-title-transition-duration;
            }

            span {
                display: inline-block;
                white-space: pre;
                animation: moveUp 1500ms forwards;
            }
        }
    }

    .highlighted {
        color: var(--token-accent-theme-1-brand-colour);
    }
}

@keyframes moveUp {
    0% {
        transform: translateY(150px);
    }
    100% {
        transform: translateY(0);
    }
}
