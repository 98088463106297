@import '../../style/globals.scss';

.Sidebar {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
    // Needed for safari
    -webkit-backdrop-filter: blur(10px);
}

.top {
    height: $header-height;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 50;

    h1,
    h2 {
        text-align: center;
        text-transform: uppercase;
        font-family: var(--font-secondary);
        font-size: $font-size-h6;
        line-height: normal;
    }

    h1 {
        color: var(--token-accent-theme-1-text-text-active);
    }
    h2 {
        color: var(--token-accent-theme-1-brand-colour);
    }
}

.openButton {
    position: absolute;
    z-index: 10;
    left: -$header-height;
    display: block;
    transition: left 500ms $animation-bounce;

    &.isOpen {
        @media (max-width: $bp-mobile-chat) {
            left: 0;
        }
    }

    &.shouldFlash {
        &.isOpen {
            animation: none;

            svg {
                animation: none;
            }
        }

        @media (max-width: $bp-mobile-chat) {
            animation: button-bg 2s steps(2) infinite;

            @keyframes button-bg {
                0% {
                    background-color: var(--token-accent-theme-1-button-button-highlight);
                }
                100% {
                    background-color: var(--token-accent-theme-1-button-button-default);
                }
            }

            svg {
                animation: icon-bounce 2s ease-in-out infinite;

                @keyframes icon-bounce {
                    0% {
                        -webkit-transform: translateY(0);
                    }
                    15% {
                        -webkit-transform: translateY(-15px);
                    }
                    20% {
                        -webkit-transform: translateY(-15px);
                    }
                    35% {
                        -webkit-transform: translateY(-6px);
                    }
                    40% {
                        -webkit-transform: translateY(4px);
                    }
                    50% {
                        -webkit-transform: translateY(-3px);
                    }
                    55% {
                        -webkit-transform: translateY(1px);
                    }
                    60% {
                        -webkit-transform: translateY(0);
                    }
                }
            }
        }
    }
}

.bottom {
    height: calc(100% - $header-height);
    position: relative;
}

.infobar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 400px;
    padding: 20px;
    // Top to bottom fade out gradient
    background: linear-gradient(0deg, transparent 0%, var(--token-accent-theme-1-interface-interface-black) 100%);
    pointer-events: none;

    @media (max-height: 900px) {
        height: 200px;
    }

    @media (max-height: 700px) {
        height: 70px;
    }
}

.feedContainer {
    width: 100%;
    height: 100%;
}
