@import '../../style/globals.scss';

.DisplayNameWithBadges {
    .displayName {
        display: inline-block;
        margin-right: 10px;
    }

    .badgeContainer {
        display: inline-block;

        .titleBadge {
            margin-right: 10px;
        }
    }
}
