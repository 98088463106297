@import '../../style/globals.scss';

$fadeIn-duration: 1500ms;
$moveUp-duration: 1000ms;

.locationIntroduction {
    position: absolute;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 60px;
    background-color: var(--token-accent-theme-1-interface-interface-black);
    color: var(--token-accent-theme-1-text-text-default);
    text-align: center;
    font-family: var(--font-primary);
    animation-timing-function: ease-out;

    @media (max-width: $bp-mobile-chat) {
        padding-bottom: 10px;
    }

    &__title {
        position: relative;
        display: block;
        margin: auto;
        padding: 10px;
        font-size: $font-size-h2;
        line-height: normal;
        text-transform: uppercase;
        animation: moveUp $moveUp-duration, fadeIn $fadeIn-duration;

        @media (max-width: $bp-mobile-chat) {
            font-size: $font-size-h3;
        }

        &Prefix {
            color: var(--token-accent-theme-1-brand-colour);
        }

        &Text {
            color: var(--token-accent-theme-1-text-text-default);
        }
    }

    &__text {
        position: relative;
        display: block;
        width: 430px;
        max-width: 100%;
        padding: 10px;
        font-size: $font-size-h6;
        animation: moveUp $moveUp-duration, fadeIn $fadeIn-duration;
    }

    &__image {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50%;
        background-position: center bottom;
        background-size: auto 100%;
        background-repeat: no-repeat;
        animation: moveUpFaster $moveUp-duration, fadeIn $fadeIn-duration;
    }

    &__soundWarning {
        color: var(--token-accent-theme-1-brand-colour);
        font-size: $font-size-h6;
        text-transform: none;

        svg {
            margin-right: 8px;
            margin-bottom: 3px;
            path {
                fill: var(--token-accent-theme-1-brand-colour);
            }
        }
    }

    @keyframes moveUp {
        0% {
            transform: translateY(150px);
        }
        100% {
            transform: translateY(0);
        }
    }

    @keyframes moveUpFaster {
        0% {
            transform: translateY(300px);
        }
        100% {
            transform: translateY(0);
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
