import { useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import classNames from 'classnames';

import IconButton from '@/components/IconButton';
import { MAX_TIMEOUT, msTimeDifferenceFromNow } from '@/utils/time';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { queueAchievement, setIsProfileOpen } from '@/state/features/app';
import { clearCurrentEvent, setCurrentEventIsActive } from '@/state/features/feed';
import { ChatEventParticipant } from '@/models/feed';
import { useAudio } from '@/hooks/audio';

import EventTimer, { EventTimerStatus } from '../EventTimer';
import * as styles from './styles.module.scss';

// How long event UI continues to be visible once event has finished
const EXTRA_VIEWING_TIME_MS = 4000;
// Animation in and out duration
const ANIMATION_DURATION_MS = 200;
// Counts down from 3 before event begins
const PRECOUNTDOWN_LENGTH_MS = 3000;

export default function SidebarEvent() {
    const containerRef = useRef<HTMLDivElement>(null);
    const { audio } = useAudio();
    const dispatch = useAppDispatch();
    const currentEvent = useAppSelector((state) => state.feed.currentEvent);
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);
    const isSidebarOpen = useAppSelector((state) => state.app.isSidebarOpen);
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isPreCountdown, setIsPreCountdown] = useState(false);

    const currentUserParticipantData: ChatEventParticipant = currentEvent?.participants?.find(
        (participant) => participant.profile.userID === currentUserProfile?.userID,
    );

    const stopAllEventSounds = () => {
        audio.stop('event__countdown');
        audio.stop('event__start');
        audio.stop('event__success');
        audio.stop('event__fail');
    };

    useEffect(() => {
        // If user entered an incorrect answer
        if (currentUserParticipantData?.eventData?.success === null) {
            setIsIncorrect(true);
            setTimeout(() => setIsIncorrect(false), 500);
        }
    }, [currentUserParticipantData?.lastUpdated]);

    useEffect(() => {
        // Play countdown / start sound
        if (isVisible) {
            stopAllEventSounds();
            if (isPreCountdown) {
                audio.play('event__countdown');
            } else {
                audio.play('event__start');
            }
        }

        return () => {
            audio.stop('event__countdown');
            audio.stop('event__start');
        };
    }, [isPreCountdown]);

    useEffect(() => {
        // Play success / fail sound
        if (currentUserParticipantData?.eventData?.success === true) {
            stopAllEventSounds();
            audio.play('event__success');
        }
        if (currentUserParticipantData?.eventData?.success === false) {
            stopAllEventSounds();
            audio.play('event__fail');
        }
        return () => {
            audio.stop('event__success');
            audio.stop('event__fail');
        };
    }, [currentUserParticipantData?.eventData?.success]);

    useEffect(() => {
        // Set timeouts for pre-countdown, start and end of event
        let timeouts = [];

        if (currentEvent) {
            const timeUntilEventStarts = msTimeDifferenceFromNow(currentEvent.startTime);
            const timeUntilCountdownShows = timeUntilEventStarts - (PRECOUNTDOWN_LENGTH_MS + 500);
            const timeUntilEventEnds = msTimeDifferenceFromNow(currentEvent.endTime);

            if (timeUntilCountdownShows > 0 && timeUntilCountdownShows < MAX_TIMEOUT) {
                const timeout = setTimeout(() => {
                    setIsVisible(true);
                    setIsPreCountdown(true);
                    dispatch(setCurrentEventIsActive(true));
                    dispatch(setIsProfileOpen(false));

                    if (!isSidebarOpen) {
                        dispatch(
                            queueAchievement({
                                actionPrefix: 'An Event is starting',
                                action: 'Head to chat now',
                                type: 'danger',
                                icon: 'death',
                                hasLineBreak: true,
                            }),
                        );
                    }
                }, timeUntilCountdownShows);
                timeouts.push(timeout);
            }

            if (timeUntilEventStarts > 0 && timeUntilEventStarts < MAX_TIMEOUT) {
                const timeout = setTimeout(() => {
                    setIsPreCountdown(false);
                }, timeUntilEventStarts);
                timeouts.push(timeout);
            }

            if (timeUntilEventEnds > 0 && timeUntilEventEnds < MAX_TIMEOUT) {
                const endOfEventTimeout = setTimeout(() => {
                    dispatch(setCurrentEventIsActive(false));
                }, timeUntilEventEnds);
                timeouts.push(endOfEventTimeout);

                const eventHidesTimeout = setTimeout(() => {
                    setIsVisible(false);
                    setTimeout(() => {
                        dispatch(clearCurrentEvent());
                    }, ANIMATION_DURATION_MS + 10);
                }, timeUntilEventEnds + EXTRA_VIEWING_TIME_MS);
                timeouts.push(eventHidesTimeout);
            }
        }

        return () => {
            timeouts.forEach((timeout) => {
                clearTimeout(timeout);
            });
        };
    }, [currentEvent?.startTime, currentEvent?.endTime]);

    const getTimerStatus = () => {
        if (currentUserParticipantData?.eventData?.success === true) {
            return EventTimerStatus.passed;
        }
        if (currentUserParticipantData?.eventData?.success === false) {
            return EventTimerStatus.failed;
        }

        return EventTimerStatus.timing;
    };

    const renderTypeChallenge = () => {
        return (
            <div className={classNames(styles.SidebarEvent, styles[status])}>
                <div className={styles.text}>
                    <span className={styles.intro}>Quick! A walker is coming!</span>
                    <span className={styles.instruction}>
                        Type <span className={styles.winString}>“{currentEvent.eventParams.winString}”</span>{' '}
                        <span className={styles.highlight}>to survive</span>
                    </span>
                </div>
                <EventTimer
                    startTime={currentEvent.startTime}
                    endTime={currentEvent.endTime}
                    status={getTimerStatus()}
                    className={classNames(styles.timer, {
                        [styles.incorrectShake]: isIncorrect,
                    })}
                    imageUrl="/assets/themes/titan/images/events/timer-hands.webp"
                />
            </div>
        );
    };

    useEffect(() => {
        // When mobile keyboard opens, move event UI down so it can still be seen
        if (!window.visualViewport) return;

        const handleVisualViewportScroll = () => {
            if (!containerRef.current) return;

            const amount = document.documentElement?.clientHeight - window.visualViewport?.height;
            const containerIsOffScreen = amount > 70;
            if (containerIsOffScreen) {
                containerRef.current.style.transition = 'top 200ms ease-out 200ms';
                containerRef.current.style.top = `${amount}px`;
            } else {
                containerRef.current.style.transition = 'none';
                containerRef.current.style.top = '0';
            }
        };

        const throttledHandleVisualViewportScroll = throttle(handleVisualViewportScroll, 500);
        window.visualViewport.addEventListener('scroll', throttledHandleVisualViewportScroll);

        return () => {
            window.visualViewport.removeEventListener('scroll', throttledHandleVisualViewportScroll);
        };
    }, []);

    const renderEvent = () => {
        switch (currentEvent.eventType) {
            case 'typechallenge':
                return renderTypeChallenge();
            default:
                return null;
        }
    };

    const handleEventDismiss = () => {
        stopAllEventSounds();
        setIsVisible(false);
        setTimeout(() => {
            dispatch(clearCurrentEvent());
        }, 210);
    };

    if (currentEvent) {
        return (
            <div
                ref={containerRef}
                className={classNames(styles.SidebarEventContainer, {
                    [styles.isHidden]: !isVisible,
                })}
                aria-live="polite"
                inert={!isVisible ? '' : undefined}
                aria-hidden={!isVisible}
            >
                <IconButton
                    iconType="close"
                    ariaLabel="Dismiss event"
                    title="Dismiss event"
                    buttonStyle="icon"
                    className={styles.dismissButton}
                    onClick={handleEventDismiss}
                    disabled={!isVisible}
                />
                {renderEvent()}
            </div>
        );
    }
    return null;
}
