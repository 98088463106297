import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/state/store';
import { loadAllLocationData, loadAreas } from '@/state/features/app';
import { useLocations } from '@/hooks/location';
import FullpageWrapper from '@/components/FullpageWrapper';

export default function MapBasicPage() {
    const dispatch = useAppDispatch();
    const locations = useLocations();

    useEffect(() => {
        dispatch(loadAllLocationData());
        dispatch(loadAreas());
    }, []);

    return (
        <FullpageWrapper>
            <div>
                <br />
                <br />
                <br />
                <br />

                <ul>
                    {locations &&
                        locations.locations &&
                        Object.values(locations.locations).map((d) => (
                            <li key={d.id}>
                                <Link to={`/location/${d.id}`}>{d.name}</Link>
                            </li>
                        ))}
                </ul>
            </div>
        </FullpageWrapper>
    );
}
