import ExternalRedirect from '@/components/ExternalRedirect';
import LoadingScreen from '@/components/LoadingScreen';

export default function InsidersExternalRedirect() {
    const INSIDERS_DOMAIN = process.env.PUBLIC_INSIDERS_DOMAIN;
    const returnUrl = window.location.origin + '/auth/insiders';
    const base64Url = btoa(returnUrl);
    const loginUrl = `${INSIDERS_DOMAIN}/sts-login?return_to=${base64Url}`;

    return (
        <>
            <ExternalRedirect to={loginUrl} />
            <LoadingScreen show={true} />
        </>
    );
}
