import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import HeaderBar from '@/components/HeaderBar';
import Settings from '@/components/Settings';
import { useAppSelector } from '@/state/store';

import * as headerBarStyles from '@/components/HeaderBar/styles.module.scss';

interface FullpageWrapperProps {
    children: React.ReactNode;
    pageHasProfileDrawer?: boolean;
}

export default function FullpageWrapper({ children, pageHasProfileDrawer }: FullpageWrapperProps) {
    const isSettingsMenuShowing = useAppSelector((s) => s.app.isSettingsMenuShowing);
    const container = useRef<HTMLDivElement>(null);

    const [headerBarZIndex, setHeaderBarZIndex] = useState('z-[20]');
    // z-index is dynamic because HeaderBar contains settings open/close button
    // Settings button must be above settings when settings is open, but behind the sidebar when settings is closed.
    useEffect(() => {
        if (isSettingsMenuShowing) {
            setHeaderBarZIndex('z-[100]');
        } else {
            // Wait for settings close animation
            setTimeout(() => setHeaderBarZIndex('z-[20]'), 300);
        }
    }, [isSettingsMenuShowing]);

    return (
        <div
            className="absolute top-0 left-0 w-full h-full overflow-hidden"
            style={{
                touchAction: 'none',
            }}
            ref={container}
            onScroll={() => {
                // Stop scroll jumping to items which become focussed off screen
                container.current.scrollTo(0, 0);
            }}
        >
            <Settings visible={isSettingsMenuShowing} displayNameShouldLinkToProfile={pageHasProfileDrawer} />

            <div className={classNames('absolute', headerBarZIndex)}>
                <HeaderBar />
            </div>
            <div className={headerBarStyles.headerBarBorderBottom}></div>
            <div className="absolute top-0 left-0 w-full h-full" inert={isSettingsMenuShowing ? '' : undefined}>
                {children}
            </div>
        </div>
    );
}
