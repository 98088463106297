import { useTheme } from '@/theme';
import { useRef, TransitionEvent } from 'react';
import classNames from 'classnames';
import IconButton from '../IconButton';
import * as styles from './styles.module.scss';
import { H4 } from '../typography/Heading';

interface ModalProps {
    title: string;
    onClose: () => void;
    isVisible: boolean;
    children?: React.ReactNode;
}

export default function Modal({ title, onClose, isVisible, children }: ModalProps) {
    const theme = useTheme();
    const backgroundImage = theme.customJSON.modalBackgroundImage;
    const modalContentRef = useRef<HTMLDivElement>(null);
    const closeButtonRef = useRef<HTMLButtonElement>(null);

    // Close modal when user presses escape
    function onKeyUp(e: any) {
        if (e.key === 'Escape') {
            onClose();
        }
    }

    // Hide modal when the user clicks outside of it
    function clickHandler(e: any) {
        if (modalContentRef.current && !modalContentRef.current.contains(e.target as Node)) {
            onClose();
        }
    }

    // Move focus to modal when opened
    function onTransitionEnd (e: TransitionEvent<HTMLDivElement>) {
        if (isVisible && e.target === e.currentTarget) {
            setTimeout(() => {
                closeButtonRef.current?.focus();
            }, 200);
        }
    }

    return (
        <div onClick={clickHandler} onKeyUp={onKeyUp} inert={isVisible ? undefined : ''}>
            <div
                className={'absolute top-0 left-0 w-full h-full'}
                style={{
                    transition: 'backdrop-filter 300ms, visibility 300ms',
                    visibility: isVisible ? 'visible' : 'hidden',
                    backdropFilter: isVisible ? 'blur(10px)' : 'blur(0px)',
                }}
            ></div>
            <div
                onTransitionEnd={onTransitionEnd}
                aria-modal={isVisible}
                aria-hidden={!isVisible}
                aria-labelledby="modalTitle"
                className={classNames(styles.modalDrawer, {
                    [styles['visible']]: isVisible,
                })}
            >
                <div
                    onClick={clickHandler}
                    ref={modalContentRef}
                    className={classNames(styles.Modal, {
                        [styles.noBackgroundImage]: !backgroundImage,
                    })}
                    style={backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : {}}
                >
                    <IconButton
                        iconType="close"
                        buttonStyle="round"
                        className={styles.closeButton}
                        ariaLabel="Close modal"
                        onClick={onClose}
                        disabled={!isVisible}
                        buttonRef={closeButtonRef}
                    />
                    <H4 id="modalTitle">{title}</H4>
                    {children}
                </div>
            </div>
        </div>
    );
}
