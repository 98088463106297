@import '../../style/globals.scss';

.ThemedButton {
    position: relative;
    overflow: hidden;
    width: 240px;
    height: 50px;
    padding: 13px 19px 19px 19px;
    background-color: var(--token-accent-theme-1-button-button-default);
    color: var(--token-accent-theme-1-text-text-default);
    font-weight: 500;
    mask-size: 100% 100%;
    font-family: var(--font-tertiary);

    span {
        position: relative;
    }

    &:hover,
    &:focus {
        &:before {
            left: 0;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        display: block;
        width: 100%;
        height: 100%;
        background-color: var(--token-accent-theme-1-button-button-highlight);
        transition: left 300ms ease-out;
    }
}
