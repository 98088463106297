import classNames from 'classnames';

import { useAppSelector } from '@/state/store';

import * as styles from './styles.module.scss';
import { useFailedWithTimer } from '@/hooks/failed';

interface FailedOverlayProps {}

export default function FailedOverlay({}: FailedOverlayProps) {
    const { secondsLeft, hasFailed } = useFailedWithTimer();

    // TODO: from cms
    const bgUrl = '/assets/themes/titan/images/failscreen/failscreen-chat-overlay.svg';

    return (
        <div
            className={classNames(styles.FailedOverlay, {
                [styles.visible]: hasFailed,
            })}
        >
            <div className={styles.background} style={{ maskImage: `url(${bgUrl})` }} />
            <div className={styles.text}>
                <div
                    style={{
                        color: 'var(--token-accent-theme-1-text-text-secondary)',
                        fontSize: '.75em',
                    }}
                >
                    YOU ARE
                </div>
                <div
                    style={{
                        color: 'var(--token-accent-theme-1-text-text-default)',
                        fontSize: '2.5em',
                        lineHeight: 1,
                        letterSpacing: '.05em',
                    }}
                >
                    DEAD
                </div>
                <div
                    style={{
                        color: 'var(--token-accent-theme-1-text-text-default)',
                        fontSize: '.5em',
                        lineHeight: 1.1,
                    }}
                >
                    YOU ALERTED THE WALKERS
                </div>
                <div
                    style={{
                        color: 'var(--token-accent-theme-1-text-text-secondary)',
                        fontSize: '.5em',
                        lineHeight: 1.1,
                    }}
                >
                    {secondsLeft ? `TRY AGAIN IN ${secondsLeft}s` : `TRY AGAIN`}
                </div>
            </div>
        </div>
    );
}
