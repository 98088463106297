import { useInitialAuthCheck } from '@/hooks/auth';
import { useAppSelector } from '@/state/store';

export default function LocationLayout({ children }: { children: React.ReactNode }) {
    useInitialAuthCheck();
    const auth = useAppSelector((state) => state.auth);
    if (!auth.loggedIn) {
        return <></>;
    }
    return <>{children}</>;
}
