import ChatMessage from '@/components/Feed/ChatMessage';
import EventSummaryMessage from '@/components/Feed/EventSummaryMessage';
import InteractionPrompt from '@/components/Feed/InteractionPrompt';
import Update from '@/components/Feed/Update';
import { FeedItem } from '@/models/feed';

import styles from './styles.module.scss';

interface FeedItemRendererProps extends React.HTMLAttributes<HTMLDivElement> {
    item: FeedItem;
}

export default function FeedItemRenderer({ item }: FeedItemRendererProps) {
    function renderFeedItem() {
        switch (item.type) {
            case 'chat-message':
                return <ChatMessage message={item.data} />;
            case 'feed-update':
                return <Update {...item.data} />;
            case 'interaction-prompt':
                return <InteractionPrompt {...item.data} />;
            case 'event-summary':
                return <EventSummaryMessage {...item.data} />;
            default:
                // @ts-ignore
                console.error('Unknown feed item type:', item.type);
                return null;
        }
    }
    return <div className={styles.feedItem}>{renderFeedItem()}</div>;
}
