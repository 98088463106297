import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { msTimeDifferenceFromNow, msTimeDifferenceBetweenUTCDates } from '@/utils/time';
import Countdown from '../../Countdown';

import * as styles from './styles.module.scss';

export enum EventTimerStatus {
    passed = 'passed',
    failed = 'failed',
    timing = 'timing',
}

interface EventTimerProps {
    startTime: string;
    endTime: string;
    status: EventTimerStatus;
    imageUrl?: string;
    className?: string;
}

export default function EventTimer({ startTime, endTime, status, imageUrl, className }: EventTimerProps) {
    const timeRemaining = msTimeDifferenceFromNow(endTime);

    const getPercentOfTimeRemaining = () => {
        const duration = msTimeDifferenceBetweenUTCDates(startTime, endTime);
        return (timeRemaining / duration) * 100;
    };

    const [percentOfTimeRemaining, setPercentOfTimeRemaining] = useState(getPercentOfTimeRemaining());
    const [animationDuration, setAnimationDuration] = useState(timeRemaining);

    useEffect(() => {
        // Reset progressBar
        setAnimationDuration(0);
        setPercentOfTimeRemaining(getPercentOfTimeRemaining());
        setTimeout(() => {
            // Set to 0% remaining to trigger transition
            setAnimationDuration(timeRemaining);
            setPercentOfTimeRemaining(0);
        }, 0);
    }, [startTime]);

    const renderText = () => {
        if (status === 'passed' || status === 'failed') {
            const text = status === 'passed' ? 'Passed!' : 'Failed!';
            return <span className={styles.statusText}>{text}</span>;
        }
        return (
            <Countdown
                className={styles.countdown}
                endTime={endTime}
                omitDaysAndHoursIfEmpty
                startTime={startTime}
                maxPreCountdownSeconds={3}
            />
        );
    };
    return (
        <div className={classNames(styles.EventTimer, styles[status], className)}>
            {status === EventTimerStatus.timing && (
                <div
                    className={styles.progressBar}
                    style={{ transitionDuration: `${animationDuration}ms`, left: `${percentOfTimeRemaining}%` }}
                ></div>
            )}
            {imageUrl && (
                <div
                    aria-hidden="true"
                    className={styles.graphic}
                    style={{
                        backgroundImage: `url(${imageUrl})`,
                    }}
                ></div>
            )}
            {renderText()}
        </div>
    );
}
