import classNames from 'classnames';

import { Achievement as IAchievement } from '@/models/misc';
import AchievementIcon from '../AchievementIcon';

import * as styles from './styles.module.scss';

interface AchievementProps extends IAchievement {
    onLoaded?: () => void;
    isVisible?: boolean;
    hasLineBreak?: boolean;
}

export default function Achievement({
    actionPrefix,
    action,
    type,
    icon,
    onLoaded,
    isVisible = true,
    hasLineBreak,
}: AchievementProps) {
    return (
        <div
            className={classNames(styles.Achievement, styles[type], { [styles.visible]: isVisible })}
            aria-label={`${actionPrefix} ${action}`}
        >
            <div className={styles.background}></div>
            <div className={styles.icon}>
                <AchievementIcon icon={icon} onLoaded={onLoaded} />
            </div>
            <div className={styles.label}>
                <span>
                    {actionPrefix}{' '}
                    <span className={classNames(styles.highlighted, { [styles.hasLineBreak]: hasLineBreak })}>
                        {action}
                    </span>
                </span>
            </div>
        </div>
    );
}
