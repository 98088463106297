import { loadInstance } from '@/state/features/app';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { useEffect } from 'react';

export const useTheme = () => {
    const theme = useAppSelector((state) => state.app.theme);
    return theme;
};

export function ThemeStylesProvider() {
    const theme = useTheme();

    return (
        <style>
            {`
                body {
                    color: ${theme.tokens.AccentTheme1TextTextDefault};

                    --font-primary: '${theme.fonts.primary}';
                    --font-secondary: '${theme.fonts.secondary}';
                    --font-tertiary: '${theme.fonts.tertiary}';
                    --font-quaternary: '${theme.fonts.quaternary}';
                    
                    --token-arise-theme-1-rarity-rarity-common-light: ${theme.tokens.AriseTheme1RarityRarityCommonLight};
                    --token-arise-theme-1-rarity-rarity-uncommon-light: ${theme.tokens.AriseTheme1RarityRarityUncommonLight};
                    --token-arise-theme-1-rarity-rarity-rare-light: ${theme.tokens.AriseTheme1RarityRarityRareLight};
                    --token-arise-theme-1-rarity-rarity-epic-light: ${theme.tokens.AriseTheme1RarityRarityEpicLight};
                    --token-arise-theme-1-rarity-rarity-legendary-light: ${theme.tokens.AriseTheme1RarityRarityLegendaryLight};
                    --token-arise-theme-1-rarity-rarity-common-mid: ${theme.tokens.AriseTheme1RarityRarityCommonMid};
                    --token-arise-theme-1-rarity-rarity-uncommon-mid: ${theme.tokens.AriseTheme1RarityRarityUncommonMid};
                    --token-arise-theme-1-rarity-rarity-rare-mid: ${theme.tokens.AriseTheme1RarityRarityRareMid};
                    --token-arise-theme-1-rarity-rarity-epic-mid: ${theme.tokens.AriseTheme1RarityRarityEpicMid};
                    --token-arise-theme-1-rarity-rarity-legendary-mid: ${theme.tokens.AriseTheme1RarityRarityLegendaryMid};
                    --token-arise-theme-1-state-state-error-light: ${theme.tokens.AriseTheme1StateStateErrorLight};
                    --token-arise-theme-1-state-state-error-mid: ${theme.tokens.AriseTheme1StateStateErrorMid};
                    --token-arise-theme-1-state-state-success-light: ${theme.tokens.AriseTheme1StateStateSuccessLight};
                    --token-arise-theme-1-state-state-success-mid: ${theme.tokens.AriseTheme1StateStateSuccessMid};
                    --token-arise-theme-1-state-state-success-dark: ${theme.tokens.AriseTheme1StateStateSuccessDark};
                    --token-accent-theme-1-button-button-default: ${theme.tokens.AccentTheme1ButtonButtonDefault};
                    --token-accent-theme-1-button-button-secondary: ${theme.tokens.AccentTheme1ButtonButtonSecondary};
                    --token-accent-theme-1-button-button-highlight: ${theme.tokens.AccentTheme1ButtonButtonHighlight};
                    --token-accent-theme-1-button-button-inactive: ${theme.tokens.AccentTheme1ButtonButtonInactive};
                    --token-accent-theme-1-border-border-highlight: ${theme.tokens.AccentTheme1BorderBorderHighlight};
                    --token-accent-theme-1-border-border-default: ${theme.tokens.AccentTheme1BorderBorderDefault};
                    --token-accent-theme-1-border-border-active: ${theme.tokens.AccentTheme1BorderBorderActive};
                    --token-accent-theme-1-text-text-active: ${theme.tokens.AccentTheme1TextTextActive};
                    --token-accent-theme-1-text-text-default: ${theme.tokens.AccentTheme1TextTextDefault};
                    --token-accent-theme-1-text-text-secondary: ${theme.tokens.AccentTheme1TextTextSecondary};
                    --token-accent-theme-1-text-text-inactive: ${theme.tokens.AccentTheme1TextTextInactive};
                    --token-accent-theme-1-interface-interface-active: ${theme.tokens.AccentTheme1InterfaceInterfaceActive};
                    --token-accent-theme-1-interface-interface-foreground: ${theme.tokens.AccentTheme1InterfaceInterfaceForeground};
                    --token-accent-theme-1-interface-interface-midground: ${theme.tokens.AccentTheme1InterfaceInterfaceMidground};
                    --token-accent-theme-1-interface-interface-background: ${theme.tokens.AccentTheme1InterfaceInterfaceBackground};
                    --token-accent-theme-1-interface-interface-white: ${theme.tokens.AccentTheme1InterfaceInterfaceWhite};
                    --token-accent-theme-1-interface-interface-grey: ${theme.tokens.AccentTheme1InterfaceInterfaceGrey};
                    --token-accent-theme-1-interface-interface-black: ${theme.tokens.AccentTheme1InterfaceInterfaceBlack};
                    --token-accent-theme-1-brand-colour: ${theme.tokens.AccentTheme1BrandColour};
                    --token-accent-theme-1-brand-colour-contrast: ${theme.tokens.AccentTheme1BrandColourContrast};
                }
            `}
        </style>
    );
}
