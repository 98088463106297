@import '../../style/globals.scss';

.AvatarPicker {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    margin: 0 auto;
    gap: 34px 24px;

    @media (max-width: $bp-mobile-chat) {
        gap: 29px 21px;
    }

    @media (max-width: $bp-s) {
        gap: 29px 15px;
    }
}

.AvatarPickerAvatar {
    position: relative;
    overflow: hidden;
    width: 90px;
    height: 90px;
    border: 2px solid transparent;
    border-radius: 50%;
    animation-duration: 1s;
    animation-fill-mode: backwards;

    // Give animation different delay to each column
    @for $i from 1 through 4 {
        &:nth-child(4n + #{$i}) {
            animation-delay: $i * 0.1s;
        }
    }

    &.page-transition-preVisible {
        opacity: 0;
        animation-name: none;
    }

    &.page-transition-visible {
        animation-name: slide-in;
    }

    &.page-transition-postVisible {
        animation-fill-mode: forwards;
        animation-name: slide-out;
    }

    @media (max-width: $bp-mobile-chat) {
        width: 76px;
        height: 76px;
    }

    &--selected {
        border-color: var(--token-accent-theme-1-border-border-active);
    }

    &__Button {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        &:focus,
        &:hover {
            .AvatarPickerAvatar {
                &__Image {
                    background-size: 120% 120%;
                }
            }
        }
    }

    &__Image {
        width: 100%;
        height: 100%;
        background-color: var(--token-accent-theme-1-interface-interface-foreground);
        background-position: center;
        background-size: 100% 100%;
        transition: background-size 200ms $animation-bounce;
    }

    @keyframes slide-in {
        from {
            bottom: -200px;
            opacity: 0;
        }
        to {
            bottom: 0px;
            opacity: 1;
        }
    }

    @keyframes slide-out {
        from {
            bottom: 0px;
            opacity: 1;
        }
        to {
            bottom: -200px;
            opacity: 0;
        }
    }
}
