// TODO: use from env variable (set at build time?)

let API_HOST = 'https://api-partner.skybound-online.com/online/api/v1';

let token = localStorage.getItem('ARISE_INSIDERS_TOKEN') || '';

export function setToken(t: string) {
    token = t;
    localStorage.setItem('ARISE_INSIDERS_TOKEN', t);
}

export function getToken() {
    return token;
}

async function call<T>(path: string, method: string, body?: any) {
    let response = await fetch(`${API_HOST}${path}`, {
        method: method,
        body: body ? JSON.stringify(body) : undefined,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) throw new Error(response.statusText);

    return (await response.json()) as T;
}

export async function me() {
    try {
        const res = await call<any>('/users/info', 'GET');
        return res;
    } catch (error) {
        throw error;
    }
}
