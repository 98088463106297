.InventoryDrawer {
    position: absolute;
    left: 50%;
    bottom: 0;
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    transition-delay: 10ms;
    z-index: 5;

    transform: translate(-50%, calc(100% + 30px));

    &__visible {
        transform: translate(-50%, 15%);
    }
}

.Inventory {
    position: relative;
    aspect-ratio: 1280/1728;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 600px;
}

.closeButton {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.pageControls {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 150px;
    text-align: center;
    transform: translateX(-50%);
}

.previousButton {
    position: absolute;
    left: 0;
    transform: scaleX(-1);
}

.nextButton {
    position: absolute;
    right: 0;
}

.pageNumber {
    display: inline-block;
}

.items {
    position: absolute;
    inset: 17.5% 17% 18%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
    align-content: start;

    .box {
        margin-bottom: 5%;
        width: 48.5%;
    }
}

.info {
    position: absolute;
    top: 66px;
    left: 0;
    text-align: center;
    width: 100%;
}

@media screen and (max-width: 440px) {
    .Inventory {
        width: 140vw;
    }
}

@media screen and (max-height: 800px) {
    .InventoryDrawer {
        width: 500px;
        .Inventory {
            width: 500px;
        }
        &__visible {
            transform: translate(-50%, 20%);
        }
    }
    .items {
        inset: 19% 20% 22%;
    }
}
