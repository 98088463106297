import { useInitialAuthCheck } from '@/hooks/auth';
import { mapPath } from '@/theme/vars';

export default function HomePage() {
    useInitialAuthCheck({
        loggedInRedirect: mapPath,
    });

    return <div className="Home"></div>;
}
