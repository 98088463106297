/**
 * Returns number converted to an oridinal string
 * @param number example: 2
 * @returns {string} example '2nd'
 */
export function convertNumberToOrdinal(number: number): string {
    let ordinalSuffix: string;
    if (number % 10 === 1 && number % 100 !== 11) {
        ordinalSuffix = 'st';
    } else if (number % 10 === 2 && number % 100 !== 12) {
        ordinalSuffix = 'nd';
    } else if (number % 10 === 3 && number % 100 !== 13) {
        ordinalSuffix = 'rd';
    } else {
        ordinalSuffix = 'th';
    }
    return `${number}${ordinalSuffix}`;
}
