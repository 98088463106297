import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import AuthInput from '@/components/Forms/AuthInput';
import Button from '@/components/Forms/Button';
import { AuthDrawer, authDrawerWidth } from '@/components/AuthDrawer';
import { useTheme } from '@/theme';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { login } from '@/state/features/auth';

import * as styles from '../styles.module.scss';
import { useAudio } from '@/hooks/audio';
import { PARALLAX_LAYERS } from './content';
import { isValidEmail } from '@/utils/validation';
import { mapPath } from '@/theme/vars';

const AUTH_DRAWER_ANIMATION_FRAMES = [
    { width: authDrawerWidth.small, durationMs: 0 },
    { width: authDrawerWidth.mediumContent, durationMs: 1500 },
    { width: authDrawerWidth.mediumContent, durationMs: 0 },
    { width: authDrawerWidth.full, durationMs: 1000 },
];
const ERROR_ANIMATION_DURATION_MS = 500;
const INPUT_TRANSITION_DURATION_MS = 700;

export default function LoginPage() {
    const [email, setEmail] = useState(process.env.PUBLIC_LOCAL_EMAIL || '');
    const [password, setPassword] = useState(process.env.PUBLIC_LOCAL_PASS || '');
    const [errorMessage, setErrorMessage] = useState('');
    const [currentStep, setCurrentStep] = useState(0);
    const [inputIsInvalid, setInputIsInvalid] = useState(false);
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const registerPath = process.env.PUBLIC_INSTANCE === 'titan' ? '/auth/arise/register' : '/auth/register';

    const { audio } = useAudio();

    const auth = useAppSelector((state) => state.auth);

    function playErrorAnimation() {
        setInputIsInvalid(true);
        setTimeout(() => setInputIsInvalid(false), ERROR_ANIMATION_DURATION_MS);
    }

    useEffect(() => {
        audio?.playOnly('drone-bg');
    }, []);

    function onContinue() {
        if (!isValidEmail(email)) {
            setErrorMessage('Please enter a valid email address');
            playErrorAnimation();
        } else {
            audio?.play('button-click');
            audio?.play('gust-open');
            setErrorMessage('');
            setCurrentStep(2);
            setTimeout(() => {
                // Focus on password once animated in
                passwordInputRef.current?.focus();
            }, INPUT_TRANSITION_DURATION_MS);
        }
    }

    function onSubmit() {
        audio?.play('button-click');
        if (!password) {
            setErrorMessage('Please enter your password');
            playErrorAnimation();
        } else {
            setErrorMessage('');
            dispatch(login([email, password]));
        }
    }

    useEffect(() => {
        if (auth.loggedIn) {
            audio?.play('drum');
            setCurrentStep(3);
            setTimeout(() => {
                navigate(mapPath);
            }, AUTH_DRAWER_ANIMATION_FRAMES[3].durationMs);
        }
    }, [auth.loggedIn]);

    useEffect(() => {
        if (auth.loginError) {
            setErrorMessage(auth.loginError);
            playErrorAnimation();
            setCurrentStep(1);
        }
    }, [auth.loginError]);

    if (auth.loggedIn) {
        // TODO show loading screen
    }

    // Trigger on load animation
    useEffect(() => {
        setTimeout(() => {
            setCurrentStep(1);
        }, 100);
    }, []);

    function renderButton() {
        switch (currentStep) {
            case 1:
                return (
                    <Button className={styles.authNextStepButton} onClick={onContinue} onSubmit={onContinue}>
                        Continue
                    </Button>
                );
            case 2:
            case 3:
                const isDisabled = auth.isLoggingIn || auth.loggedIn;
                return (
                    <Button
                        className={styles.authNextStepButton}
                        onClick={onSubmit}
                        onSubmit={onSubmit}
                        disabled={isDisabled}
                    >
                        {isDisabled ? 'Connecting...' : 'Login'}
                    </Button>
                );

            default:
                break;
        }
    }

    function renderFormElements() {
        const emailInputClasses = classNames({
            [styles.loginPage__loginTextInput]: true,
            [styles['loginPage__loginTextInput--postVisible']]: currentStep > 1,
        });
        const passwordInputClasses = classNames({
            [styles.loginPage__loginTextInput]: true,
            [styles['loginPage__loginTextInput--preVisible']]: currentStep < 2,
            [styles['loginPage__loginTextInput--postVisible']]: currentStep > 2,
        });
        return (
            <>
                <div className={styles.loginPage__inputContainer}>
                    <AuthInput
                        isInvalid={inputIsInvalid}
                        errorAnimationDurationMs={ERROR_ANIMATION_DURATION_MS}
                        className={emailInputClasses}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email Address"
                        onSubmit={onContinue}
                        type="email"
                        tabIndex={currentStep === 1 ? 0 : -1}
                        style={{ transitionDuration: `${INPUT_TRANSITION_DURATION_MS}ms` }}
                    />
                    <AuthInput
                        isInvalid={inputIsInvalid}
                        errorAnimationDurationMs={ERROR_ANIMATION_DURATION_MS}
                        className={passwordInputClasses}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        onSubmit={onSubmit}
                        type="password"
                        tabIndex={currentStep === 2 ? 0 : -1}
                        passedRef={passwordInputRef}
                        style={{ transitionDuration: `${INPUT_TRANSITION_DURATION_MS}ms` }}
                    />
                </div>
                {renderButton()}
            </>
        );
    }

    const renderDrawerContent = () => {
        return (
            <div className={styles.loginPage__content} style={{ opacity: currentStep < 3 ? 1 : 0 }}>
                <div
                    aria-label={theme.customJSON.loginLogoAltText}
                    className={styles.loginPage__logo}
                    style={{ backgroundImage: `url(${theme.files.instance_mark})` }}
                ></div>
                <div className={styles.loginPage__formContent}>
                    <h2 className="text-[14px] pb-[1em] font-bold">Log in</h2>
                    <p
                        className="text-[14px] font-medium mb-[14px]"
                        style={{
                            color: theme.tokens.AccentTheme1TextTextSecondary,
                        }}
                    >
                        {theme.customJSON.loginText}
                    </p>
                    {renderFormElements()}
                    <p className="text-[14px] pt-[14px]">
                        <span
                            style={{
                                color: theme.tokens.AccentTheme1TextTextDefault,
                            }}
                        >
                            {theme.customJSON.signUpLinkPrefix}
                        </span>{' '}
                        <Link
                            style={{
                                color: theme.tokens.AccentTheme1TextTextSecondary,
                            }}
                            to={registerPath}
                        >
                            Sign Up
                        </Link>
                    </p>
                    <p className={styles.loginPage__errorMessage}>{errorMessage}</p>
                </div>
            </div>
        );
    };

    const renderParallax = () => {
        const layers = PARALLAX_LAYERS[theme.themeId]?.layers ?? [];
        return (
            <>
                {layers.map((layer, index) => (
                    <div
                        key={index}
                        className={classNames(
                            styles.loginPage__customElement,
                            styles[`loginPage__customElement${layer.id}`],
                            styles[`loginPage__customElement${layer.id}--frame-${currentStep}`],
                        )}
                        style={{
                            backgroundImage: `url(${layer.imageUrl})`,
                        }}
                    ></div>
                ))}
            </>
        );
    };

    return (
        <div className={styles.loginPage}>
            <AuthDrawer
                imagePath={theme.customJSON.loginDrawerImage}
                width={AUTH_DRAWER_ANIMATION_FRAMES[currentStep].width}
                imageColour={theme.customJSON.loginDrawerColour}
                animationDurationMs={AUTH_DRAWER_ANIMATION_FRAMES[currentStep].durationMs}
            />
            <div
                style={{ opacity: currentStep < 3 ? 1 : 0 }}
                className={classNames(styles[theme.themeId], styles.parallaxContainer)}
            >
                {renderParallax()}
            </div>
            {renderDrawerContent()}
        </div>
    );
}
