import { ThemeIds } from '@/theme/themes';

export const PARALLAX_LAYERS = {
    [ThemeIds.Titan]: {
        layers: [
            {
                imageUrl: '/assets/themes/titan/images/auth/parallax/hands-background.webp',
                id: 'Background',
            },
            {
                imageUrl: '/assets/themes/titan/images/auth/parallax/hands-midground.webp',
                id: 'Midground',
            },
            {
                imageUrl: '/assets/themes/titan/images/auth/parallax/hands-foreground.webp',
                id: 'Foreground',
            },
        ],
    },
};
