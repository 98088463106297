.buttonContainer {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin: 5px;
    transform: scale(1);
    @media (max-width: 500px) {
        transform: scale(0.8);
    }
}
