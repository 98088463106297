import { scroll } from '@/arise/api';
import { getPlatformData } from '@/utils/analytics/platform';

let amplitudeKey = undefined;
let userId = undefined;
let deviceId = window.localStorage.getItem('ARISE_DEVICE_ID');

if (!deviceId) {
    deviceId = uuidv4();
    window.localStorage.setItem('ARISE_DEVICE_ID', deviceId);
}

export function setUserId(id: string) {
    userId = id;
}

export function setAmplitudeKey(key: string) {
    amplitudeKey = key;
}

const platformData = getPlatformData();

export function sendEvent(name: string, data: any) {
    if (!amplitudeKey) {
        return;
    }
    return scroll({
        api_key: amplitudeKey,
        events: [
            {
                user_id: userId,
                device_id: deviceId,
                event_type: name,
                event_properties: data,
                time: new Date().getTime(),

                // Platform
                ...platformData,
            },
        ],
    }).catch((e) => {
        // Silently fail
    });
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
